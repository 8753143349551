import axios from 'axios';

import React from 'react';
import { StyleSheet, View, TouchableOpacity, Platform, useWindowDimensions, TouchableWithoutFeedback, Linking } from 'react-native';
import MyText from './MyText';
import { MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons'; 
import Logo from './assets/icons/logo.svg';
import LogoPurple from './assets/icons/logo-purple.svg';
import MySVG from './assets/MySVG';
import stylesheet from './Style'

import AsyncStorage from '@react-native-async-storage/async-storage';

import NetInfo from '@react-native-community/netinfo';

import MyPicker from './MyPicker';
import { useModal } from './hooks/modal';

const Header = (props) => {
  const css = stylesheet();
  const modal = useModal();
  
  const {height, width} = useWindowDimensions();

  const [count, setCount] = React.useState(0);
  
  React.useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching notifications");
      // const deviceState = await OneSignal.getDeviceState();
      let readNotifications = JSON.parse(await AsyncStorage.getItem('read-notifications')??'{}');
      // if(props.user && deviceState && deviceState.userId){
      if(props.user){
        NetInfo.fetch().then(async(state) => {
          if(!state.isConnected){
              return;
          }
          axios.get('/api/getNotificationsCount.php', {
            params: {
                uuid: await AsyncStorage.getItem('uuid')
            }
          })
          .then(async (res) => {
            if(res.data){
              let seen = 0;
              res.data.forEach(dbId => {
                let hasSeen = false;
                if(readNotifications[dbId]){
                  hasSeen = true;
                  seen++;
                }
              })
              let temp = res.data.length-seen;
              setCount(temp);
            }
          })
        });
      }
    }
  
    fetchData().catch(console.error);
  },[])

  React.useEffect(() => {
    const present = async() => {
      const accepted = JSON.parse(await AsyncStorage.getItem('cookie-consent')??'false');
      if(!accepted && Platform.OS != 'ios'){ // Apple doesn't like custom cookie popups
        props.bottomConsentSheet.current.present();
      }
    }
    if(props.bottomConsentSheet?.current){
      present();
    }
  },[props.bottomConsentSheet])

  // if(!props.user){
    // return <></>
  // }
  const LinkTo = (to) => {
    if(Platform.OS==='web'){
        document.documentElement.scrollTop = 0;
    }
    props.navigation.push(to);
  }

  const Home = () => {
    if(Platform.OS==='web'){
      document.documentElement.scrollTop = 0;
    }
    if(!props.user){
      props.navigation.push('Landing Page');
    }
    else{
      if(!props.user.isVGroup){
        props.navigation.push('My Requests');
      }
      else{
        props.navigation.push('Manage Requests');
      }
    }
  }
  
  const Web = () => {
    let background = styles.webBackground;
    let containerStyle = {position: 'fixed', top: 0, left: 0, right: 0};
    let height = 108; //to compensate for position fixed
    let logo = Logo;
    if(props.route?.name === 'Landing Page'){
      background = styles.webHomeBackground;
      height = 0;
      containerStyle = {};
    }
    if(!props.user && (props.route?.name === 'Register' 
        || props.route?.name === 'About Us'
        || props.route?.name === 'About'
        || props.route?.name === 'Career'
        || props.route?.name === 'Press'
        || props.route?.name === 'Customer Support'
        || props.route?.name === 'Terms'
        || props.route?.name === 'Privacy'
        )
      ){
      height = 66;
    }
  
    return <View style={{height: height}}>
      <View style={containerStyle}>
        <View style={background}>
            <TouchableWithoutFeedback onPress={() => Home()}>
              <MySVG source={(props.route?.name === 'Landing Page')?Logo:LogoPurple} style={styles.logo} resizeMode={'contain'} />
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => Home()}>
              <MyText style={[css.l, styles.text, {color: (props.route?.name === 'Landing Page')?'white':'#461b6f', marginRight: 10}]}>
                  FOOTPRINT
              </MyText>
            </TouchableWithoutFeedback>
            {
              !props.user?
              <>
                <TouchableOpacity onPress={() => LinkTo('About')}>
                  <MyText style={[css.bold, width >= 576?styles.headerText:styles.headerTextSmall, {color: (props.route?.name === 'Landing Page')?'white':'#461b6f'}]}>About</MyText>
                </TouchableOpacity>
                <View>
                  <MyText style={[css.bold, width >= 576?styles.headerText:styles.headerTextSmall, {color: (props.route?.name === 'Landing Page')?'white':'#461b6f'}]}>|</MyText>
                </View>
                {
                  width >= 576?
                  <>
                    <TouchableOpacity onPress={() => LinkTo('Register')}>
                      <MyText style={[css.bold, styles.headerText, {color: (props.route?.name === 'Landing Page')?'white':'#461b6f'}]}>Register</MyText>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => false}>
                      <MyText style={[css.bold, styles.headerText, {color: (props.route?.name === 'Landing Page')?'white':'#461b6f'}]}>|</MyText>
                    </TouchableOpacity>
                  </>
                  :null
                }
              </>
              :null
            }
            <TouchableOpacity onPress={() => false}>
                <View style={{width: 130}}>
                  <MyPicker
                      style={[styles.headerDropdownButton]}
                      getTheApp={true}
                      items={['iOS', 'Android']}
                      onValueChange={(item, itemIndex) => {
                        if(item.value === 'Android'){
                          Linking.openURL('https://play.google.com/store/apps/details?id=gr.mdata.footprint');
                        }
                        else if(item.value === 'iOS'){
                          Linking.openURL('https://apps.apple.com/us/app/application-footprint/id6446500693');
                        }
                      }}
                      value={'GET THE APP'}
                      placeholder={''}
                      // fancyPlaceholder={true}
                  />
                </View>
            </TouchableOpacity>
            { props.user &&
              <>
              {
                width < 992?
                <TouchableOpacity onPress={() => props.bottomSheet.current?.present()} style={{marginLeft: 15}} hitSlop={{top: 20, bottom: 20, left: 0, right: 20}}>
                  <View>
                        {/* <DrawerToggleButton/> */}
                      <FontAwesome5 name="bars" size={20} color="#461b6f" />
                  </View>
                </TouchableOpacity>
                :null
              }
              </>
            }
        </View>
        {
          props.user?
          <View style={styles.webExtraHeader}>
            <TouchableOpacity onPress={() => LinkTo('About')}>
              <MyText style={[css.bold, css.s, {color: 'white', marginRight: 15}]}>About</MyText>
            </TouchableOpacity>
            <View>
              <MyText style={[css.bold, css.s, {color: 'white', marginRight: 15}]}>|</MyText>
            </View>
            <TouchableOpacity onPress={() => false}>
              <MyText style={[css.bold, css.s, {color: 'white', marginRight: 15}]}>Help</MyText>
            </TouchableOpacity>
            <View>
              <MyText style={[css.bold, css.s, {color: 'white', marginRight: 15}]}>|</MyText>
            </View>
            <TouchableOpacity onPress={() => LinkTo('My Profile')}>
              <MyText style={[css.bold, css.s, {color: 'white'}]}>{props.user.firstName} {props.user.lastName}</MyText>
            </TouchableOpacity>
          </View>
          :null
        }
      </View>
    </View>
  }
  const MyNotifications = () => {
    if(Platform.OS === 'web'){
      modal.confirm.show('Warning', 'This functionality is available only in the $android & $ios mobile apps.');
    }
    else{
      props.navigation.push('My Notifications')
    }
  }
  const Mobile = () => {
    return <View style={styles.background}>
        <TouchableWithoutFeedback onPress={() => Home()}>
          <MySVG source={Logo} style={styles.logo} resizeMode={'contain'} />
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback onPress={() => Home()}>
          <MyText style={[css.l, styles.text]}>
              FOOTPRINT
          </MyText>
        </TouchableWithoutFeedback>
        { props.user &&
          <>
          <TouchableOpacity onPress={() => MyNotifications()}>
            <View style={styles.notificationsContainer}>
                <MaterialCommunityIcons name="bell" size={30} color="white" />
                { count != 0 &&
                  <MyText style={[css.sm, styles.notifications]}>
                      {count}
                  </MyText>
                }
            </View>
          </TouchableOpacity>
          {
            width < 992?
            <TouchableOpacity onPress={() => props.bottomSheet.current?.present()} style={{marginLeft: 15}} hitSlop={{top: 20, bottom: 20, left: 0, right: 20}}>
              <View>
                    {/* <DrawerToggleButton/> */}
                  <FontAwesome5 name="bars" size={20} color="white" />
              </View>
            </TouchableOpacity>
            :null
          }
          </>
        }
    </View>
  }

  if(Platform.OS==='web'){
    return <Web/>
  }
  else{
    return <Mobile/>
  }
}

const styles = StyleSheet.create({
  background: {
    display: 'flex',
    backgroundColor: '#461b6f',
    paddingTop: Platform.OS==='web'?15:45,
    paddingBottom: 15,
    paddingRight: 20,
    paddingLeft: 20,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    zIndex: 10000,
    overflow: 'hidden'
  },
  webBackground: {
    display: 'flex',
    backgroundColor: 'white',
    paddingTop: Platform.OS==='web'?15:45,
    paddingBottom: 15,
    paddingRight: 20,
    paddingLeft: 20,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    zIndex: 10000,
    overflow: 'hidden',
  },
  webHomeBackground: {
    display: 'flex',
    backgroundColor: 'transparent',
    paddingTop: Platform.OS==='web'?15:45,
    paddingBottom: 15,
    paddingRight: 20,
    paddingLeft: 20,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    zIndex: 10000,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  headerText: {
    color: 'white',
    marginRight: 20,
    fontFamily: 'Futura Heavy',
  },
  headerTextSmall: {
    color: 'white',
    marginRight: 5,
    fontFamily: 'Futura Heavy',
  },
  headerButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#461b6f',
    color: 'white',
    borderRadius: 0,
    overflow: 'hidden',
    textAlign: 'center'
  },
  headerDropdownButton: {
    backgroundColor: '#461b6f',
    borderColor: '#461b6f',
    color: 'white',
    borderRadius: 0,
    textAlign: 'center'
  },
  text: {
    color: 'white',
    fontFamily: 'Futura Heavy',
    flexGrow: 1,
    flexShrink: 1,
    cursor: 'pointer'
  },
  logo: {
      width: 34,
      height: 34,
      marginRight: 10,
      backgroundSize: 'contain',
      cursor: 'pointer'
  },
  notificationsContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
  },
  notifications: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: -4,
      top: 2,
      borderRadius: 10,
      overflow: 'hidden',
      width: 20,
      lineHeight: 19,
      height: 20,
      color: 'white',
      borderWidth: 1,
      borderColor: 'white',
      backgroundColor: '#461b6f',
      fontWeight: 'bold',
      textAlign: 'center'
  },
  webExtraHeader: {
    backgroundColor: '#461b6f',
    paddingVertical: 14,
    paddingHorizontal: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    zIndex: 10000,
    overflow: 'hidden',
    justifyContent: 'flex-end'
  },
});

export default Header

import React from 'react';
import {
    View
} from 'react-native'
import { ScrollView } from 'react-native-gesture-handler';
import stylesheet from './Style'
import MyText from './MyText';

const AboutUs = (props) => {
    const css = stylesheet();
    
  return (
    <ScrollView vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
    {/* <TouchableWithoutFeedback> */}
        <View style={[css.background, css.formBackground]}>
            <View style={[{zIndex: 10}, css.form]}>
                <MyText style={[css.formTitle, css.bold]}>
                    About Us
                </MyText>
                <View
                style={{
                    borderBottomColor: '#f3f1f8',
                    borderBottomWidth: 2,
                }}
                />
                <View style={{paddingVertical: 20}}>
                    <MyText style={css.p}>
                        Footprint Ventures is a dynamic and forward-thinking company specializing in the design, development, and management of innovative technology solutions for a wide range of industries. With a deep commitment to driving progress and efficiency, Footprint Ventures leverages cutting-edge technologies to create bespoke solutions tailored to the unique needs of each client. Whether it&#39;s pioneering software applications, revolutionary hardware systems, or comprehensive digital strategies, Footprint Ventures is dedicated to leaving a positive and lasting mark in the world of innovation and technology.
                    </MyText>
                </View>
            </View>
        </View>
     </ScrollView>
  );
}

export default AboutUs

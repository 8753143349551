import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native';
import MyCheckbox from '../../MyCheckbox';
import MyText from '../../MyText';
import AnnexForm from '../AnnexForm';
import { AntDesign } from '@expo/vector-icons'; 
import stylesheet from '../../Style'
import { TouchableOpacity } from 'react-native-gesture-handler';

const AnnexIVHazardous = (props) => {
    const css = stylesheet();
    
    const [collapsed, setCollapsed] = React.useState(true);

    const [sewage, setSewage] = React.useState(props?.value?.sewage?.active??false);

    const [sewageData, setSewageData] = React.useState(props?.value?.sewage?.data??{});

    const [hasChecked, setHasChecked] = React.useState(false);

    React.useEffect(() => {
        setCollapsed(!props.checked);
    }, [props.checked]);
    
    React.useEffect(() => {
        onChange();
    }, [sewage,
        sewageData]);

    const onChange = () => {
        props.onChange({
            sewage: {
                active: sewage,
                data: sewageData
            },
        });
    }

    const Block = (check, setCheck, data, setData, label, ew) => {
        return (<>
            <View style={styles.row}>
                <MyCheckbox onClick={() => {setCheck(prev => !prev);}} checked={check} label={label} isInfo={props.isInfo}/>
            </View>
            {
                check && 
                <View style={[styles.row]}>
                    <AnnexForm ew={ew} wasteMetrics={props.wasteMetrics} onChange={setData} value={data} isInfo={props.isInfo}/>
                </View>
            }
        </>);
    }

    return (
        <View style={styles.annexContainer}>
            <TouchableOpacity activeOpacity={0.7} onPress={() => {setCollapsed(prev => !prev)}}>
                <MyText style={[css.m, styles.annexTitle, hasChecked?css.bold:css.normal]}>ANNEX IV - Sewage</MyText>
                <View style={{position: 'absolute', right: 10, top: 14}}>
                    <AntDesign name="down" size={12} color="#461b6f" style={{transform: [{rotate: collapsed?'0deg':'180deg'}]}}/>
                </View>
            </TouchableOpacity>
            <View style={styles.formContainer}>
                <View style={{display: collapsed?'none':'flex'}}>
                    {Block(sewage, setSewage, sewageData, setSewageData, 'Sewage', '902901')}
                </View>
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    annexContainer: {
        marginBottom: 0
    },
    annexTitle: {
        backgroundColor: '#e9e3f0',
        color: '#57307e',
        textAlign: 'center',
        width: '100%',
        padding: 10
    },
    formContainer: {
        padding: 20,
        paddingTop: 15,
        paddingVertical: 0,
        flex: 1
    },
    row: {
        marginBottom: 15
    }
})
export default AnnexIVHazardous
import React, { FunctionComponent, useEffect } from 'react'
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity
} from 'react-native'
import MyText from './MyText';
import stylesheet from './Style'
import { Ionicons } from '@expo/vector-icons'; 

const MyCheckbox = (props) => {
    const css = stylesheet();
    
//   const [value, setValue] = React.useState(false);
    
  return (
    <TouchableOpacity style={styles.container} onPress={() => props.isInfo?false:props.onClick()}>
        <View style={[{borderColor: props.checked?'#532b75':'#bdbdbd'}, styles.box]}>
            { props.checked &&
                <Ionicons name="checkmark-sharp" size={18} color="#532b75" />
            }
        </View>
        <MyText style={styles.label}>
            {props.label??''}
        </MyText>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center'
    },
    box: {
        width: 24,
        height: 24,
        display: 'flex',
        borderWidth: 0.5,
        // borderColor: '#bdbdbd',
        borderRadius: 8,
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center'
    },
    label: {
        marginLeft: 10,
        // alignItems: 'center',
        // lineHeight: 24,
    }
})
export default MyCheckbox
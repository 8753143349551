// Custom Navigation Drawer / Sidebar with Image and Icon in Menu Options
// https://aboutreact.com/custom-navigation-drawer-sidebar-with-image-and-icon-in-menu-options/

import React from 'react';
import {
  SafeAreaView,
  View,
  StyleSheet,
  Image,
  Text,
  Linking,
  TouchableOpacity
} from 'react-native';
import stylesheet from './Style'
import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import MyText from './MyText';
import Logo from './assets/icons/logo-purple.svg';
import MySVG from './assets/MySVG';
import { MaterialIcons, Entypo, Feather } from '@expo/vector-icons'; 

import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as db from './database';

const CustomSidebarMenu = (props) => {
  const css = stylesheet();
  
  const Logout = () => {
    axios.get('/api/user/logout.php')
      .then(async() => {
        await AsyncStorage.removeItem('token');
        await AsyncStorage.removeItem('user');
        props.setUser(null);
        props.navigation.push('Landing Page');
        db.logout();
      })
  }
  return (
    <SafeAreaView style={{ flex: 1, zIndex: 2 }}>
      {/*Top Large Image */}
      <MySVG source={Logo} style={styles.sideMenuProfileIcon} resizeMode={'contain'} />
      <DrawerContentScrollView {...props}>
        <View style={{padding: 10, paddingTop: 0, marginBottom: 0}}>
          <View style={{borderBottomWidth: 1, borderColor: 'lightgray'}}>
            {
              props.user &&
              <>
                <MyText style={{marginBottom: 10}}>
                  {props.user.firstName} {props.user.lastName}
                </MyText>
                <TouchableOpacity onPress={() => Logout()}>
                  <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 10}}>
                    <MaterialIcons name="logout" size={24} color="#461b6f" /> 
                    <MyText style={{marginLeft: 5}}>
                      Logout
                    </MyText>
                  </View>
                </TouchableOpacity>
              </>
            }
          </View>
        </View>
        <DrawerItemList {...props}/>
      </DrawerContentScrollView>
      <MyText 
            onPress={() => {
                Linking.openURL('https://mdata.gr');
            }}
            style={[css.sm, { textAlign: 'center', color: 'grey', marginBottom: 10 }]}>
        www.mdata.gr
      </MyText>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  sideMenuProfileIcon: {
    resizeMode: 'center',
    width: 100,
    height: 100,
    borderRadius: 100 / 2,
    alignSelf: 'center',
    marginTop: 40
  },
  iconStyle: {
    width: 15,
    height: 15,
    marginHorizontal: 5,
  },
  customItem: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default CustomSidebarMenu;

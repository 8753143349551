import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { SelectList } from 'react-native-dropdown-select-list'
import { Platform, TouchableOpacity } from 'react-native';
import { AntDesign, MaterialIcons } from '@expo/vector-icons'; 
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import { Dropdown } from 'react-native-element-dropdown';
import stylesheet from './Style'

const MyPicker = (props) => {
    const css = stylesheet();
    
    const inputRef = React.useRef();

    const [isFocus, setIsFocus] = React.useState(false);

    const dropdownRef = React.useRef(null);
    const viewRef = React.useRef(null);

    const [open, setOpen] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);
    const [defaultValue, setDefaultValue] = React.useState({index: -1, value: props.isInfo?'':'Please select...'});
    const [value, setValue] = React.useState(props.value??null);
    const [found, setFound] = React.useState(false);
    const [pageY, setPageY] = React.useState(0);
    const [borderRadiusBottom, setBorderRadiusBottom] = React.useState(5);
    const [borderRadiusTop, setBorderRadiusTop] = React.useState(0);

    const [search, setSearch] = React.useState('');
    
    React.useEffect(() => {
        let tempItems = props.items??[];
        if(props.items && props.items.length == 0){
            tempItems = [];
        }
        setItems(tempItems.map(item => {
            if(item.key){ //It's an object and we have to pass the key from the props
                return {key: item.key, value: item.value, label: item.value}
            }
            return {value: item, label: item}
        }));
    },[props.items]);

    React.useEffect(() => {
        let tempItems = [];
        let counter = 0;
        if(!search){
            setFiltered(items);
            // if(open){
            //     dropdownRef.current.hide();
            //     dropdownRef.current.show();
            // }
        }
        else{
            items.forEach(item => {
                // item.disabled = true;
                if(item.value.toLowerCase().includes(search.toLowerCase()) || !search){
                    // item.disabled = false;
                    counter++;
                    tempItems.push(item);
                }
            })
            setFiltered(tempItems);
        }
    },[items, search]);

    React.useEffect(() => {
        let found = false;
        items.forEach((item, idx) => {
            if(item.value == props.value){
                found = true;
                setDefaultValue({index: idx, value: item.value});
                if(dropdownRef && dropdownRef.current)
                    dropdownRef.current.select(idx);
                return;
            }
        });
        setFound(found);
    },[filtered]);

    const renderButtonText = (rowData) => {
        const {key, value, disabled} = rowData;
        return value;
    }
    const renderRow = (rowData, rowID, highlighted) => {
        const {key, value, disabled} = rowData;
        return <MyText style={[css.s, {height: disabled?0:null, paddingVertical: disabled?0:7, backgroundColor: 'white', color: highlighted?'#474747':'#7d7d7d'}]}>{value}</MyText>;
    }

    const measureComponent = component => {
        return new Promise((resolve, reject) => {
          component.measure((x, y, width, height, pageX, pageY) => {
            resolve({ x, y, width, height, pageX, pageY })
          })
        })
    }

    const handleAdjustFrame = (pos) => {
        let length = 0;
        let filteredLength = 0;
        filtered.forEach(item => {
            if(!item.disabled)
                filteredLength++;
        })
        if(!filtered || filteredLength == 0){
            length = 1;
        }
        else{
            length = filteredLength;
        }
        let newHeight = length*33 + (length-1) + 5 + (props.showSearch?35:0); //35 is for the search
        if(newHeight > 170)
            newHeight = 170;
        pos.height = newHeight;
        if(pos.right){
            pos.right -= 4;
        }
        if(pos.left){
            pos.left -= 8;
        }

        if(pageY > pos.top){ //dropup
            if(pos.height < 170){ //have to lower the dropdown to accomodate for the missing height
                pos.top += 170 - pos.height;
            }
            setBorderRadiusBottom(0);
            setBorderRadiusTop(5);
        }
        else{ //dropdown
            setBorderRadiusBottom(5);
            setBorderRadiusTop(0);
        }

        if(Platform.OS === 'web'){
            pos.top += 3;
        }
        else{
            if(pageY > pos.top){ //dropup
                pos.top -= 27;
            }
            else{
                pos.top -= 30;
            }
        }
        
        // const measure = await measureComponent(viewRef.current);
        // console.log(measure);
        return pos;
    }

    // React.useEffect(() => {
    //     const interval = setInterval(async () => {
    //         if(viewRef && viewRef.current){
    //             const measure = await measureComponent(viewRef.current);
    //             if(measure.pageY){
    //                 setPageY(measure.pageY);
    //             }
    //         }
    //     }, 500);
    //     return () => clearInterval(interval);
    // }, []);

    const renderItem = item => {
        return (
          <View style={styles.item}>
            <View style={{borderBottomWidth: 0.7, borderColor: '#ebe6f2'}}>
                <MyText style={[css.s, {
                    fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                    color: '#474747',
                    // lineHeight: 30,
                    paddingVertical: 7,
                    opacity: item.value === props.value?1:0.5
                    // paddingRight: 2
                }]}>{item.label}</MyText>
            </View>
          </View>
        );
    };

    /*
    IMPORTANT changes made to the library:
    1. Replaced in styles.ts in input: {} the margin: 6 to marginVertical: 6
    2. Removed "left: left" from styleVertical: ViewStyle (around line 562) and added it to <View style={Stylesheet.flatten... around line 605)
    */

    return (
        <View style={{flex: 1, width: '100%'}} ref={viewRef} collapsable={false}>
            {
                props.isInfo && !found?
                <MyTextInput
                    style={css.formRowInput}
                    value={props.value}
                    isInfo={props.isInfo}
                />
                :
                <Dropdown
                    style={[css.s, {
                        fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                        borderWidth: props.isInfo?0:1,
                        paddingBottom: 1,
                        paddingRight: props.isInfo?0:3,
                        paddingLeft: props.isInfo?0:6,
                        borderColor: props.hasError?'red':'#bdbdbd',
                        backgroundColor: (props.editable===false)&&(props.isInfo===false||!props.isInfo)?'#e6e6e6':'white',
                        color: '#474747',
                        borderRadius: 8,
                        minHeight: props.getTheApp?35:33,
                        maxHeight: props.getTheApp?35:33,
                        overflow: 'hidden'
                    }, props.style??null]}
                    containerStyle={[css.s, {
                        width: props.width??undefined,
                        borderTopLeftRadius: borderRadiusTop,
                        borderTopRightRadius: borderRadiusTop,
                        borderBottomLeftRadius: borderRadiusBottom,
                        borderBottomRightRadius: borderRadiusBottom,
                        // overflow: 'hidden',
                        // borderColor: props.hasError?'red':'#bdbdbd',
                        borderWidth: 0,
                        fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                        shadowColor: '#000',
                        shadowOffset: { width: 0, height: 6 },
                        shadowOpacity: 0.5,
                        shadowRadius: 7,
                        elevation: 15,
                        paddingHorizontal: 7,
                        // marginLeft: -7,
                    }]}
                    placeholderStyle={[css.s, {
                        fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                        color: '#b9bab7',
                        paddingTop: Platform.OS==='android'?0:3,
                        // lineHeight: props.isInfo?33:30,
                        // paddingRight: 2
                    }]}
                    selectedTextStyle={[props.isInfo?css.sm:css.s, {
                            fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                            color: props.isInfo?'#461b6f':'#474747',
                            paddingTop: Platform.OS==='android'?0:3,
                        }, 
                        props.getTheApp?{
                            fontSize: 0.001
                        }: null
                    ]}
                    inputSearchStyle={[css.s, {
                        fontFamily: props.getTheApp?'Futura Heavy':'Comfortaa-Regular',
                        color: '#474747',
                        height: 33,
                        borderRadius: 8,
                        overflow: 'hidden'
                    }]}
                    onChange={(item) => {
                        // setValue(item.value);
                        setIsFocus(false);
                        props.onValueChange({key: item.key, value: item.label});
                    }}
                    //Edited styles.ts "item" to remove padding (probably not necessary anymore due to custom renderItem)
                    renderItem={renderItem}
                    activeColor='white'
                    data={items}
                    //Edited styles.ts "input" to remove margin and only set marginVertical to 6
                    search={props.showSearch}
                    disable={props.editable===false || props.isInfo}
                    maxHeight={200}
                    renderRightIcon={props.isInfo?(()=>{}):null}
                    autoScroll={false}
                    labelField="label"
                    valueField="value"
                    placeholder={props.fancyPlaceholder?'':(props.editable===false || props.isInfo ? '':(props.placeholder??'Please select...'))}
                    searchPlaceholder="Search..."
                    value={props.value}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}
                    // renderLeftIcon={() => (
                    //     <AntDesign
                    //     style={styles.icon}
                    //     color={isFocus ? 'blue' : 'black'}
                    //     name="Safety"
                    //     size={20}
                    //     />
                    // )}
                    />
                }
                {
                    props.fancyPlaceholder &&
                    <MyText style={[styles(props, isFocus).placeholder, (isFocus||props.value)?css.xxs:css.s]}>
                        {props.placeholder}
                    </MyText>
                }
                {
                    props.getTheApp &&
                    <MyText style={[styles(props, isFocus).getTheApp, css.sm]}>
                        {'GET THE APP'}
                    </MyText>
                }
        </View>
    )
}
const styles = (props, isFocus) => StyleSheet.create({
    placeholder: {
        position: 'absolute',
        left: 0,
        top: Platform.OS==='android'?((isFocus||props.value)?-9:1):((isFocus||props.value)?-6:4), //for the border maybe(?)
        paddingLeft: (props.icon && !props.isInfo)?5:null,
        padding: props.isInfo?0:7,
        color: '#474747',
        opacity: 0.5,
        pointerEvents: 'none'
    },
    getTheApp: {
        position: 'absolute',
        left: 0,
        top: Platform.OS==='android'?1:3,
        // paddingLeft: 5,
        padding: 7,
        paddingLeft: 14,
        color: 'white',
        opacity: 1,
        pointerEvents: 'none',
        fontFamily: 'Futura Heavy',
        textAlign: 'center'
    }
})
// const areEqual = (prevProps, currentProps) => {
//     if(!currentProps.items) // If we don't have any items, don't render yet 
//         return true;

//     if(prevProps.items?.length != currentProps.items?.length){
//         return false;
//     }
//     return true;
// }
// export default React.memo(MyPicker, areEqual);
export default MyPicker
import Dexie from 'dexie';
import { Platform } from 'react-native';
import * as SQLite from 'expo-sqlite';

import NetInfo from '@react-native-community/netinfo';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

let db;

const FormatDatetime = (dt) => {
    if(!dt)
        return '';
    const padLeft = (num) => {
        if(num < 10)
            return '0'+num;
        return num;
    }
    return dt.getUTCFullYear() + '-' + (dt.getUTCMonth()+1) + '-' + padLeft(dt.getUTCDate()) + ' ' + padLeft(dt.getUTCHours()) + ':' + padLeft(dt.getUTCMinutes()) + ':' + padLeft(dt.getUTCSeconds());
}

class MyDexie extends Dexie {
  constructor(name) {
    if(Platform.OS === 'web'){
        super(name);
    }
    else{
        const setGlobalVars = require('indexeddbshim/dist/indexeddbshim-noninvasive');
        let win = {}
        setGlobalVars(win, { 
            checkOrigin: false, 
            win: SQLite, 
            deleteDatabaseFiles: false,
            useSQLiteIndexes: true
        });
        super(name, {
            indexedDB: win.indexedDB,
            IDBKeyRange: win.IDBKeyRange,
        });
    }
  }
}
export const initDatabase = () => {
    return new Promise(async(resolve, reject) => {
        try{
            db = new MyDexie('footprint');
            db.version(4).stores({
                requests: 'id',
                pending_requests: '++id',
                countries: 'key',
                collection_means: 'key',
                company_representatives: 'key',
                requested_purposes: 'key',
                ships: 'imo, shipName',
                ship_types: 'key',
                waste_metrics: 'key',
                ports: 'key',
                locations: 'key',
                agents: 'key, value',
                agent_port: '[agent+port]',
                documents: '++id,user,file,datetime'
            });
            resolve();
        }
        catch(e){
            reject(e);
        }
    });
}
export const syncFilters = (callback) => {
    if(!db)
      return;
    return new Promise(async(resolve, reject) => {
      try{
        const state = await NetInfo.fetch();
        if(state.isConnected){
          const new_updated_at = FormatDatetime(new Date());
          const res = await axios.get('/api/getFilters.php', {
            params: {
                updated_at: await AsyncStorage.getItem('filters_updated_at_2')??''
            }
          })
          let totalLength = res.data.countries.length +
                      res.data.collectionMeans.length +
                      res.data.companyRepresentatives.length +
                      res.data.requestedPurposes.length +
                      res.data.ships.length +
                      res.data.shipTypes.length +
                      res.data.wasteMetrics.length +
                      res.data.ports.length +
                      res.data.locations.length +
                      res.data.agents.length +
                      res.data.agentPort.length;
          let finished = 0;
          const updateProgress = () => {
            callback((++finished) / totalLength);
          }
          let promises = [
            saveAll('countries', res.data.countries, updateProgress),
            saveAll('collection_means', res.data.collectionMeans, updateProgress),
            saveAll('company_representatives', res.data.companyRepresentatives, updateProgress),
            saveAll('requested_purposes', res.data.requestedPurposes, updateProgress),
            saveAll('ships', res.data.ships, updateProgress),
            saveAll('ship_types', res.data.shipTypes, updateProgress),
            saveAll('waste_metrics', res.data.wasteMetrics, updateProgress),
            saveAll('ports', res.data.ports, updateProgress),
            saveAll('locations', res.data.locations, updateProgress),
            saveAll('agents', res.data.agents, updateProgress),
            saveAll('agent_port', res.data.agentPort, updateProgress)
          ];
          await Promise.all(promises);
          if(finished == totalLength){ // Making sure that everything has been stored correctly
            await AsyncStorage.setItem('filters_updated_at_2', new_updated_at);
          }
        }
        resolve();
      }
      catch(e) {
        reject(e);
      }
    });
}
export const get = (table, id) => {
  if(!db)
    return;
  return new Promise(async(resolve, reject) => {
    try{        
        resolve(await db[table].get(id));
    }
    catch(e){
        reject(e);
    }
  });
}
export const getAll = (table, sortBy) => {
  if(!db)
    return;
  return new Promise(async(resolve, reject) => {
    try{
      let data = await db[table].toArray();
      if(sortBy){
        data = data.sort((a,b) => (a[sortBy] > b[sortBy]) ? 1 : ((b[sortBy] > a[sortBy]) ? -1 : 0))
      }
      resolve(data);
    }
    catch(e){
        reject(e);
    }
  });
}
export const getRequest = (id) => {
  if(!db)
    return;
  return new Promise(async(resolve, reject) => {
    try{
      const state = await NetInfo.fetch();
      if(state.isConnected){
        const res = await axios.get('/api/getRequest.php', {
          params: {
              id: id
          }
        })
        if(res?.data?.length > 0)
          await save('requests', res.data[0]);
      }
      resolve(await db.requests.get(id.toString()));
    }
    catch(e) {
      reject(e);
    }
  });
}
export const getAllRequests = () => {
  if(!db)
    return;
  return new Promise(async(resolve, reject) => {
    try{
      const state = await NetInfo.fetch();
      if(state.isConnected){
        const new_updated_at = FormatDatetime(new Date());
        const res = await axios.get('/api/getRequests.php', {
          params: {
              updated_at: await AsyncStorage.getItem('requests_updated_at')??''
          }
        })
        await saveAll('requests', res.data);
        await AsyncStorage.setItem('requests_updated_at', new_updated_at);
      }
      resolve(await db.requests.toArray());
    }
    catch(e) {
      reject(e);
    }
  });
}
export const save = (table, data) => {
    if(!db)
      return;
    return new Promise(async(resolve, reject) => {
      try{
        await db[table].put(data);
        resolve();
      } catch(e) {
        reject(e);
      }
    });
  }
export const saveAll = (table, dataArray, callback) => {
    if(!db)
      return;
    const promises = dataArray.map(async (data) => {
      await db[table].put(data).then(() => {
        if(callback)
          callback();
      }).catch((e) => {
        console.log(e);
      });
    });
    return Promise.all(promises);
}

export const remove = (table, id) => {
  if(!db)
    return;
  return new Promise(async(resolve, reject) => {
    try{
      await db[table].delete(id);
      resolve();
    } catch(e) {
      reject(e);
    }
  });
}

export const saveDocument = (user, name, base64) => {
  return db.documents.add({
    user: user,
    name: name,
    file: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+base64,
    datetime: new Date().toISOString()
  });
}
export const deleteDocument = (id) => {
  return db.documents.delete(id);
}

export const getDocuments = (user) => {
  return db.documents.where({user: user}).toArray();
}

export const logout = () => {
    if(!db)
      return;
    return new Promise(async(resolve, reject) => {
      try{
        await db.requests.clear();
        await db.pending_requests.clear();
  
        await AsyncStorage.removeItem('requests_updated_at');
  
        resolve();
      } catch(e) {
        reject(e);
      }
    })
}
import React, { FunctionComponent, useEffect } from 'react'
import {
  Text,
  StyleSheet,
  View,
  Platform
} from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import stylesheet from './Style'

const MyTextarea = (props) => {
  const css = stylesheet();

  React.useEffect(() => {
    if(props.value != value){
      setValue(props.value);
    }
  },[props.value]);
  
  const [value, setValue] = React.useState('');
  
  return (
      <TextInput 
        inputMode={props.inputMode}
        keyboardType={props.keyboardType}
        multiline={true}
        numberOfLines={10}
        placeholder={props.isInfo?'':props.placeholder}
        onChangeText={(e) => {setValue(e); props.onChangeTextImmediate?props.onChangeTextImmediate(e):false}}
        value={value}
        onBlur={() => {props.onChangeText(value)}}
        editable={props.isInfo?false:props.editable}
        style={
            [
              props.isInfo?css.sm:css.s,
              {
                  borderWidth: props.isInfo?0:1,
                  height: 99,
                  minHeight: 99,
                  maxHeight: 99,
                  borderColor: '#bdbdbd',
                  borderRadius: props.isInfo?0:8,
                  overflow: 'hidden',
                  width: '100%',
                  padding: props.isInfo?0:7,
                  fontFamily: 'Comfortaa-Regular',
                  color: props.isInfo?'#461b6f':'#474747',
                  textAlignVertical: 'top',
              },
              Platform.OS==='web'?{outline: 0}:{},
              props.style
            ]
        }
      />
  )
}
const styles = StyleSheet.create({
})
export default MyTextarea
import DateTimePicker, { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import React, { FunctionComponent, useRef } from 'react'
import { StyleSheet, Text, View, TextInput, Platform } from 'react-native';
import { EvilIcons, Entypo } from '@expo/vector-icons'; 
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import stylesheet from './Style'
import { TouchableOpacity } from 'react-native-gesture-handler';
import Popover from 'react-native-popover-view';
import { SelectList } from 'react-native-dropdown-select-list';

const MyDatetimePicker = (props) => {
    const css = stylesheet();

    const webInputDateRef = useRef(null);
    const webInputTimeRef = useRef(null);

    const [date, setDate] = React.useState(new Date());
    const [mode, setMode] = React.useState('date');
    const [show, setShow] = React.useState(false);

    const [dateString, setDateString] = React.useState('Date');
    const [timeString, setTimeString] = React.useState('Time');

    const [invalidRange, setInvalidRange] = React.useState(false);

    React.useEffect(() => {
        if(props.validation == 'requestedDelivery'){
            setInvalidRange(false);
            if(props.arrival && props.departure){
                let datetime = new Date(props.value);
                let webDatetime = WebTimezoneFix(datetime);
                let dt = Platform.OS==='web'?webDatetime:datetime;
                let departure = Platform.OS==='web'?WebTimezoneFix(props.departure):new Date(props.departure);
                let arrival = Platform.OS==='web'?WebTimezoneFix(props.arrival):new Date(props.arrival);

                if(dt.getTime() > departure.getTime()){
                    setInvalidRange(true);
                }
                if(dt.getTime() < arrival.getTime()){
                    setInvalidRange(true);
                }
            }
        }
        else if(props.validation == 'departure'){
            setInvalidRange(false);
            if(props.arrival){
                let datetime = new Date(props.value);
                let webDatetime = WebTimezoneFix(datetime);
                let dt = Platform.OS==='web'?webDatetime:datetime;
                let arrival = Platform.OS==='web'?WebTimezoneFix(props.arrival):new Date(props.arrival);
                
                if(dt.getTime() < arrival.getTime()){
                    setInvalidRange(true);
                }
            }
        }
        else if(props.validation == 'meanFrom'){
            setInvalidRange(false);
            let datetime = new Date(props.value);
            let webDatetime = WebTimezoneFix(datetime);
            let dt = Platform.OS==='web'?webDatetime:datetime;
            if(props.arrival && props.departure){
                let arrival = Platform.OS==='web'?WebTimezoneFix(props.arrival):new Date(props.arrival);
                let departure = Platform.OS==='web'?WebTimezoneFix(props.departure):new Date(props.departure);
                
                if(dt.getTime() < arrival.getTime() || dt.getTime() > departure.getTime()){
                    setInvalidRange(true);
                }
            }
            if(props.meanTo){
                let meanTo = Platform.OS==='web'?WebTimezoneFix(props.meanTo):new Date(props.meanTo);
                if(dt.getTime() > meanTo.getTime()){
                    setInvalidRange(true);
                }
            }
        }
        else if(props.validation == 'meanTo'){
            setInvalidRange(false);
            let datetime = new Date(props.value);
            let webDatetime = WebTimezoneFix(datetime);
            let dt = Platform.OS==='web'?webDatetime:datetime;
            if(props.arrival && props.departure){
                let arrival = Platform.OS==='web'?WebTimezoneFix(props.arrival):new Date(props.arrival);
                let departure = Platform.OS==='web'?WebTimezoneFix(props.departure):new Date(props.departure);
                
                if(dt.getTime() < arrival.getTime() || dt.getTime() > departure.getTime()){
                    setInvalidRange(true);
                }
            }
            if(props.meanFrom){
                let meanFrom = Platform.OS==='web'?WebTimezoneFix(props.meanFrom):new Date(props.meanFrom);
                if(dt.getTime() < meanFrom.getTime()){
                    setInvalidRange(true);
                }
            }
        }
    },[props.arrival, props.departure, props.value, props.meanTo, props.meanFrom]);

    const padLeft = (num) => {
        if(num < 10)
            return '0'+num;
        return num;
    }

    const onChange = (event, selectedDate, mode) => {
        if(event.type == 'set'){ //dismissed = canceled, set = modified
            const currentDate = selectedDate;
            setShow(false);
            console.log(selectedDate);
            props.onChange(currentDate);
        }
    };

    const FormatDate = (dt) => {
        if(!dt)
            return props.isInfo?'':'Date';
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return padLeft(dt.getDate() + ' ' + months[dt.getMonth()] + ', ' + dt.getFullYear().toString().slice(2));
    }
    const FormatTime = (dt) => {
        if(!dt)
            return props.isInfo?'':'Time';
        return [padLeft(dt.getHours()), padLeft(dt.getMinutes())].join(':');
    }

    const showMode = (currentMode) => {
        if (Platform.OS === 'android') {
            setShow(false);
            DateTimePickerAndroid.open({
                value: props.value??new Date(),
                onChange,
                mode: currentMode,
                is24Hour: true,
            });
        }
        else{
            setShow(true);
        }
        setMode(currentMode);
    };

    const showDatepicker = () => {
        if(Platform.OS === 'web'){
            webInputDateRef.current.showPicker();
        }
        else{
            showMode('date');
        }
    };

    const showTimepicker = () => {
        if(Platform.OS === 'web'){
            webInputTimeRef.current.showPicker();
        }
        else{
            showMode('time');
        }
    };

    const showDatetimePicker = () => {
        if(Platform.OS === 'web'){
            webInputDateRef.current.showPicker();
        }
        else{
            showMode('datetime');
        }
    };

    const onChangeWeb = (selectedDate) => {
        console.log(selectedDate);
        if(selectedDate){
            function getTimezoneOffset() {
                function z(n){return (n<10? '0' : '') + n}
                var offset = new Date().getTimezoneOffset();
                var sign = offset < 0? '+' : '-';
                offset = Math.abs(offset);
                return sign + z(offset/60 | 0) + ':' + z(offset%60);
            }            
            const currentDate = selectedDate;
            props.onChange(currentDate+getTimezoneOffset());
        }
        else{
            setDate(null);
            props.onChange(null);
        }
    }

    const WebTimezoneFix = (dt) => {
        let webDatetime;
        if(dt){
            webDatetime = new Date(dt);
        }
        else{
            webDatetime = new Date();
        }
        webDatetime.setMinutes(webDatetime.getMinutes() - webDatetime.getTimezoneOffset());

        return webDatetime;
    }

    let datetime = props.value??new Date();
    let webDatetime = WebTimezoneFix(props.value);

    let fontSize = css.s;
    if(props.small){
        fontSize = css.xs;
    }
    if(props.isInfo){
        fontSize = css.sm;
    }

    let color = props.value?'#474747':'#b9bab7';
    if(props.isInfo){
        color = '#461b6f';
    }

    return (
        <>
            <Popover isVisible={show} onRequestClose={() => setShow(false)}>
                <DateTimePicker
                    style={{padding: 15}}
                    testID="dateTimePicker"
                    value={datetime}
                    mode={mode}
                    is24Hour={true}
                    display={'inline'}
                    onChange={onChange}
                    accentColor='#461b6f'
                />
            </Popover>
            <View style={styles.container}>
                <View style={styles.date}>
                    <TouchableOpacity onPress={() => !props.isInfo?showDatepicker():false} activeOpacity={1}>
                        {
                            Platform.OS==='web'?
                            <input type="datetime-local" ref={webInputDateRef} style={styles.webInput} value={webDatetime.toISOString().slice(0,16)} onChange={(e) => onChangeWeb(e.target.value)} />
                            :null
                        }
                        <MyTextInput onPress={() => !props.isInfo?showDatetimePicker():false} icon="date" hasError={props.hasError || invalidRange} editable={false} isInfo={props.isInfo} value={FormatDate(props.value)} style={[{color: color}, fontSize]}/>
                    </TouchableOpacity>
                </View>
                <View style={styles.time}>
                    <TouchableOpacity onPress={() => !props.isInfo?showTimepicker():false} activeOpacity={1}>
                        {
                            Platform.OS==='web'?
                            <input type="datetime-local" ref={webInputTimeRef} style={styles.webInput} value={webDatetime.toISOString().slice(0,16)} onChange={(e) => onChangeWeb(e.target.value)} />
                            :null
                        }
                        <MyTextInput onPress={() => !props.isInfo?showDatetimePicker():false} icon="time" hasError={props.hasError || invalidRange} editable={false} isInfo={props.isInfo} value={FormatTime(props.value)} style={[{color: color}, fontSize]}/>
                    </TouchableOpacity>
                </View>
                <MyText style={[css.xxs, styles.error, {display: invalidRange?'flex':'none'}]}>
                    Invalid datetime
                </MyText>
            </View>
        </>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
        position: 'relative'
    },
    date: {
        flex: 1,
        marginRight: 2,
        position: 'relative',
        overflow: 'hidden'
    },
    time: {
        flex: 1,
        marginLeft: 2,
        position: 'relative',
        overflow: 'hidden'
    },
    webInput: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 100,
        minWidth: '100%',
        bottom: 0,
        left: 0,
        opacity: 0,
        zIndex: 1
    },
    error: {
        position: 'absolute',
        color: 'red',
        top: '100%',
        marginTop: Platform.OS==='android'?-2:2
    }
})
export default MyDatetimePicker
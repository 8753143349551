import React from 'react';
import {
    View
} from 'react-native'
import { ScrollView } from 'react-native-gesture-handler';
import stylesheet from './Style'
import MyText from './MyText';

const CustomerSupport = (props) => {
    const css = stylesheet();
    
  return (
    <ScrollView vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
    {/* <TouchableWithoutFeedback> */}
        <View style={[css.background, css.formBackground]}>
            <View style={[{zIndex: 10}, css.form]}>
                <MyText style={[css.formTitle, css.bold]}>
                    Customer Support
                </MyText>
                <View
                style={{
                    borderBottomColor: '#f3f1f8',
                    borderBottomWidth: 2,
                }}
                />
                <View style={{paddingVertical: 20}}>
                    <MyText style={css.p}>
                        For any inquiry do not hesitate to contact with us via email or landline and a representative will come back to you the soonest.
                    </MyText>
                </View>
            </View>
        </View>
     </ScrollView>
  );
}

export default CustomerSupport
import React from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Platform,
  useWindowDimensions
} from 'react-native'
import { StatusBar } from 'expo-status-bar';
import { NavigationContainer, useIsFocused } from '@react-navigation/native';
import stylesheet from './Style'
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import WebView from 'react-native-webview';
import { MaterialIcons, Entypo, Feather, AntDesign } from '@expo/vector-icons'; 

// const RNFS = require('react-native-fs');
import * as XLSX from 'xlsx'
// import RNFetchBlob from 'rn-fetch-blob';

import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';

import MyDatePicker from './MyDatePicker';
import MyPicker from './MyPicker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useAssets } from 'expo-asset';
import * as db from './database';
import axios from 'axios';
import { useModal } from './hooks/modal';
import { RefreshControl } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

let dashboardWebHTML = '';
if(Platform.OS === 'web')
    dashboardWebHTML = require("./dashboard/dashboard.html").default;

let filteredData = [];

const tableHeader = ['ID', 'Ship Name', 'Collection\nDatetime', 'TOTAL \nQuantity (m³) \nRequested', 'TOTAL \nQuantity (m³) \nCollected', 'ANNEX I \nQuantity \nRequested', 'ANNEX I \nQuantity \nCollected', 'ANNEX II \nQuantity \nRequested', 'ANNEX II \nQuantity \nCollected', 'ANNEX IV \nQuantity \nRequested', 'ANNEX IV \nQuantity \nCollected', 'ANNEX V \nQuantity \nRequested', 'ANNEX V \nQuantity \nCollected', 'ANNEX VI \nQuantity \nRequested', 'ANNEX VI \nQuantity \nCollected'];
// const tableData = [
//   ['ID', 'Ship Name', 'Collection\nDatetime', 'TOTAL\nQuantity', 'ANNEX I\nQuantity', 'ANNEX II\nQuantity', 'ANNEX IV\nQuantity', 'ANNEX V\nQuantity', 'ANNEX VI\nQuantity'],
//   ['123456', 'Blue Star Delos',       '31-Jan-2023 11:00', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx'],
//   ['213456', 'Blue Star Paros',       '31-Jan-2023 11:00', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx'],
//   ['312456', 'Blue Star Mykonos',     '31-Jan-2023 11:00', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx'],
//   ['312456', 'Blue Star Mykonos',     '31-Jan-2023 11:00', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx'],
//   ['412356', 'Aida Blue',             '31-Jan-2023 11:00', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx'],
//   ['512356', 'Aida Blue',             '31-Jan-2023 11:00', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx'],
//   ['612345', 'Aida Blue',             '31-Jan-2023 11:00', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx'],
//   ['612345', 'Aida Blue',             '31-Jan-2023 11:00', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx', 'xxx'],
// ];

const Dashboard = (props) => {
    const css = stylesheet();
    const modal = useModal();
    
    const {height, width} = useWindowDimensions();
    const sizes = width>=768?
    [55, 90, 110, 135, 110, 110, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95]:
    [45, 80, 100, 125, 100, 100, 85, 85, 85, 85, 85, 85, 85, 85, 85, 85];

  const tableRef = React.useRef();
  const isFocused = useIsFocused();

    const [page, setPage] = React.useState('1');
    const [pageLength, setPageLength] = React.useState('5');

  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [fromKey, setFromKey] = React.useState(1000);
  const [toKey, setToKey] = React.useState(2000);

  const [type, setType] = React.useState('all');
  const [searchText, setSearchText] = React.useState('');

  const [rawData, setRawData] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [data, setData] = React.useState(tableData);
  const [sort, setSort] = React.useState({column: 2, ascending: false}); //# column does not count, 0 is the ID
  
  const [html, setHtml] = React.useState('');
  if(Platform.OS !== 'web'){
    const [index, indexLoadingError] = useAssets(
        require("./dashboard/dashboard.html")
    );

    React.useEffect(() => {
        if(index){
            FileSystem.readAsStringAsync(index[0].localUri).then((data) => {
                setHtml(data);
            });
        }
    },[index]);
  }

  const webViewRef = React.useRef();
//   const [loading, setLoading] = React.useState(Platform.OS==='web'?false:true); //on web I cannot
  const [loading, setLoading] = React.useState(true); //on web I cannot

  const [webviewHeight, setWebviewHeight] = React.useState(1);

  const [initialLoadDone, setInitialLoadDone] = React.useState(false);

  const [ships, setShips] = React.useState(null);
  const [imo, setImo] = React.useState(null);
  const [shipName, setShipName] = React.useState(null);

  const [refreshing, setRefreshing] = React.useState(true);

    const onRefresh = React.useCallback(() => {
        GetFilters();
        setRefreshing(true);
        getData();
    }, []);

    const getData = async() => {
        const requests = await db.getAllRequests();
        setRawData(requests);
    }

  // function to handle exporting
  const exportDataToExcel = async () => {
    console.log('export');
    let wb = XLSX.utils.book_new();

    // Format datetime columns
    let formattedTableData = tableData.map(row => {
        if(row[2])
            row[2] = new Date(row[2]);
        return row;
    })
    let ws = XLSX.utils.aoa_to_sheet([tableHeader, ...formattedTableData], { cellDates: true });
    ws['!cols'] = sizes.map(size => { return {wpx: size}});

    XLSX.utils.book_append_sheet(wb,ws,"Dashboard")
    const wbout = XLSX.write(wb, {type:'base64', bookType:"xlsx"});

    const filename = "Dashboard_"+FormatDatetime(new Date()).replace(":", "-").replace(" ", "_")+".xlsx";
    if(Platform.OS === 'android' || Platform.OS === 'ios'){
        const dir = FileSystem.documentDirectory+'Documents/'+props.user.id;
        const fileUri = dir+'/'+filename;
        const dirInfo = await FileSystem.getInfoAsync(dir);
        if (!dirInfo.exists) {
            console.log("Documents directory doesn't exist, creating...");
            await FileSystem.makeDirectoryAsync(dir, { intermediates: true });
        }
        await FileSystem.writeAsStringAsync(fileUri, wbout, { encoding: FileSystem.EncodingType.Base64 });
    }
    else if(Platform.OS === 'web'){
        await db.saveDocument(props.user.id, filename, wbout); 
        var a = document.createElement("a");
        a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + wbout;
        a.download = filename;
        a.click();
    }
    const body = "Exporting completed successfully with filename: "+filename+".";
    modal.confirm.show("Success", body);
  }

  const webViewScript = `
    setInterval(function() { 
      window.ReactNativeWebView.postMessage(JSON.stringify({height: document.documentElement.scrollHeight})); 
    }, 100);
    true; // note: this is required, or you'll sometimes get silent failures
  `;

//   const webWebViewScript = `
//     setInterval(function() { 
//         window.parent.postMessage('test', '*');
//     }, 100);
//     true; // note: this is required, or you'll sometimes get silent failures
//   `;

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener('focus', () => {
      GetFilters();
      setLoading(true);
      setWebviewHeight(0);
      // if(webViewRef){
        // webViewRef?.current?.reload();
      // }
    });
    const getPageLength = async() => {
        setPageLength(await AsyncStorage.getItem('pageLength')??'10');
    }
    getPageLength();
    return unsubscribe;
  }, []);

  const GetFilters = async() => {
    try{
        await db.syncFilters();
        setShips(await db.getAll('ships', 'shipName'));
    }
    catch(e){
        console.log(e);
    }
  }

  React.useEffect(() => {
    if(!loading){ //Has finished loading html
        getData();
    }
  },[loading])

  const FilterRawData = () => {
    let statusCounters = {
        "Submitted": 0,
        "Validated": 0,
        "Route Scheduled": 0,
        "Driver Arrived": 0,
        "Waste Collected": 0,
        "Waste Segregated": 0,
        "Certificate Issued": 0,
        "MaxStatus": 0
    };
    let annexCounters = {
        "annex1": {
            "requested": 0,
            "completed": 0,
        },
        "annex2": {
            "requested": 0,
            "completed": 0,
        },
        "annex4": {
            "requested": 0,
            "completed": 0,
        },
        "annex5": {
            "requested": 0,
            "completed": 0,
        },
        "annex6": {
            "requested": 0,
            "completed": 0,
        },
        "annex1non": {
            "requested": 0,
            "completed": 0,
        },
        "annex2non": {
            "requested": 0,
            "completed": 0,
        },
        "annex4non": {
            "requested": 0,
            "completed": 0,
        },
        "annex5non": {
            "requested": 0,
            "completed": 0,
        },
        "annex6non": {
            "requested": 0,
            "completed": 0,
        }
    };
    let maxHazardous = 0;
    let maxNonHazardous = 0;

    let graphData = {};
    let result = [];
    rawData.forEach(row => {
        const requestedDelivery = new Date(row.data.requestedDelivery);
        let fromDate = new Date();
        let toDate = new Date();
        if(from){
            fromDate = new Date(from);
            fromDate.setHours(0);
            fromDate.setMinutes(0);
        }
        if(to){
            toDate = new Date(to);
            toDate.setHours(23);
            toDate.setMinutes(59);
        }
        if(from && !to){
            if(requestedDelivery.getTime() < fromDate.getTime())
                return;
        }
        else if(!from && to){
            if(requestedDelivery.getTime() > toDate.getTime())
                return;
        }
        else if(from && to){
            if(requestedDelivery.getTime() < fromDate.getTime() || requestedDelivery.getTime() > toDate.getTime())
                return;
        }

        if(imo){
            if(row.data.imo != imo)
                return;
        }
        if(shipName){
            if(row.data.shipName != shipName)
                return;
        }

        if(searchText != ''){
            if(!row.id.toString().toLowerCase().includes(searchText.toLowerCase()) &&
                !row.data.shipName.toString().toLowerCase().includes(searchText.toLowerCase()) &&
                !row.data.imo.toString().toLowerCase().includes(searchText.toLowerCase())){
                    return;
            }
        }
        
        if(row.status in statusCounters){
            statusCounters[row.status]++;
            if(statusCounters[row.status] > statusCounters['MaxStatus'])
                statusCounters['MaxStatus'] = statusCounters[row.status];
        }

        let currentAnnex = {
            annex1: 0,
            annex2: 0,
            annex4: 0,
            annex5: 0,
            annex6: 0,
            annex1non: 0,
            annex2non: 0,
            annex4non: 0,
            annex5non: 0,
            annex6non: 0,
        };
        let currentAnnexCollected = {
            annex1: 0,
            annex2: 0,
            annex4: 0,
            annex5: 0,
            annex6: 0,
            annex1non: 0,
            annex2non: 0,
            annex4non: 0,
            annex5non: 0,
            annex6non: 0,
        };

        for (const annexKey in annexCounters) {
            if(annexKey in row.data){
                let counter = 0;
                for (const category in row.data[annexKey]){
                    if(row.data[annexKey][category].active){
                        if(row.data[annexKey][category]['data'] && row.data[annexKey][category]['data']['wasteForDelivery']){
                            let waste = parseFloat(row.data[annexKey][category]['data']['wasteForDelivery'].replace(',', '.'));
                            counter += waste;
                            // console.log(annexKey);
                            currentAnnex[annexKey] += waste;
                        }
                    }
                }
                annexCounters[annexKey]['requested'] += counter;
                // if(["Submitted", "Validated", "Route Scheduled", "Driver Arrived"].includes(row.status)){
                //     annexCounters[annexKey]['requested'] += counter;
                // }
                // else if(["Waste Collected", "Waste Segregated", "Certificate Issued"].includes(row.status)){
                //     annexCounters[annexKey]['completed'] += counter;
                // }

                if(annexKey.includes('non')){
                    if(annexCounters[annexKey]['requested'] > maxNonHazardous){
                        maxNonHazardous = annexCounters[annexKey]['requested'];
                    }
                }
                else{
                    if(annexCounters[annexKey]['requested'] > maxHazardous){
                        maxHazardous = annexCounters[annexKey]['requested'];
                    }
                }
            }
            if(row.waste_collection){
                if(annexKey in row.waste_collection){
                    let counter = 0;
                    for (const category in row.waste_collection[annexKey]){
                        if(row.waste_collection[annexKey][category]){
                            let waste = parseFloat(row.waste_collection[annexKey][category].replace(',', '.'));
                            counter += waste;
                            currentAnnexCollected[annexKey] += waste;
                        }
                    }
                    annexCounters[annexKey]['completed'] += counter;

                    if(annexKey.includes('non')){
                        if(annexCounters[annexKey]['completed'] > maxNonHazardous){
                            maxNonHazardous = annexCounters[annexKey]['completed'];
                        }
                    }
                    else{
                        if(annexCounters[annexKey]['completed'] > maxHazardous){
                            maxHazardous = annexCounters[annexKey]['completed'];
                        }
                    }
                }
            }
        }

        let collectionDatetime = '';
        if(row.waste_collection?.collectedOn){
            collectionDatetime = row.waste_collection.collectedOn;
        }

        result.push([
            row.id,
            row.data.shipName,
            collectionDatetime, 
            
            (currentAnnex['annex1']+currentAnnex['annex1non']+currentAnnex['annex2']+currentAnnex['annex2non']+currentAnnex['annex4']+currentAnnex['annex4non']+currentAnnex['annex5']+currentAnnex['annex5non']+currentAnnex['annex6']+currentAnnex['annex6non'])
            ,(currentAnnexCollected['annex1']+currentAnnexCollected['annex1non']+currentAnnexCollected['annex2']+currentAnnexCollected['annex2non']+currentAnnexCollected['annex4']+currentAnnexCollected['annex4non']+currentAnnexCollected['annex5']+currentAnnexCollected['annex5non']+currentAnnexCollected['annex6']+currentAnnexCollected['annex6non']),
            
            (currentAnnex['annex1']+currentAnnex['annex1non'])
            ,(currentAnnexCollected['annex1']+currentAnnexCollected['annex1non']),

            (currentAnnex['annex2']+currentAnnex['annex2non'])
            ,(currentAnnexCollected['annex2']+currentAnnexCollected['annex2non']),

            (currentAnnex['annex4']+currentAnnex['annex4non'])
            ,(currentAnnexCollected['annex4']+currentAnnexCollected['annex4non']),

            (currentAnnex['annex5']+currentAnnex['annex5non'])
            ,(currentAnnexCollected['annex5']+currentAnnexCollected['annex5non']),

            (currentAnnex['annex6']+currentAnnex['annex6non'])
            ,(currentAnnexCollected['annex6']+currentAnnexCollected['annex6non'])

            // 'R : '+(currentAnnex['annex1']+currentAnnex['annex1non']+currentAnnex['annex2']+currentAnnex['annex2non']+currentAnnex['annex4']+currentAnnex['annex4non']+currentAnnex['annex5']+currentAnnex['annex5non']+currentAnnex['annex6']+currentAnnex['annex6non'])
            // +'\n'+
            // 'C : '+(currentAnnexCollected['annex1']+currentAnnexCollected['annex1non']+currentAnnexCollected['annex2']+currentAnnexCollected['annex2non']+currentAnnexCollected['annex4']+currentAnnexCollected['annex4non']+currentAnnexCollected['annex5']+currentAnnexCollected['annex5non']+currentAnnexCollected['annex6']+currentAnnexCollected['annex6non']),
            
            // (currentAnnex['annex1']+currentAnnex['annex1non'])
            // +'\n'+
            // (currentAnnexCollected['annex1']+currentAnnexCollected['annex1non']),

            // (currentAnnex['annex2']+currentAnnex['annex2non'])
            // +'\n'+
            // (currentAnnexCollected['annex2']+currentAnnexCollected['annex2non']),

            // (currentAnnex['annex4']+currentAnnex['annex4non'])
            // +'\n'+
            // (currentAnnexCollected['annex4']+currentAnnexCollected['annex4non']),

            // (currentAnnex['annex5']+currentAnnex['annex5non'])
            // +'\n'+
            // (currentAnnexCollected['annex5']+currentAnnexCollected['annex5non']),

            // (currentAnnex['annex6']+currentAnnex['annex6non'])
            // +'\n'+
            // (currentAnnexCollected['annex6']+currentAnnexCollected['annex6non'])
        ]);
    })
    graphData = {
        ...statusCounters,
        ...annexCounters,
        maxHazardous,
        maxNonHazardous,
        TotalHazardousRequested: annexCounters['annex1']['requested']+annexCounters['annex2']['requested']+annexCounters['annex4']['requested']+annexCounters['annex5']['requested']+annexCounters['annex6']['requested'],
        TotalHazardousCompleted: annexCounters['annex1']['completed']+annexCounters['annex2']['completed']+annexCounters['annex4']['completed']+annexCounters['annex5']['completed']+annexCounters['annex6']['completed'],
        TotalNonHazardousRequested: annexCounters['annex1non']['requested']+annexCounters['annex2non']['requested']+annexCounters['annex4non']['requested']+annexCounters['annex5non']['requested']+annexCounters['annex6non']['requested'],
        TotalNonHazardousCompleted: annexCounters['annex1non']['completed']+annexCounters['annex2non']['completed']+annexCounters['annex4non']['completed']+annexCounters['annex5non']['completed']+annexCounters['annex6non']['completed']
    };
    if(Platform.OS==='web'){
        console.log(webViewRef?.current?.contentWindow?.postMessage);
        webViewRef?.current?.contentWindow?.postMessage(JSON.stringify(graphData), '*');
    }
    else{
        webViewRef?.current?.injectJavaScript(`SetData('${JSON.stringify(graphData)}')`);
    }
    setTimeout(() => {
        setTableData(result);
        setRefreshing(false);
        setInitialLoadDone(true);
    },100);
  }
  React.useEffect(() => {
    if(rawData){
        FilterRawData();
    }
  },[rawData])

  React.useEffect(() => {
    if(tableData)
        FilterData();
  },[tableData])

  const FormatDatetime = (dt) => {
    if(!dt)
        return '';
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const padLeft = (num) => {
        if(num < 10)
            return '0'+num;
        return num;
    }
    const date = new Date(dt);
    return padLeft(date.getDate()) + '-' + months[date.getMonth()] + '-' + date.getFullYear() + ' ' + padLeft(date.getHours()) + ':' + padLeft(date.getMinutes());
  }

  const Header = (headerProps) => {
    let text = headerProps?.text?.toString()??'';
    return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <MyText style={[styles.headerText, css.bold, css.s]}>{text}</MyText>
        {
            sort.column+1 == headerProps.cellIndex &&
            <View style={{position: 'absolute', right: -10}}>
                <MaterialIcons style={{top: 0}} name={"keyboard-arrow-up"} size={18} color={sort.ascending?'white':'#461b6f'} />
                <MaterialIcons style={{marginTop: -10}} name={"keyboard-arrow-down"} size={18} color={!sort.ascending?'white':'#461b6f'} />
            </View>
        }
        {
            sort.column+1 != headerProps.cellIndex && headerProps.cellIndex != 0 && (
            <View style={{position: 'absolute', right: -10}}>
                <MaterialIcons style={{top: 0}} name={"keyboard-arrow-up"} size={18} color="#d1d1d1" />
                <MaterialIcons style={{marginTop: -10}} name={"keyboard-arrow-down"} size={18} color="#d1d1d1" />
            </View>
            )
        }
        </View>;
  }
  const Bullet = (bulletProps) => {
    const orange = '#efa270';
    const yellow = '#fed354';
    const blue = '#1aa0eb';
    const green = '#23a43f';
    const red = '#e32112';
    let color = '';
    let text = bulletProps?.text?.toString()??'';

    if(!text){
        return <></>;
    }
    if(bulletProps.cellIndex == 3){
        text = <><MyText style={{fontSize: 0.001, color: 'transparent'}}>{text}</MyText>{FormatDatetime(text)}</>;
    }
    let textAlign = 'left';
    if(bulletProps.cellIndex == 0)
        textAlign = 'right';
    return <MyText style={[css.s, {textAlign: textAlign}]}>{text}</MyText>;
  }

  const renderHeader = (cellIndex, data) => {
    const totalTableWidth = sizes.reduce((partialSum, a) => partialSum + a, 0);
    let cellWidth = sizes[cellIndex];
    if(totalTableWidth < width-230-40){ //table fits without scrolling
        cellWidth = sizes[cellIndex]*((width-230-40)/totalTableWidth);
    }
    return (
        <TouchableOpacity onPress={() => ChangeSort(cellIndex-1)}>
            <View key={cellIndex} style={[css.s, styles.cell, styles.header, {width: cellWidth}]}>
                <Header cellIndex={cellIndex} text={data}/>
            </View>
        </TouchableOpacity>
    );
  }
  const render = (rowIndex, cellIndex, data, id) => {
    const totalTableWidth = sizes.reduce((partialSum, a) => partialSum + a, 0);
    let cellWidth = sizes[cellIndex];
    if(totalTableWidth < width-230-40){ //table fits without scrolling
        cellWidth = sizes[cellIndex]*((width-230-40)/totalTableWidth);
    }
    return <View key={cellIndex} style={[rowIndex%2?styles.even:styles.odd, css.x, styles.cell, {width: cellWidth}]}><Bullet rowIndex={rowIndex} cellIndex={cellIndex} id={id} text={data}/></View>;
  }

  const FilterData = () => {
    setPage('1');
    filteredData = [];
    tableData.forEach((row, rowIndex) => {
        // let addedRow = false;
        filteredData.push(row);
        row.forEach((cell, cellIndex) => {
            // if(addedRow)
            //     return;
            // if(cell.toString().toLowerCase().includes(searchText.toLowerCase())){
            //     let hasFilter = false;
            //     if(type == 'all'){
            //         hasFilter = true;
            //     }
            //     // if(row[7].startsWith('Submitted') && type == 'new'){
            //     //     hasFilter = true;
            //     // }
            //     // else if(row[7].startsWith('Validated') && type == 'new'){
            //     //     hasFilter = true;
            //     // }
            //     // else if(row[7].startsWith('Route Scheduled') && type == 'scheduled'){
            //     //     hasFilter = true;
            //     // }
            //     // else if(row[7].startsWith('Driver Arrived') && type == 'scheduled'){
            //     //     hasFilter = true;
            //     // }
            //     // else if(row[7].startsWith('Waste Collected') && type == 'collected'){
            //     //     hasFilter = true;
            //     // }
            //     // else if(row[7].startsWith('Waste Segregated') && type == 'segregated'){
            //     //     hasFilter = true;
            //     // }
            //     // else if(row[7].startsWith('Certificate') && type == 'segregated'){
            //     //     hasFilter = true;
            //     // }
            //     if(hasFilter){
            //         filteredData.push(row);
            //         addedRow = true;
            //     }
            //     return;
            // }
        })
    })
    SortData();
  }

  const SortData = () => {
      filteredData.sort((a, b) => {
          if (a[sort.column].toString() < b[sort.column].toString()) return sort.ascending ? -1 : 1;
          if (a[sort.column].toString() > b[sort.column].toString()) return sort.ascending ? 1 : -1;
          return 0;
      });
      let numbered = [['#', ...tableHeader]];
      filteredData.forEach((row, idx) => {
          numbered.push([(idx+1).toString(), ...row]);//.unshift(idx.toString()));
      })
      setData(numbered);
  }

    const Searched = (text) => {
        setSearchText(text);
    }

    const ChangeSort = (sortColumn) => {
        if(sortColumn > 0){
            setSort(prev => (
                {
                    column: sortColumn,
                    ascending: prev.column==sortColumn?(!prev.ascending):true
                }
            ));
        }
    }

    React.useEffect(() => {
        if(initialLoadDone)
            FilterRawData();
    }, [type, searchText, from, to, imo, shipName]);

    React.useEffect(() => {
        SortData();
    }, [sort]);

  return (
    <KeyboardAwareScrollView overScrollMode='never' vertical refreshControl={Platform.OS==='web'?undefined:<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>} nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} removeClippedSubviews={true} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
      <View style={[css.background, {paddingHorizontal: 0, paddingTop: 20}]}>
          <MyText style={[css.title, css.bold, {paddingLeft: 20}]}>
              Dashboard
          </MyText>
          {
            loading && 
              <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                  <ActivityIndicator size="large" color="#461b6f" />
              </View>
          }
          {
            (isFocused && !loading) &&
            <View style={{paddingHorizontal: 20}}>
                <View style={{marginTop: 15}}>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{marginRight: 7.5, flexBasis: 127.5, flexGrow: 0, flexShrink: 0}}>
                            <MyDatePicker
                                onChange={setFrom}
                                value={from}
                                placeholder={'From\nDelivery Date'}
                                key={fromKey}
                            />
                        </View>
                        <View style={{flex: 1, flexBasis: 127.5, flexGrow: 0, flexShrink: 0}}>
                            <MyDatePicker
                                onChange={setTo}
                                value={to}
                                placeholder={'To\nDelivery Date'}
                                key={toKey}
                            />
                        </View>
                    </View>
                </View>
                <View style={{marginTop: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <View style={css.fullFilterButton}>
                        <MyPicker
                            style={css.formRowInput}
                            items={ships?.map(x => parseInt(x.imo)).sort((a, b) => (a - b)).map(x => x.toString())}
                            onValueChange={(item, itemIndex) => {
                                    ships.forEach(x => {
                                        if(x.imo == item.value){
                                            setShipName(x.shipName.toString());
                                        }
                                    })
                                    setImo(item.value)
                                }
                            }
                            value={imo}
                            showSearch={true}
                            placeholder={'IMO'}
                            fancyPlaceholder={true}
                        />
                    </View>
                    <View style={css.fullFilterButton}>
                        <MyPicker
                            style={css.formRowInput}
                            items={ships?.map(x => x.shipName.toString())}
                            onValueChange={(item, itemIndex) => {
                                    ships.forEach(x => {
                                        if(x.shipName == item.value){
                                            setImo(x.imo.toString());
                                        }
                                    })
                                    setShipName(item.value)
                                }
                            }
                            value={shipName}
                            showSearch={true}
                            placeholder={'Ship Name'}
                            fancyPlaceholder={true}
                        />
                    </View>
                </View>
                <View>
                    <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: 5}}>
                        {/* <View style={[css.filterButton, {justifyContent: 'center', alignItems: 'center'}]}> */}
                            <TouchableOpacity onPress={() => {setFrom(null); setTo(null); setImo(null); setShipName(null); setType('all'); setFromKey(prev => prev+1); setToKey(prev => prev+1);}}>
                                <MyText style={{color: '#461b6f', textDecorationLine: 'underline'}}>
                                    Clear Filters
                                </MyText>
                            </TouchableOpacity>
                        {/* </View> */}
                    </View>
                </View>
            </View>
          }
          {
            isFocused?
              <View style={{marginTop: 15}}>
                {
                    Platform.OS === 'web'?
                    <iframe ref={webViewRef} srcdoc={dashboardWebHTML} onLoad={(e) => {setLoading(false);}} style={{height: loading?0:(width>=768?800:1350), border: 0}}/>
                    :
                    <WebView 
                        ref={webViewRef}
                        androidHardwareAccelerationDisabled
                        originWhitelist={['*']}
                        source={{html: html, baseUrl: ''}}
                        // source={{ uri: 'https://footprint.mdataws.com/dashboard.php' }} 
                        style={{ flex: 1, height: webviewHeight }}
                        scalesPageToFit={true}
                        onMessage={event => {
                            if(event.nativeEvent.data){
                                const msg = JSON.parse(event.nativeEvent.data);
                                if(msg.height){
                                    if(height != msg.height && msg.height > 0){
                                        setWebviewHeight(parseInt(msg.height))
                                    }
                                    if(msg.height > 0 && loading){
                                        setLoading(false);
                                    }
                                }
                            }
                        }}
                        javaScriptEnabled={true}
                        // injectedJavaScript ={webViewScript}
                        domStorageEnabled={true}
                        automaticallyAdjustContentInsets={false}
                        // startInLoadingState
                        onLoadStart={() => setLoading(true)}
                        scrollEnabled={false}
                        bounces={false}
                        showsVerticalScrollIndicator={false}
                        // onLoadEnd={() => setLoading(false)}
                    />
                }
              </View>
              :null
            }
            {
              (isFocused && !loading)?
              <>
                {
                    width<576?
                    <View style={{display: 'flex', flexDirection: 'row', marginRight: 20, marginTop: 0, marginBottom: 10, justifyContent: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <MyText style={{paddingRight: 10}}>
                                Show
                            </MyText>
                            <View style={{width: 50}}>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={['5','10','25','50']}
                                    onValueChange={(item, itemIndex) => {
                                            setPageLength(item.value);
                                            AsyncStorage.setItem('pageLength', item.value);
                                            const maxPage = Math.ceil(data.length/parseInt(item.value));
                                            if(page > maxPage){
                                                setPage(maxPage.toString());
                                            }
                                        }
                                    }
                                    value={pageLength}
                                    placeholder={'Show'}
                                    // fancyPlaceholder={true}
                                />
                            </View>
                            <MyText style={{paddingLeft: 10}}>
                                Requests
                            </MyText>
                        </View>
                    </View>
                    :<></>
                }
                <View style={{display: 'flex', flexDirection: 'row', marginRight: 20, marginTop: 0, marginBottom: 10, marginLeft: 20, justifyContent: 'flex-start'}}>
                    {/* <MyText style={[{lineHeight: 33, marginRight: 10}, css.bold]}>Search</MyText> */}
                    <MyTextInput icon='search' onChangeTextImmediate={Searched} onChangeText={() => false} value={searchText} placeholder='Search...' style={{flexGrow: 0, flexShrink: 0, flexBasis: 232, marginRight: 38}}/>
                    {
                        width>=576?
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <MyText style={{paddingRight: 10}}>
                                Show
                            </MyText>
                            <View style={{width: 50}}>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={['5','10','25','50']}
                                    onValueChange={(item, itemIndex) => {
                                            setPageLength(item.value);
                                            AsyncStorage.setItem('pageLength', item.value);
                                            const maxPage = Math.ceil(data.length/parseInt(item.value));
                                            if(page > maxPage){
                                                setPage(maxPage.toString());
                                            }
                                        }
                                    }
                                    value={pageLength}
                                    placeholder={'Show'}
                                    // fancyPlaceholder={true}
                                />
                            </View>
                            <MyText style={{paddingLeft: 10}}>
                                Requests
                            </MyText>
                        </View>
                        :<></>
                    }
                    <TouchableOpacity onPress={() => exportDataToExcel()} style={{marginLeft: 'auto'}}>
                        <View style={{flexDirection: 'column', alignItems: 'center'}}>
                            <Feather name="download" size={18} color="#461b6f" />
                            <MyText style={[css.xs, {color: '#461b6f'}]}>
                                Export
                            </MyText>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={{marginBottom: 20, marginLeft: 20}}>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{flexShrink: 0, flexGrow: 0, borderTopLeftRadius: 8, borderBottomLeftRadius: 8, overflow: 'hidden'}}>
                        {
                                data.length>0?
                                <View key={0} style={{display: 'flex', flexDirection: 'row'}}>
                                    {
                                        data[0].map((cellData, cellIndex) => {
                                            if(cellIndex >= 2)
                                                return <></>;
                                            return renderHeader(cellIndex, cellData);
                                        })
                                    }
                                </View>
                                :null
                            }
                            {
                                data.slice(1).map((rowData, rowIndex) => {
                                    if(rowIndex < (parseInt(page)-1)*parseInt(pageLength) || rowIndex >= ((parseInt(page)-1)*parseInt(pageLength) + parseInt(pageLength)))
                                        return <></>
                                    return (
                                        <View key={rowIndex} style={{display: 'flex', flexDirection: 'row'}}>
                                            {
                                                rowData.map((cellData, cellIndex) => {
                                                    if(cellIndex >= 2)
                                                        return <></>;
                                                    return render(rowIndex, cellIndex, cellData, rowData[1]);
                                                })
                                            }
                                        </View>
                                    )
                                })
                            }
                        </View>
                        <ScrollView horizontal ref={tableRef}>
                            <View style={{marginRight: 20, borderTopRightRadius: 8, borderBottomRightRadius: 8, overflow: 'hidden'}}>
                            {
                                    data.length>0?
                                    <View key={0} style={{display: 'flex', flexDirection: 'row'}}>
                                        {
                                            data[0].map((cellData, cellIndex) => {
                                                if(cellIndex < 2)
                                                    return <></>;
                                                return renderHeader(cellIndex, cellData);
                                            })
                                        }
                                    </View>
                                    :null
                                }
                                {
                                    data.slice(1).map((rowData, rowIndex) => {
                                        if(rowIndex < (parseInt(page)-1)*parseInt(pageLength) || rowIndex >= ((parseInt(page)-1)*parseInt(pageLength) + parseInt(pageLength)))
                                            return <></>
                                        return (
                                            <View key={rowIndex} style={{display: 'flex', flexDirection: 'row'}}>
                                                {
                                                    rowData.map((cellData, cellIndex) => {
                                                        if(cellIndex < 2)
                                                            return <></>;
                                                        return render(rowIndex, cellIndex, cellData, rowData[1]);
                                                    })
                                                }
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </ScrollView>
                    </View>
                    <View style={[styles.odd, css.s, styles.cell, {textAlign: 'center', display: data.length==1?'flex':'none'}]}>
                        <MyText style={{textAlign: 'center'}}>
                            {initialLoadDone?'No requests found':'Loading...'}
                        </MyText>
                    </View>
                    {
                        data?.length-1 > 0 ?
                        <View style={{display: 'flex', flexDirection: 'row', marginRight: 20, marginTop: 10, marginBottom: 10, justifyContent: 'space-between'}}>
                            <MyText>
                                Showing <MyText style={[css.bold, {color: '#461b6f'}]}>{(page-1)*pageLength+1}</MyText> to <MyText style={[css.bold, {color: '#461b6f'}]}>{Math.min((parseInt(page)-1)*parseInt(pageLength) + parseInt(pageLength), data.length-1)}</MyText> of <MyText style={[css.bold, {color: '#461b6f'}]}>{data?.length-1}</MyText> requests
                            </MyText>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <TouchableOpacity onPress={() => page-1>=1?setPage((parseInt(page)-1).toString()):false}>
                                    <AntDesign name="left" size={18} color="#461b6f" />
                                </TouchableOpacity>
                                <View style={[{width: 50, marginVertical: 0, marginHorizontal: 10}]}>
                                    <MyPicker
                                        style={css.formRowInput}
                                        items={[...Array(Math.ceil(data?.length/pageLength))].map((_, i) => (i+1).toString())}
                                        onValueChange={(item, itemIndex) => {
                                                setPage(item.value);
                                            }
                                        }
                                        value={page}
                                        placeholder={'Page'}
                                        fancyPlaceholder={true}
                                    />
                                </View>
                                <TouchableOpacity onPress={() => parseInt(page)+1<=Math.ceil(data?.length/parseInt(pageLength))?setPage((parseInt(page)+1).toString()):false}>
                                    <AntDesign name="right" size={18} color="#461b6f" />
                                </TouchableOpacity>
                            </View>
                        </View>
                        :null
                    }
                </View>
            </>:null
            }
            
      </View>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  filterButton: {
    marginRight: 10,
    marginBottom: 10,
    flexBasis: 125,
    flexGrow: 0,
    flexShrink: 0
  },
  text: {
      padding: 10,
  },
  row: {
      flexDirection: 'row',
  },
  header: {
      backgroundColor: '#461b6f',
  },
  headerText: {
      color: 'white'
  },
  cell: {
      paddingHorizontal: 10,
      height: Platform.OS==='web'?60:50,
      borderWidth: 1,
      borderColor: 'white',
      display: 'flex',
      justifyContent: 'center'
  },
  even: {
      backgroundColor: '#F6F4F9'
  },
  odd: {
      backgroundColor: '#ececec'
  }
});

export default Dashboard

import React from 'react'
import { StyleSheet, View, TouchableOpacity, Keyboard } from 'react-native';
import MyText from '../MyText';
import stylesheet from '../Style'
import RouteUpdateForm from './RouteUpdateForm';

import axios from 'axios';
import { useModal } from '../hooks/modal';

const RouteUpdate = (props) => {
    const css = stylesheet();
    const modal = useModal();
    
    console.log(props.value);
    console.log(props.requestedDelivery);
    const [submit, setSubmit] = React.useState(false);

    const HasDefaultValue = () => {
        if(!props.value || props.value.length == 0){
            return false;
        }
        let found = false;
        props.value.forEach(row => {
            if(row.active){
                if(row.collectionMean || row.companyRepresentative || row.datetime || row.collectionMean || row.vehicleId || row.purpose || row.comments){
                    found = true;
                }
            }
        });
        return found;
    }
    const [data, setData] = React.useState(HasDefaultValue()?props.value:(props.isInfo?[]:[{}]));//props?.value??[{}]);

    React.useEffect(() => {
        onChange();
    }, [data]);

    const onChange = () => {
        props.onChange(data);
    }

    const Submit = () => {
        Keyboard.dismiss();
        setSubmit(true);

        let formData = [];
        data.forEach(row => {
            if(row.active){
                formData.push(row);
            }
        })
        let hasError = false;
        formData.forEach(row => {
            if(!row.companyRepresentative || !row.datetime || !row.collectionMean || !row.vehicleId || !row.purpose){
                hasError = true;
            }
        })
        if(!hasError){
            if(props.id){
                let data = {
                    id: props.id,
                    data: formData
                };
              axios.post('/api/editRequestRouteUpdate.php', data)
              .then((res) => {
                  console.log(res);
                  modal.confirm.show("Success", "Route scheduled successfully, for Request "+props.id+".");
                    setTimeout(() => {
                        props.navigation.push('Manage Requests');
                    },10)
              })
            }
            else{
                modal.confirm.show("Warning", "Something went wrong.");
            }
        }
        else{
            modal.confirm.show("Warning", "Please fill in all mandatory fields in red.");
        }
    }
  

    return (
        <>
        {/* Route Update */}
        <View style={[{zIndex: 10}, css.form]}>
            <MyText style={[css.formTitle, css.bold]}>
                {props.isInfo?'Scheduled Route':'Route Update'}
            </MyText>
            <View
            style={{
                borderBottomColor: '#f3f1f8',
                borderBottomWidth: 2,
            }}
            />
            <View style={styles.reqContainer}>
                <View>
                    <View style={[{zIndex: 9}, css.form]}>
                        <View
                        style={{
                            borderBottomColor: '#f3f1f8',
                            borderBottomWidth: 2,
                        }}
                        />
                        <View style={css.columnFormContainer}>
                            {
                                data.map((mean, i) => {
                                    if(!mean.datetime){
                                        mean.datetime = props.requestedDelivery;
                                    }
                                    return <RouteUpdateForm onChange={(e) => {
                                        const newAr = data.slice(); //copy array
                                        newAr[i] = e;
                                        let hasActive = false;
                                        newAr.forEach(re => {
                                            if(re.active){
                                                hasActive = true;
                                            }
                                        })
                                        if(!hasActive){ //If we deleted everything, add a new one
                                            newAr.push({});
                                        }
                                        setData(newAr);
                                    }} value={mean} submit={submit} collectionMeans={props.collectionMeans} companyRepresentatives={props.companyRepresentatives} purposes={props.purposes} key={i} id={i} isInfo={props.isInfo}/>
                                })
                            }
                            {
                                !props.isInfo &&
                                <TouchableOpacity onPress={() => setData(current => [...current, {}])} style={{alignItems: 'flex-start', marginBottom: 0}}>
                                    <MyText style={[{textDecorationLine: 'underline', color: '#461b6f'}, css.bold]}>
                                        + Add more
                                    </MyText>
                                </TouchableOpacity>
                            }
                        </View>
                    </View>
                </View>
            </View>
        </View>
        {
            !props.isInfo?
            <TouchableOpacity onPress={() => Submit()} style={{alignItems: 'center', marginBottom: 20}}>
                <MyText style={[css.button, css.bold]}>
                    Submit
                </MyText>
            </TouchableOpacity>
            :null
        }
    </>
    )
}
const styles = StyleSheet.create({
    container: {
        paddingVertical: 10,
        paddingHorizontal: 14,
        paddingBottom: 0,
        borderColor: '#532b75',
        borderWidth: 0.5,
        borderRadius: 20,
        marginBottom: 15,
        overflow: 'hidden',
    },
    reqContainer: {
        marginBottom: 0,
        backgroundColor: 'white'
    },
    reqTitle: {
        backgroundColor: '#e9e3f0',
        color: '#57307e',
        textAlign: 'center',
        width: '100%',
        padding: 10
    },
})

export default RouteUpdate

import React from 'react';
import {
  SafeAreaView,
  View,
  StyleSheet,
  Image,
  Text,
  Linking,
  TouchableOpacity,
  Platform,
  useWindowDimensions
} from 'react-native';

import Logo from './assets/icons/logo.svg';
import MyText from './MyText';
import stylesheet from './Style'
import axios from 'axios';

import MyRequests from './assets/icons/MyRequests.svg';
import NewRequest from './assets/icons/NewRequest.svg';
import Dashboard from './assets/icons/Dashboard.svg';
import Documents from './assets/icons/Documents.svg';
import MySVG from './assets/MySVG';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { AntDesign, Entypo, FontAwesome, FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { StackActions } from '@react-navigation/native';

import Constants from "expo-constants"

export default function WebFooter(props) {
    const css = stylesheet();

    const LinkTo = (to) => {
        if(props.navigation?.current && to){
            if(Platform.OS==='web'){
                document.documentElement.scrollTop = 0;
            }
            props.navigation.current.dispatch(StackActions.push(to, null));
        }
    }
    const Link = (text, to) => {
        if(to && to.startsWith('http')){
            return <TouchableOpacity style={styles.link} onPress={() => Linking.openURL(url)}>
                <MyText style={[css.s, styles.linkText]}>
                    {text}
                </MyText>
            </TouchableOpacity>
        }
        return <TouchableOpacity style={styles.link} onPress={() => LinkTo(to)}>
            <MyText style={[css.s, styles.linkText]}>
                {text}
            </MyText>
        </TouchableOpacity>
    }
    
    return (
    <View style={{backgroundColor: '#461b6f', padding: 20, flexWrap: 'wrap', flexDirection: 'row'}}>
        <View style={[styles.column, {alignItems: 'center'}]}>
            <MySVG source={Logo} style={styles.logo} resizeMode={'contain'} />
            <MyText style={[css.xl, styles.logoText]}>
                FOOTPRINT
            </MyText>
        </View>
        <View style={styles.column}>
            
        </View>
        <View style={styles.column}>
            <MyText style={[css.sm, styles.headerText]}>
                COMPANY
            </MyText>
            {Link('About Us', 'About Us')}
            {Link('Career', 'Career')}
            {Link('Press', 'Press')}
            {Link('Terms of Use', 'Terms')}
            {Link('Privacy Policy', 'Privacy')}            
        </View>
        <View style={styles.column}>
            <MyText style={[css.sm, styles.headerText]}>
                RESOURCES
            </MyText>
            {Link('Customer Support', 'Customer Support')}
        </View>
        <View style={styles.column}>
            <MyText style={[css.sm, styles.headerText]}>
                GET THE APP
            </MyText>
            {Link('iOS', 'https://apps.apple.com/us/app/application-footprint/id6446500693')}
            {Link('Android', 'https://play.google.com/store/apps/details?id=gr.mdata.footprint')}
        </View>
        <View style={styles.column}>
            <MyText style={[css.sm, styles.headerText]}>
                CONNECT
            </MyText>
            {Link('GREECE +30.210.9962680', null)}
            {Link('team@footprint.com', null)}
        </View>
        <View style={[styles.column, {flexDirection: 'column', justifyContent: 'space-between'}]}>
            <View style={{flexDirection: 'row', flex: 1}}>
                <AntDesign name="instagram" size={24} color="white" style={{marginRight: 15}} />
                <Entypo name="linkedin" size={21} color="white" style={{marginTop: 1}}/>
            </View>
            <MyText style={[styles.version, css.xs]}>Version {Constants.expoConfig.version} ({Constants.expoConfig.code})</MyText>
        </View>
    </View>
    )
}

const styles = StyleSheet.create({
    column: {
        flex: 1,
        minWidth: 150
    },
    link: {
        marginBottom: 10
    },
    headerText: {
        color: 'white',
        marginBottom: 20,
        fontFamily: 'Futura Heavy'
    },
    linkText: {
        fontFamily: 'Futura Medium',
        color: 'white'
    },
    logo: {
        width: 70,
        height: 70,
        backgroundSize: 'contain',
        cursor: 'pointer'
    },
    logoText: {
        fontFamily: 'Futura Heavy',
        color: 'white',
        marginTop: 15
    },
    version: {
        color: 'white',
        textAlign: 'right',
    }
});

import DateTimePicker, { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import React, { FunctionComponent, useRef } from 'react'
import { StyleSheet, Text, View, TextInput, Platform, Pressable } from 'react-native';
import { EvilIcons, Entypo } from '@expo/vector-icons'; 
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import stylesheet from './Style'
import { TouchableOpacity } from 'react-native-gesture-handler';
import Popover from 'react-native-popover-view';

const MyDatePicker = (props) => {
    const css = stylesheet();
    
    const webInputRef = useRef(null);

    const [date, setDate] = React.useState(new Date());
    const [hasDate, setHasDate] = React.useState(false);
    const [mode, setMode] = React.useState('date');
    const [show, setShow] = React.useState(false);

    const [dateString, setDateString] = React.useState(props.placeholder??'Date');

    const padLeft = (num) => {
        if(num < 10)
            return '0'+num;
        return num;
    }

    const onChange = (event, selectedDate, mode) => {
        if(event.type == 'set'){ //dismissed = canceled, set = modified
            const currentDate = selectedDate;
            setShow(false);
            setDate(currentDate);
            setHasDate(true);
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            setDateString(padLeft(currentDate.getDate() + ' ' + months[currentDate.getMonth()] + ', ' + currentDate.getFullYear().toString().slice(2)));
            props.onChange(currentDate);
        }
    };

    const onChangeWeb = (selectedDate) => {
        console.log(selectedDate);
        if(selectedDate){
            const currentDate = selectedDate;
            setDate(currentDate);
            setHasDate(true);
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            setDateString(padLeft(currentDate.getDate() + ' ' + months[currentDate.getMonth()] + ', ' + currentDate.getFullYear().toString().slice(2)));
            props.onChange(currentDate);
        }
        else{
            setDate(null);
            setHasDate(false);
            props.onChange(null);
            setDateString(props.placeholder??'Date');
        }
    }

    const showMode = (currentMode) => {
        if (Platform.OS === 'android') {
            setShow(false);
            DateTimePickerAndroid.open({
                value: date,
                onChange,
                mode: currentMode,
                is24Hour: true,
            });
        }
        else{
            setShow(true);
        }
        setMode(currentMode);
    };

    const showDatepicker = () => {
        if(Platform.OS === 'web'){
            webInputRef.current.showPicker();
        }
        else{
            showMode('date');
        }
    };

    // React.useEffect(() => {
    //     if(webInputRef){
    //         webInputRef.current.onChange = () => {
    //             alert(1);
    //         }
    //     }
    // },[webInputRef])

    let webDate = date?(new Date(date)):(new Date());
    webDate.setMinutes(webDate.getMinutes() - webDate.getTimezoneOffset());

    let color = hasDate?'#474747':'#b9bab7';
    if(props.isInfo){
        color = '#461b6f';
    }

    return (
        <>
            <Popover isVisible={show} onRequestClose={() => setShow(false)}>
                <DateTimePicker
                    style={{padding: 15}}
                    testID="dateTimePicker"
                    value={date}
                    mode={mode}
                    is24Hour={true}
                    display={'inline'}
                    onChange={onChange}
                    accentColor='#461b6f'
                />
            </Popover>
            <TouchableOpacity onPress={showDatepicker} activeOpacity={1}>
                <View style={styles.container}>
                    <View style={styles.date}>
                        {
                            Platform.OS==='web'?
                            <input type="date" ref={webInputRef} style={styles.webInput} value={webDate.toISOString().slice(0,10)} onChange={(e) => onChangeWeb(e.target.valueAsDate)} />
                            :null
                        }
                        <MyTextInput icon="date" onPress={showDatepicker} editable={false} value={''} style={[{color: '#b9bab7'}, props.small?css.xs:css.s]}/>
                        {
                            dateString.includes('\n')?
                            <MyText onPress={showDatepicker} style={[props.isInfo?css.sm:css.s, styles.multiLinePlaceholder, {color: color, opacity: (props.isInfo||hasDate)?1:0.5}]}>
                                {dateString}
                            </MyText>
                            :
                            <MyText onPress={showDatepicker} style={[props.isInfo?css.sm:css.s, styles.placeholder, {color: color, opacity: (props.isInfo||hasDate)?1:0.5}]}>
                                {dateString}
                            </MyText>
                        }
                    </View>
                </View>
            </TouchableOpacity>
        </>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        display: 'flex',
        width: '100%'
    },
    date: {
        flex: 1,
        marginRight: 2,
        position: 'relative',
        overflow: 'hidden'
    },
    placeholder: {
        position: 'absolute',
        left: 33,
        top: Platform.OS==='web'?'50%':(Platform.OS==='android'?8:12),
        transform: [{translateY: Platform.OS==='web'?'-50%':0}],
        color: '#474747',
        opacity: 0.5
    },
    multiLinePlaceholder: {
        position: 'absolute',
        left: 33,
        top: Platform.OS==='web'?'50%':(Platform.OS==='android'?2:6),
        transform: [{translateY: Platform.OS==='web'?'-50%':0}],
        color: '#474747',
        opacity: 0.5
    },
    webInput: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 100,
        minWidth: '100%',
        bottom: 0,
        left: 0,
        opacity: 0,
        zIndex: 1
    }
})
export default MyDatePicker
// Custom Navigation Drawer / Sidebar with Image and Icon in Menu Options
// https://aboutreact.com/custom-navigation-drawer-sidebar-with-image-and-icon-in-menu-options/

import React from 'react';
import {
  SafeAreaView,
  View,
  StyleSheet,
  Image,
  Text,
  Linking,
  TouchableOpacity,
  Platform
} from 'react-native';

import stylesheet from './Style'
import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import MyText from './MyText';
import { MaterialIcons, Entypo, Feather, FontAwesome, MaterialCommunityIcons, AntDesign, FontAwesome5 } from '@expo/vector-icons'; 

import * as SecureStore from 'expo-secure-store';
import axios from 'axios';

import MyRequests from './assets/icons/MyRequests.svg';
import NewRequest from './assets/icons/NewRequest.svg';
import Dashboard from './assets/icons/Dashboard.svg';
import Documents from './assets/icons/Documents.svg';
import MySVG from './assets/MySVG';
import * as logo from './assets/icons/mdata_logo.png';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StackActions } from '@react-navigation/routers';
import * as db from './database';

import Constants from "expo-constants"

import { useModal } from "./hooks/modal";


const CustomBottomSheetContent = (props) => {
    const css = stylesheet();
    const modal = useModal();
    
    const push = (name, params) => {
        if(Platform.OS==='web'){
            document.documentElement.scrollTop = 0;
        }
        props.navigation.current && props.navigation.current.dispatch(StackActions.push(name, params));
    }

    const Logout = () => {
        props.bottomSheet.current?.dismiss();
        axios.get('/api/user/logout.php')
        .then(async() => {
            await AsyncStorage.removeItem('token');
            await AsyncStorage.removeItem('user');
            props.setUser(null);
            db.logout();
            // push('Landing Page');
        })
  }

  const MyNotifications = () => {
    props.bottomSheet.current?.dismiss();
    if(Platform.OS === 'web'){
        modal.confirm.show('Warning', 'This functionality is available only in the $android & $ios mobile apps.');
    }
    else{
        push('My Notifications');
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, zIndex: 2 }}>
        <View style={{flex: 1, paddingHorizontal: 30, paddingVertical: 10, marginBottom: 0}}>
            {
                props.user &&
                <TouchableOpacity style={styles.touchable} onPress={() => {props.bottomSheet.current?.dismiss(); push('My Profile');}}>
                    <View style={{flexDirection: 'row', alignItems: 'center', flex: 1}}>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="user-circle" size={20} color="#461b6f" style={styles.icon}/>
                        </View>
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                {props.user.firstName} {props.user.lastName}
                            </MyText>
                        </View>
                    </View>
                </TouchableOpacity>
            }
            {
                props.user && !props.user.isVGroup?
                <>
                    <TouchableOpacity style={styles.touchable} onPress={() => {props.bottomSheet.current?.dismiss(); push('My Requests');}}>
                        <MySVG source={MyRequests} style={styles.icon} />
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                My Requests
                            </MyText>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.touchable} onPress={() => {props.bottomSheet.current?.dismiss(); push('New Request');}}>
                        <MySVG source={NewRequest} style={styles.icon} />
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                New Request
                            </MyText>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.touchable} onPress={() => {props.bottomSheet.current?.dismiss(); push('Dashboard');}}>
                        <MySVG source={Dashboard} style={styles.icon} />
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                Dashboard
                            </MyText>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.touchable} onPress={() => {props.bottomSheet.current?.dismiss(); push('Documents');}}>
                        <MySVG source={Documents} style={styles.icon} />
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                Documents Library
                            </MyText>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.touchable} onPress={() => MyNotifications()}>
                        <MaterialCommunityIcons name="bell" size={20} color="#461b6f" style={styles.icon}/>
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                My Notifications
                            </MyText>
                        </View>
                    </TouchableOpacity>
                </>
                :<></>
            }
            {
                props.user && props.user.isVGroup?
                <>
                    <TouchableOpacity style={styles.touchable} onPress={() => {props.bottomSheet.current?.dismiss(); push('Manage Requests');}}>
                        <MySVG source={MyRequests} style={styles.icon} />
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                Manage Requests
                            </MyText>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.touchable} onPress={() => {props.bottomSheet.current?.dismiss(); push('New Request');}}>
                        <MySVG source={NewRequest} style={styles.icon} />
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                New Request
                            </MyText>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.touchable} onPress={() => {props.bottomSheet.current?.dismiss(); push('Manage Users');}}>
                        <FontAwesome5 name="users-cog" size={16} color="#461b6f" style={styles.vectorIcon} />
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                Manage Users
                            </MyText>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.touchable} onPress={() => {props.bottomSheet.current?.dismiss(); push('Dashboard');}}>
                        <MySVG source={Dashboard} style={styles.icon} />
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                Dashboard
                            </MyText>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.touchable} onPress={() => {props.bottomSheet.current?.dismiss(); push('Documents');}}>
                        <MySVG source={Documents} style={styles.icon} />
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                Documents Library
                            </MyText>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.touchable} onPress={() => MyNotifications()}>
                        <MaterialCommunityIcons name="bell" size={20} color="#461b6f" style={styles.icon}/>
                        <View style={styles.labelContainer}>
                            <MyText style={styles.label}>
                                My Notifications
                            </MyText>
                        </View>
                    </TouchableOpacity>
                </>
                :<></>
            }
            {
                props.user &&
                <TouchableOpacity style={styles.touchable} onPress={Logout}>
                    <MaterialIcons name="logout" size={13} color="white" style={[styles.icon, styles.logout]}/> 
                    <View style={styles.labelContainer}>
                        <MyText style={styles.label}>
                            Logout
                        </MyText>
                    </View>
                </TouchableOpacity>
            }
        </View>
        <View style={{flex: 0, paddingHorizontal: 30, paddingVertical: 0, marginBottom: Platform.OS==='web'?30:0}}>
            <TouchableOpacity onPress={() => {Linking.openURL('https://mdata.gr')}} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: Platform.OS === 'ios'?20:10}}>
                <MyText style={{flex: 1}}></MyText>
                <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <MyText style={[css.xxs, {textAlign: 'center', color: '#461b6f', marginRight: 3, marginBottom: Platform.OS === 'ios'?-2:0}]}>
                        Developed by
                    </MyText>
                    <Image source={require("./assets/icons/mdata_logo.png")} style={{width: 10*3.14, height: 10}}/>
                </View>
                <MyText style={[{flex: 1, textAlign: 'right'}, css.xs]}>Version {Constants.expoConfig.version} ({Constants.expoConfig.code})</MyText>
            </TouchableOpacity>
        </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  iconStyle: {
    width: 15,
    height: 15,
    marginHorizontal: 5,
  },
  customItem: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  vectorIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    paddingTop: 2
  },
  logout: {
    backgroundColor: '#461b6f',
    borderRadius: 10,
    paddingLeft: 5,
    paddingTop: 3,
    overflow: 'hidden'
  },
  touchable: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {

  },
  labelContainer: {
      marginLeft: 10,
      borderBottomWidth: 0.7,
      borderColor: '#e0dde3',
      flex: 1,
      justifyContent: 'center',
      paddingVertical: Platform.OS==='ios'?13:11
  },
  label: {
    color: '#461b6f',
  }
});


export default CustomBottomSheetContent

import React from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    Platform
  } from 'react-native'
import stylesheet from './Style'
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import axios from 'axios';

import { useModal } from './hooks/modal';

const ForgotPassword = (props) => {
    const css = stylesheet();
    const modal = useModal();

    const [email, setEmail] = React.useState('');

    const ForgotPassword = async() => {
        if(!email.trim()){
            modal.confirm.show('Warning', 'Please enter your email.');
            return;
        }
        const data = {
            email
        };
        console.log(data);
        axios.post('/api/user/forgotPassword.php', data)
        .then(async(res) => {
            modal.confirm.show('Success', 'Check your email for instructions on how to reset your password. Check your spam/junk folder, as well.');
            setTimeout(() => {
                props.Login()
            },2000)
        })
        .catch((err) => {
            console.log(err.response);
            if(err.response?.status == 400){
                modal.confirm.show('Warning', err.response.data);
            }
            else{
                modal.confirm.show('Warning', 'An internal error occured. Please try again later or contact us.');
            }
        })
    }

  return (
    <View style={{alignItems: 'center', justifyContent: 'center', marginTop: Platform.OS==='web'?18:15, marginBottom: Platform.OS==='web'?13:0}}>
        <View style={{width: 232}}>
            <MyText style={[css.title, css.bold]}>
                Forgot Password
            </MyText>
            <View style={{marginTop: 18, flexDirection: 'row'}}>
                <MyTextInput
                    style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
                    onChangeTextImmediate={setEmail}
                    value={email}
                    inputMode='email'
                    keyboardType='email-address'
                    autoCapitalize='none'
                    autoCorrect={false}
                    placeholder='Email'
                    fancyPlaceholder={true}
                    validation='email'
                    autoComplete='off'
                />
            </View>
            <View style={{marginTop: 18, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <TouchableOpacity onPress={() => ForgotPassword()} style={{flexGrow: 0, flexShrink: 0, flexBasis: 135}}>
                    <MyText style={[css.button, css.bold]}>
                        Continue
                    </MyText>
                </TouchableOpacity>
            </View>
            <View style={{marginTop: 15, alignItems: 'center'}}>
                <TouchableOpacity onPress={() => props.Login()} style={{flexGrow: 0, flexShrink: 0}}>
                    {/* <View style={{borderStyle: Platform.OS==='ios'?'solid':'dotted', borderColor: '#461b6f', borderBottomWidth: 1}}> */}
                        {/* <MyText style={[css.bold, {fontSize: 10, color: '#461b6f', paddingBottom: 3}]}>
                            Register and Join Us!
                        </MyText> */}
                    {/* </View> */}
                    <View style={{ overflow: 'hidden' }}>
                        <View
                        style={{
                            borderStyle: 'dashed',
                            borderWidth: 1,
                            borderColor: '#461b6f',
                            margin: -2,
                            marginBottom: 10,
                            paddingHorizontal: 1
                        }}>
                        {/* https://stackoverflow.com/questions/73506925/when-trying-to-add-a-dashed-border-style-getting-unsupported-dashed-dotted-b */}
                        <MyText style={[css.bold, css.s, {color: '#461b6f', paddingBottom: 3, textAlign: 'center'}]}>
                            Back to Login
                        </MyText>
                        {/* <View style={{ height: 1, width: 200 }} /> */}
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    </View>
  );
}

const styles = StyleSheet.create({

});

export default ForgotPassword
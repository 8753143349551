import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native';
import Collapsible from 'react-native-collapsible';
import MyCheckbox from '../MyCheckbox';
import MyPicker from '../MyPicker';
import MyText from '../MyText';
import MyTextarea from '../MyTextarea';
import MyTextInput from '../MyTextInput';
import stylesheet from '../Style'

const AnnexForm = (props) => {
    const css = stylesheet();
    
    const [comments, onComments] = React.useState(props.value?.comments??'');
    const [ew, onEw] = React.useState(props.ew?props.ew:(props.value?.ew??''));
    const [wasteForDelivery, onWasteForDelivery] = React.useState(props.value?.wasteForDelivery??'');
    const [maxStorageCapacity, onMaxStorageCapacity] = React.useState(props.value?.maxStorageCapacity??'');
    const [wasteRetainedOnBoard, onWasteRetainedOnBoard] = React.useState(props.value?.wasteRetainedOnBoard??'');
    const [estWasteUntilNextPort, onEstWasteUntilNextPort] = React.useState(props.value?.estWasteUntilNextPort??'');

    const [wasteForDeliveryType, setWasteForDeliveryType] = React.useState(props.value?.wasteForDeliveryType??'m³');
    const [maxStorageCapacityType, setMaxStorageCapacityType] = React.useState(props.value?.maxStorageCapacityType??'m³');
    const [wasteRetainedOnBoardType, setWasteRetainedOnBoardType] = React.useState(props.value?.wasteRetainedOnBoardType??'m³');
    const [estWasteUntilNextPortType, setEstWasteUntilNextPortType] = React.useState(props.value?.estWasteUntilNextPortType??'m³');


    React.useEffect(() => {
        props.onChange({
            ew,
            wasteForDelivery,
            wasteForDeliveryType,
            comments,
            maxStorageCapacity,
            maxStorageCapacityType,
            wasteRetainedOnBoard,
            wasteRetainedOnBoardType,
            estWasteUntilNextPort,
            estWasteUntilNextPortType
        });
    }, [ew, wasteForDelivery, wasteForDeliveryType, comments, maxStorageCapacity, maxStorageCapacityType, wasteRetainedOnBoard, wasteRetainedOnBoardType, estWasteUntilNextPort, estWasteUntilNextPortType]);

    return (
        <View style={[css.formContainer, styles.container]}>
            <View style={[{zIndex: 10}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    EW Code
                </MyText>
                <MyTextInput
                    inputMode='decimal'
                    keyboardType='phone-pad'
                    style={[css.formRowInput]}
                    onChangeText={onEw}
                    value={ew}
                    hasError={ew==''}
                    isInfo={props.isInfo}
                    editable={false}
                />
            </View>
            <View style={[{zIndex: 9}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Waste for delivery
                </MyText>
                <View style={[{flex: 1}, css.doubleInput]}>
                    <MyTextInput
                        inputMode='decimal'
                        keyboardType='phone-pad'
                        style={[{flexGrow: 1, flexShrink: 1, marginRight: 5}, css.formRowInput]}
                        onChangeText={onWasteForDelivery}
                        value={wasteForDelivery}
                        type={wasteForDeliveryType}
                        hasError={wasteForDelivery==''}
                        isInfo={props.isInfo}
                        maxStorageCapacity={maxStorageCapacity}
                        wasteRetainedOnBoard={wasteRetainedOnBoard}
                        validation='wasteForDelivery'
                        max={1000}
                    />
                    <View style={css.secondInput}>
                        <MyPicker
                        style={css.formRowInput}
                        width={65}
                        items={props.wasteMetrics}
                        value={props.isInfo?'':wasteForDeliveryType}
                        onValueChange={(item, itemIndex) =>
                            setWasteForDeliveryType(item.value)
                        }
                        isInfo={props.isInfo}
                        >
                        </MyPicker>
                    </View>
                </View>
            </View>
            <View style={[{zIndex: 9}, css.formTextareaRow]}>
                <MyText style={[css.formRowLabel, {marginBottom: 5}]}>
                    Comments
                </MyText>
                <MyTextarea
                    style={[{flexGrow: 1, flexShrink: 0, marginRight: 5}, css.formRowInput]}
                    onChangeText={onComments}
                    value={comments}
                    isInfo={props.isInfo}
                />
            </View>
            <View style={[{zIndex: 8}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Max Storage Capacity
                </MyText>
                <View style={[{flex: 1}, css.doubleInput]}>
                    <MyTextInput
                        inputMode='decimal'
                        keyboardType='phone-pad'
                        style={[{flexGrow: 1, flexShrink: 1, marginRight: 5}, css.formRowInput]}
                        onChangeText={onMaxStorageCapacity}
                        value={maxStorageCapacity}
                        type={maxStorageCapacityType}
                        isInfo={props.isInfo}
                        max={1000}
                    />
                    <View style={css.secondInput}>
                        <MyPicker
                        style={css.formRowInput}
                        width={65}
                        items={props.wasteMetrics}
                        value={props.isInfo?'':maxStorageCapacityType}
                        onValueChange={(itemValue, itemIndex) =>
                            setMaxStorageCapacityType(itemValue)
                        }
                        isInfo={props.isInfo}
                        >
                        </MyPicker>
                    </View>
                </View>
            </View>
            <View style={[{zIndex: 7}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Waste retained on board
                </MyText>
                <View style={[{flex: 1}, css.doubleInput]}>
                    <MyTextInput
                        inputMode='decimal'
                        keyboardType='phone-pad'
                        style={[{flexGrow: 1, flexShrink: 1, marginRight: 5}, css.formRowInput]}
                        onChangeText={onWasteRetainedOnBoard}
                        value={wasteRetainedOnBoard}
                        type={wasteRetainedOnBoardType}
                        isInfo={props.isInfo}
                        maxStorageCapacity={maxStorageCapacity}
                        wasteForDelivery={wasteForDelivery}
                        validation='wasteRetainedOnBoard'
                        max={1000}
                    />
                    <View style={css.secondInput}>
                        <MyPicker
                        style={css.formRowInput}
                        width={65}
                        items={props.wasteMetrics}
                        value={props.isInfo?'':wasteRetainedOnBoardType}
                        onValueChange={(item, itemIndex) =>
                            setWasteRetainedOnBoardType(item.value)
                        }
                        isInfo={props.isInfo}
                        >
                        </MyPicker>
                    </View>
                </View>
            </View>
            <View style={[{zIndex: 6}, css.formRow]}>
                <MyText style={css.formRowLabel}>
                    Est. Waste Until Next Port
                </MyText>
                <View style={[{flex: 1}, css.doubleInput]}>
                    <MyTextInput
                        inputMode='decimal'
                        keyboardType='phone-pad'
                        style={[{flexGrow: 1, flexShrink: 1, marginRight: 5}, css.formRowInput]}
                        onChangeText={onEstWasteUntilNextPort}
                        value={estWasteUntilNextPort}
                        type={estWasteUntilNextPortType}
                        isInfo={props.isInfo}
                        maxStorageCapacity={maxStorageCapacity}
                        wasteRetainedOnBoard={wasteRetainedOnBoard}
                        validation='estWasteUntilNextPort'
                        max={1000}
                    />
                    <View style={css.secondInput}>
                        <MyPicker
                        style={css.formRowInput}
                        width={65}
                        items={props.wasteMetrics}
                        value={props.isInfo?'':estWasteUntilNextPortType}
                        onValueChange={(itemValue, itemIndex) =>
                            setEstWasteUntilNextPortType(itemValue)
                        }
                        isInfo={props.isInfo}
                        >
                        </MyPicker>
                    </View>
                </View>
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        paddingVertical: 10,
        paddingHorizontal: 14,
        // paddingBottom: 0,
        borderColor: '#532b75',
        borderWidth: 0.5,
        borderRadius: 20,
        marginBottom: 15,
        overflow: 'hidden',
    }
})
export default AnnexForm
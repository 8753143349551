import React from 'react';
import {
    View
} from 'react-native'
import { ScrollView } from 'react-native-gesture-handler';
import stylesheet from './Style'
import MyText from './MyText';

const About = (props) => {
    const css = stylesheet();
    
  return (
    <ScrollView vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
    {/* <TouchableWithoutFeedback> */}
        <View style={[css.background, css.formBackground]}>
            <View style={[{zIndex: 10}, css.form]}>
                <MyText style={[css.formTitle, css.bold]}>
                    About Footprint
                </MyText>
                <View
                style={{
                    borderBottomColor: '#f3f1f8',
                    borderBottomWidth: 2,
                }}
                />
                <View style={{paddingVertical: 20}}>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Footprint</MyText> is an innovative, comprehensive digital platform designed to streamline and
                        synchronize the complex process of port reception facilities (PRF) for the maritime industry.
                        This versatile platform is accessible on both mobile and desktop devices, ensuring seamless
                        communication and coordination between ships, shipping companies, PRF service providers,
                        and port authorities.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        Key Features:
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Efficient Waste Disposal Management:</MyText> Footprint simplifies the waste disposal process for
                        ships, providing user-friendly tools to arrange and schedule waste disposal services at the
                        port. Ship operators can easily specify the types and quantities of waste to be disposed of,
                        ensuring a smooth and environmentally responsible waste management process.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Process Mapping for Shipping Companies:</MyText> Shipping companies can utilize Footprint to map
                        out and oversee the entire PRF process. The platform offers a digital dashboard that allows
                        them to track each ship&#39;s waste disposal activities, ensuring compliance with regulations and
                        company policies.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Activity Tracking for PRF Companies:</MyText> PRF service providers benefit from real-time activity
                        tracking and data management capabilities. Footprint allows them to monitor waste disposal
                        requests, track service completion, and maintain accurate records. This transparency
                        enhances their operational efficiency and accountability.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Digitalization for Port Authorities:</MyText> Footprint assists port authorities in digitalizing their
                        processes and elevating the quality of services provided to ships. It offers tools for
                        scheduling, resource allocation, and monitoring port activity, reducing administrative
                        overhead and improving service reliability.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Secure and Collaborative Environment:</MyText> Footprint ensures data security through robust
                        encryption and access control measures. Collaboration features enable seamless
                        communication and data sharing among all stakeholders, fostering cooperation and
                        efficiency.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Data Analytics and Reporting:</MyText> The platform provides valuable insights through data
                        analytics and reporting tools. Shipping companies, PRF service providers, and port
                        authorities can access performance metrics, compliance reports, and historical data to
                        optimize their operations and make informed decisions.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Environmental Compliance:</MyText> Footprint places a strong emphasis on environmental
                        responsibility. It helps ships and ports adhere to international regulations and standards for
                        waste disposal, reducing the maritime industry&#39;s ecological footprint.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>User-Friendly Interface:</MyText> Footprint boasts an intuitive and user-friendly interface, making it
                        accessible to a wide range of users, from ship captains to port administrators. Training and
                        onboarding are efficient, ensuring rapid adoption across the industry.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        Benefits:
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Streamlined Operations:</MyText> Footprint simplifies the PRF process, reducing paperwork,
                        eliminating bottlenecks, and enhancing overall operational efficiency.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Enhanced Environmental Stewardship:</MyText> The platform promotes responsible waste disposal
                        practices, contributing to cleaner seas and coastlines.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Improved Transparency:</MyText> Stakeholders have access to real-time data, ensuring transparency
                        and accountability throughout the waste disposal process.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Cost Savings:</MyText> By optimizing waste disposal processes and resource allocation, Footprint
                        helps reduce costs for both shipping companies and PRF service providers.
                    </MyText>
                    <MyText style={css.p}>
                        <MyText style={css.bold}>Competitive Advantage:</MyText> Ports that embrace Footprint gain a competitive edge by offering
                        efficient and environmentally friendly services, attracting more ships and business.
                    </MyText>
                    <MyText style={css.p}>
                        Footprint is the ultimate digital solution for the maritime industry, revolutionizing the way
                        port reception facilities are managed and contributing to a sustainable and efficient future
                        for the shipping and port sectors.
                    </MyText>
                </View>
            </View>
        </View>
     </ScrollView>
  );
}

export default About

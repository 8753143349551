import { useEffect, useState } from "react"
import NetInfo from "@react-native-community/netinfo";
import { Platform } from "react-native";
import * as db from './database';
import axios from 'axios';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { useModal } from "./hooks/modal";

const SyncRequests = (props) => {
    const modal = useModal();

    const [connected, setConnected] = useState(false);

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
            setConnected(state.isConnected);
        });

        return () => {
            unsubscribe();
        }
    },[]);

    useEffect(() => {
        console.log('Is connected?', connected);
        const Filters = async() => {
            await db.syncFilters();
        }
        const SyncNew = async() => {
            const pendingRequests = await db.getAll('pending_requests');
            if(pendingRequests.length > 0){
                let counter = 0;
                Promise.allSettled(pendingRequests.map((request) => axios.post('/api/newRequest.php', request.data)))
                .then((results) => {
                results.forEach(async(result, index) => {
                    if (result.status === 'fulfilled') {
                    counter++;
                    const request = pendingRequests[index];
                    await db.remove('pending_requests', request.id);
                    }
                })
                let message = 'Your requests have been synced.';
                if(counter == 1){
                    message = 'Your request has been synced.';
                }
                if(counter > 0){
                    modal.confirm.show('Update', message);
                }
                SyncEdit();
                })
            }
            else{
                SyncEdit();
            }
        }

        const SyncEdit = async() => {
            let pendingRequests = JSON.parse(await AsyncStorage.getItem('PendingEditRequests')??'[]');
            if(pendingRequests.length > 0){
                let counter = 0;
                Promise.allSettled(pendingRequests.map(async(requestId) => {
                    let pendingRequest = await db.get('requests', requestId);
                    pendingRequest.data.id = requestId;
                    return axios.post('/api/editRequest.php', pendingRequest.data);
                }))
                .then((results) => {
                results.forEach(async(result, index) => {
                    if (result.status === 'fulfilled') {
                    counter++;
                    pendingRequests = pendingRequests.splice(index, 1);
                    }
                })
                AsyncStorage.setItem('PendingEditRequests', JSON.stringify(pendingRequests));
                let message = 'Your edited requests have been synced.';
                if(counter == 1){
                    message = 'Your edited request has been synced.';
                }
                if(counter > 0){
                    modal.confirm.show('Update', message);
                }
                Filters();
                })
            }
            else{
                Filters();
            }
        }
        if(connected && Platform.OS !== 'web'){
            SyncNew();
        }
    },[connected]);
}

export default SyncRequests
import React from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, ScrollView, TextInput, TouchableWithoutFeedback } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  SafeAreaView,
  SafeAreaProvider,
  SafeAreaInsetsContext,
  useSafeAreaInsets,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import { useFonts } from 'expo-font';
import stylesheet from './Style'
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import MyPicker from './MyPicker';
import Header from './Header';
import Footer from './Footer';

export default function Home({ navigation }) {
  const css = stylesheet();
  
  return (
    <ScrollView contentContainerStyle={{flexShrink: 0, flexGrow: 1}}>
        <MyText>Home</MyText>
    </ScrollView>
  );
}

const styles = StyleSheet.create({

});

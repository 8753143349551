import React from 'react';
import {
    Linking,
    Platform,
    View
} from 'react-native'
import { ScrollView } from 'react-native-gesture-handler';
import stylesheet from './Style'
import MyText from './MyText';

const Privacy = (props) => {
    const css = stylesheet();

    const LinkTo = (to) => {
        if(Platform.OS==='web'){
            document.documentElement.scrollTop = 0;
        }
        props.navigation.push(to);
    }
    
  return (
    <ScrollView vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
    {/* <TouchableWithoutFeedback> */}
        <View style={[css.background, css.formBackground]}>
            <View style={[{zIndex: 10}, css.form]}>
                <MyText style={[css.formTitle, css.bold]}>
                    Privacy Policy
                </MyText>
                <View
                style={{
                    borderBottomColor: '#f3f1f8',
                    borderBottomWidth: 2,
                }}
                />
                <View style={{paddingVertical: 20}}>
                    <MyText style={css.p}>
                        This legal disclaimer (&quot;Disclaimer&quot;) governs the use of the FOOTPRINT Application and Web
                        Platform (&quot;FOOTPRINT&quot;). By accessing or using FOOTPRINT, you agree to be bound by this
                        Disclaimer. If you do not agree to these terms, please refrain from using FOOTPRINT.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        No Legal Advice
                    </MyText>
                    <MyText style={css.p}>
                        The information provided on FOOTPRINT does not constitute legal, financial, or professional
                        advice. Users should not rely on the information on FOOTPRINT as a substitute for seeking
                        professional advice or making informed decisions.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        Accuracy and Completeness
                    </MyText>
                    <MyText style={css.p}>
                        While we strive to provide accurate and up-to-date information, we do not guarantee the
                        accuracy, completeness, or reliability of any information on FOOTPRINT. Users are
                        encouraged to independently verify any information obtained from FOOTPRINT before
                        making any decisions or taking any actions.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        User Responsibility
                    </MyText>
                    <MyText style={css.p}>
                        Users of FOOTPRINT are solely responsible for their actions and decisions based on the
                        information obtained from the platform. We do not accept any liability for any direct,
                        indirect, incidental, consequential, or punitive damages arising from the use or misuse of
                        FOOTPRINT.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        Third-Party Content
                    </MyText>
                    <MyText style={css.p}>
                        FOOTPRINT may include links to third-party websites or content. We do not endorse or
                        assume responsibility for the accuracy, legality, or content of any third-party websites linked
                        to from FOOTPRINT. Accessing third-party websites is at the user&#39;s own risk.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        User Contributions
                    </MyText>
                    <MyText style={css.p}>
                        Users may contribute content to FOOTPRINT, such as comments, reviews, or suggestions. By
                        submitting content, users grant us a non-exclusive, royalty-free, worldwide, perpetual
                        license to use, reproduce, modify, adapt, publish, translate, create derivative works from,
                        distribute, and display the content in any media.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        Privacy
                    </MyText>
                    <MyText style={css.p}>
                        We value your privacy and take it seriously. Please refer to our Privacy Policy for details on
                        how we collect, use, and protect your personal information while using FOOTPRINT.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        Changes to Disclaimer
                    </MyText>
                    <MyText style={css.p}>
                        We reserve the right to update or modify this Disclaimer at any time. Users are responsible
                        for reviewing this Disclaimer periodically to ensure they are aware of any changes.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        Contact Information
                    </MyText>
                    <MyText style={css.p}>
                        If you have any questions or concerns regarding this Disclaimer or the use of FOOTPRINT,
                        please contact us at <MyText onPress={() => Linking.openURL('mailto:support@mdata.gr')} style={{color: 'blue', textDecorationLine: 'underline'}}>support@mdata.gr</MyText>.
                    </MyText>
                    <MyText style={css.p}>
                        By accessing and using FOOTPRINT, you acknowledge that you have read, understood, and
                        agreed to this Disclaimer. If you do not agree with any part of this Disclaimer, please
                        discontinue your use of FOOTPRINT immediately.
                    </MyText>
                </View>
            </View>
        </View>
     </ScrollView>
  );
}

export default Privacy
import React from 'react'
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import MyText from './MyText';
import stylesheet from './Style'

import * as FileSystem from 'expo-file-system';
import FileViewer from "react-native-file-viewer";
import * as Sharing from 'expo-sharing';

import * as db from './database';
import { useModalStore } from './hooks/modal';

const MyDocumentModal = (props) => {
    const css = stylesheet();
    const modal = useModalStore();

    const Open = () => {
        if(Platform.OS === 'web'){
            var a = document.createElement("a");
            a.href = modal.document.uri;
            a.download = modal.document.name;
            a.click();
        }
        else{
            FileViewer.open(modal.document.uri);
        }
        modal.document.hide();
    }
    
    const Share = () => {
        Sharing.shareAsync(modal.document.uri, {
            dialogTitle: 'Share file'
        })
        modal.document.hide();
    }

    const Delete = async() => {
        modal.confirm.show("Warning", "Are you sure you want to Delete this document?", async() => {
            if(Platform.OS === 'web'){
                await db.deleteDocument(modal.document.id);
            }
            else{
                await FileSystem.deleteAsync(modal.document.uri);
            }
            modal.document.hide();
            modal.document.callback();
        });
    }

    return (
        <>
            {modal.document.visible &&
                <View style={styles.bg}>
                    <View style={styles.modal}>
                        <View style={styles.header}>
                            <MyText style={[css.ml, {color: '#461b6f'}, css.bold]}>
                                {modal.document.name}
                            </MyText>
                        </View>
                        <View style={styles.body}>
                            <TouchableOpacity style={styles.btn} onPress={Open}>
                                <MyText style={css.m}>{Platform.OS === 'web'?'Download':'Open'}</MyText>
                            </TouchableOpacity>
                            {
                                Platform.OS === 'web'?
                                null
                                :
                                <TouchableOpacity style={styles.btn} onPress={Share}>
                                    <MyText style={css.m}>Share</MyText>
                                </TouchableOpacity>
                            }
                            <TouchableOpacity style={styles.btn} onPress={Delete}>
                                <MyText style={css.m}>Delete</MyText>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.footer}>
                            <TouchableOpacity onPress={modal.document.hide} style={{alignItems: 'center', marginBottom: 0}}>
                                <MyText style={[{paddingVertical: 10, paddingHorizontal: 30, backgroundColor: '#461b6f', color: 'white', borderRadius: 12, overflow: 'hidden'}, css.bold]}>
                                    Cancel
                                </MyText>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            }
        </>
    );
}
const styles = StyleSheet.create({
    bg: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        paddingTop: Platform.OS==='web'?64:94,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modal: {
        backgroundColor: 'white',
        borderRadius: 8,
        width: 350,
        maxWidth: '95%',
        overflow: 'hidden'
    },
    header: {
        padding: 10,
        alignItems: 'center'
    },
    body: {
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
        borderBottomColor: '#dee2e6',
        padding: 15,
        paddingBottom: 5,
        alignItems: 'center',
        textAlign: 'center'
    },
    footer: {
        padding: 10,
        // flex: 1,
        // display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    btn: {
        marginBottom: 10
    }
})

export default MyDocumentModal

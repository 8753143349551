import React, {useState, useEffect} from 'react';
import { Platform, StyleSheet, Text, View, TextInput, Image, TouchableOpacity, Keyboard, useWindowDimensions } from 'react-native';
import MyText from './MyText';
import * as logo from './assets/icons/logo.png';
import * as logoSmall from './assets/icons/logo-small.png';
import { FontAwesome, MaterialCommunityIcons, FontAwesome5, AntDesign } from '@expo/vector-icons'; 
import MyRequests from './assets/icons/MyRequests.svg';
import NewRequest from './assets/icons/NewRequest.svg';
import Dashboard from './assets/icons/Dashboard.svg';
import Documents from './assets/icons/Documents.svg';
import MySVG from './assets/MySVG';
import stylesheet from './Style'

export default function Footer(props) {
  const css = stylesheet();
  
  const {height, width} = useWindowDimensions();

  const [keyboardStatus, setKeyboardStatus] = useState(false);

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardStatus(true);
    });
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardStatus(false);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  if(width >= 992)
    return <></>

  if(!keyboardStatus && props.user && !props.user.isVGroup){
    return <View style={styles.background}>
            <TouchableOpacity style={styles.touchable} onPress={() => props.navigation.push('My Requests')}>
              <MySVG source={MyRequests} style={styles.icon} />
              <MyText style={[css.xs, styles.label]}>My Requests</MyText>
            </TouchableOpacity>
            <TouchableOpacity style={styles.touchable} onPress={() => props.navigation.push('New Request')}>
              <MySVG source={NewRequest} style={styles.icon} />
              <MyText style={[css.xs, styles.label]}>New Request</MyText>
            </TouchableOpacity>
            <TouchableOpacity style={styles.touchable} onPress={() => props.navigation.push('Dashboard')}>
              <MySVG source={Dashboard} style={styles.icon} />
              <MyText style={[css.xs, styles.label]}>Dashboard</MyText>
            </TouchableOpacity>
            <TouchableOpacity style={styles.touchable} onPress={() => props.navigation.push('Documents')}>
              <MySVG source={Documents} style={styles.icon} />
              <MyText style={[css.xs, styles.label]}>Documents</MyText>
            </TouchableOpacity>
          </View>
  }
  else if(!keyboardStatus && props.user && props.user.isVGroup){
    return <View style={styles.background}>
            <TouchableOpacity style={styles.touchable} onPress={() => props.navigation.push('Manage Requests')}>
              <MySVG source={MyRequests} style={styles.icon} />
              <MyText style={[css.xs, styles.label]}>Manage Requests</MyText>
            </TouchableOpacity>
            <TouchableOpacity style={styles.touchable} onPress={() => props.navigation.push('Manage Users')}>
              <FontAwesome5 name="users-cog" size={24} color="#461b6f" style={styles.vectorIcon} />
              <MyText style={[css.xs, styles.label]}>Manage Users</MyText>
            </TouchableOpacity>
            <TouchableOpacity style={styles.touchable} onPress={() => props.navigation.push('Dashboard')}>
              <MySVG source={Dashboard} style={styles.icon} />
              <MyText style={[css.xs, styles.label]}>Dashboard</MyText>
            </TouchableOpacity>
            <TouchableOpacity style={styles.touchable} onPress={() => props.navigation.push('Documents')}>
              <MySVG source={Documents} style={styles.icon} />
              <MyText style={[css.xs, styles.label]}>Documents</MyText>
            </TouchableOpacity>
          </View>
  }
  return <></>
}

const styles = StyleSheet.create({
  background: {
    display: 'flex',
    backgroundColor: '#F6F4F9',
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTopWidth: 4,
    borderColor: '#e9e3f0',
    // boxShadow: '0px 0px 15px #e9e3f0',
    justifyContent: 'space-around',
    paddingBottom: Platform.OS === 'ios' ? 20 : 10
  },
  touchable: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
  },
  icon: {
    width: 30,
    height: 30,
    resizeMode: 'contain'
  },
  vectorIcon: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
    paddingTop: 3
  },
  label: {
    color: '#461b6f',
    marginTop: 5
  }
});

import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow'

export const useModalStore = create((set) => ({
    confirm: {
        header: '',
        body: '',
        callback: undefined,
        visible: false,
        show: (header = '', body = '', callback = undefined) => {
            set((state) => ({
                confirm: {
                    ...state.confirm,
                    header,
                    body,
                    callback,
                    visible: true
                }
            }));
        },
        hide: () => {
            set((state) => ({
                confirm: {
                    ...state.confirm,
                    visible: false
                }
            }));
        }
    },
    document: {
        uri: '',
        name: '',
        id: '',
        callback: undefined,
        visible: false,
        show: (uri = '', name = '', id= '', callback = undefined) => {
            set((state) => ({
                document: {
                    ...state.document,
                    uri,
                    name,
                    id,
                    callback,
                    visible: true
                }
            }));
        },
        hide: () => {
            set((state) => ({
                document: {
                    ...state.document,
                    visible: false
                }
            }));
        }
    }
}))

export const useModal = () => useModalStore( // This will be used in components that want to call show/hide
    (state) => state,
    (prev, current) => true // Always equal to prevent re-renders
  )
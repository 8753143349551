import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native';
import MyCheckbox from '../../MyCheckbox';
import MyText from '../../MyText';
import AnnexForm from '../AnnexForm';
import { AntDesign } from '@expo/vector-icons'; 
import stylesheet from '../../Style'
import { TouchableOpacity } from 'react-native-gesture-handler';

const AnnexINonHazardous = (props) => {
    const css = stylesheet();
    
    const [collapsed, setCollapsed] = React.useState(true);

    const [oilyBilgeWater, setOilyBilgeWater] = React.useState(props?.value?.oilyBilgeWater?.active??false);
    const [oilyResidues, setOilyResidues] = React.useState(props?.value?.oilyResidues?.active??false);
    const [oilyTank, setOilyTank] = React.useState(props?.value?.oilyTank?.active??false);
    const [dirtyBallast, setDirtyBallast] = React.useState(props?.value?.dirtyBallast?.active??false);
    const [scale, setScale] = React.useState(props?.value?.scale?.active??false);
    const [other, setOther] = React.useState(props?.value?.other?.active??false);

    const [oilyBilgeWaterData, setOilyBilgeWaterData] = React.useState(props?.value?.oilyBilgeWater?.data??{});
    const [oilyResiduesData, setOilyResiduesData] = React.useState(props?.value?.oilyResidues?.data??{});
    const [oilyTankData, setOilyTankData] = React.useState(props?.value?.oilyTank?.data??{});
    const [dirtyBallastData, setDirtyBallastData] = React.useState(props?.value?.dirtyBallast?.data??{});
    const [scaleData, setScaleData] = React.useState(props?.value?.scale?.data??{});
    const [otherData, setOtherData] = React.useState(props?.value?.other?.data??{});

    React.useEffect(() => {
        setCollapsed(!props.checked);
    }, [props.checked]);

    React.useEffect(() => {
        onChange();
    }, [oilyBilgeWater, oilyResidues, oilyTank, dirtyBallast, scale, other,
        oilyBilgeWaterData, oilyResiduesData, oilyTankData, dirtyBallastData, scaleData, otherData]);

    const onChange = () => {
        props.onChange({
            oilyBilgeWater: {
                active: oilyBilgeWater,
                data: oilyBilgeWaterData
            },
            oilyResidues: {
                active: oilyResidues,
                data: oilyResiduesData
            },
            oilyTank: {
                active: oilyTank,
                data: oilyTankData
            },
            dirtyBallast: {
                active: dirtyBallast,
                data: dirtyBallastData
            },
            scale: {
                active: scale,
                data: scaleData
            },
            other: {
                active: other,
                data: otherData
            },
        });
    }

    return (
        <View style={styles.annexContainer}>
            <TouchableOpacity activeOpacity={0.7} onPress={() => {setCollapsed(prev => !prev)}}>
                <MyText style={[css.m, styles.annexTitle, props.hasOne?css.bold:css.normal]}>ANNEX I - Oily Waste</MyText>
                <View style={{position: 'absolute', right: 10, top: 14}}>
                    <AntDesign name="down" size={12} color="#461b6f" style={{transform: [{rotate: collapsed?'0deg':'180deg'}]}}/>
                </View>
            </TouchableOpacity>
            <View style={styles.formContainer}>
                <View style={{display: collapsed?'none':'flex'}}>
                    <View style={styles.row}>
                        <MyCheckbox onClick={() => {setOilyBilgeWater(prev => !prev);}} checked={oilyBilgeWater} label='Oily Bilge Water' isInfo={props.isInfo}/>
                    </View>
                    {
                        oilyBilgeWater && 
                        <View style={[styles.row]}>
                            <AnnexForm ew={'800801'} wasteMetrics={props.wasteMetrics} onChange={setOilyBilgeWaterData} value={oilyBilgeWaterData} isInfo={props.isInfo}/>
                        </View>
                    }
                    <View style={styles.row}>
                        <MyCheckbox onClick={() => {setOilyResidues(prev => !prev);}} checked={oilyResidues} label='Oily Residues - Sludge' isInfo={props.isInfo}/>
                    </View>
                    {
                        oilyResidues &&
                        <View style={[styles.row]}>
                            <AnnexForm ew={'800802'} wasteMetrics={props.wasteMetrics} onChange={setOilyResiduesData} value={oilyResiduesData} isInfo={props.isInfo}/>
                        </View>
                    }
                    <View style={styles.row}>
                        <MyCheckbox onClick={() => {setOilyTank(prev => !prev);}} checked={oilyTank} label='Oily tank water - Slops' isInfo={props.isInfo}/>
                    </View>
                    {
                        oilyTank &&
                        <View style={[styles.row]}>
                            <AnnexForm ew={'800803'} wasteMetrics={props.wasteMetrics} onChange={setOilyTankData} value={oilyTankData} isInfo={props.isInfo}/>
                        </View>
                    }
                    <View style={styles.row}>
                        <MyCheckbox onClick={() => {setDirtyBallast(prev => !prev);}} checked={dirtyBallast} label='Dirty ballast water' isInfo={props.isInfo}/>
                    </View>
                    {
                        dirtyBallast &&
                        <View style={[styles.row]}>
                            <AnnexForm ew={'800804'} wasteMetrics={props.wasteMetrics} onChange={setDirtyBallastData} value={dirtyBallastData} isInfo={props.isInfo}/>
                        </View>
                    }
                    <View style={styles.row}>
                        <MyCheckbox onClick={() => {setScale(prev => !prev);}} checked={scale} label='Scale and sludge from tank cleaning' isInfo={props.isInfo}/>
                    </View>
                    {
                        scale &&
                        <View style={[styles.row]}>
                            <AnnexForm ew={'800805'} wasteMetrics={props.wasteMetrics} onChange={setScaleData} value={scaleData} isInfo={props.isInfo}/>
                        </View>
                    }
                    <View style={styles.row}>
                        <MyCheckbox onClick={() => {setOther(prev => !prev);}} checked={other} label='Other (please specify)' isInfo={props.isInfo}/>
                    </View>
                    {
                        other &&
                        <View style={[styles.row]}>
                            <AnnexForm ew={'800806'} wasteMetrics={props.wasteMetrics} onChange={setOtherData} value={otherData} isInfo={props.isInfo}/>
                        </View>
                    }
                </View>
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    annexContainer: {
        marginBottom: 0
    },
    annexTitle: {
        backgroundColor: '#e9e3f0',
        color: '#57307e',
        textAlign: 'center',
        width: '100%',
        padding: 10
    },
    formContainer: {
        padding: 20,
        paddingTop: 15,
        paddingVertical: 0,
        flex: 1
    },
    row: {
        marginBottom: 15
    }
})
export default AnnexINonHazardous
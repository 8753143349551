import React from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    ScrollView,
    useWindowDimensions
  } from 'react-native'
import stylesheet from '../Style'
import MyText from '../MyText';
import MyDatePicker from '../MyDatePicker';
import MyTextInput from '../MyTextInput';
import { MaterialIcons, Feather, AntDesign } from '@expo/vector-icons'; 

import axios from 'axios';
import MyPicker from '../MyPicker';

// const RNFS = require('react-native-fs');
import * as XLSX from 'xlsx'
// import RNFetchBlob from 'rn-fetch-blob';

import * as FileSystem from 'expo-file-system';

import MyReasonModal from '../MyReasonModal';

import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useModal } from '../hooks/modal';
import { RefreshControl } from 'react-native';

import * as db from '../database';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

let filteredData = [];

const tableHeader = ['Status', 'First Name', 'Last Name', 'Email Address', 'Phone Number', 'Profession', 'User Type', 'Registered At', 'Actions'];
// const tableData = [
//     ['123456', 'Blue Star Delos',       '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Submitted', '31-Jan-2023 11:00', ''],
//     ['213456', 'Blue Star Paros',       '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Validated', '31-Jan-2023 11:00', ''],
//     ['312456', 'Blue Star Mykonos',     '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Route Scheduled 7-Jan-2022 14:00', '31-Jan-2023 11:00', ''],
//     ['312456', 'Blue Star Mykonos',     '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Driver Arrived', '31-Jan-2023 11:00', ''],
//     ['412356', 'Aida Blue',             '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Waste Collected', '31-Jan-2023 11:00', ''],
//     ['512356', 'Aida Blue',             '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Waste Segregated', '31-Jan-2023 11:00', ''],
//     ['612345', 'Aida Blue',             '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Certificate Issued', '31-Jan-2023 11:00', ''],
//     ['612345', 'Aida Blue',             '31-Jan-2023 11:00', '31-Jan-2023 11:00', 'Κ.Λ. Περαία', 'XXXXXXX', 'XXXXXXX', 'Cancelled', '31-Jan-2023 11:00', ''],
// ];

const ManageUsers = (props) => {
    const css = stylesheet();
    const modal = useModal();
    
    const {height, width} = useWindowDimensions();
    const sizes = width>=768?
    [55, 100, 135, 135, 170, 120, 110, 110, 150, 90]:
    [45, 90, 125, 125, 160, 110, 100, 100, 140, 80];

    const pageRef = React.useRef();
    const tableRef = React.useRef();

    const [page, setPage] = React.useState('1');
    const [pageLength, setPageLength] = React.useState('5');

    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalCallback, setModalCallback] = React.useState(null);

    const [from, setFrom] = React.useState(null);
    const [to, setTo] = React.useState(null);
    const [fromKey, setFromKey] = React.useState(1000);
    const [toKey, setToKey] = React.useState(2000);

    const [type, setType] = React.useState('all');
    const [searchText, setSearchText] = React.useState('');
    const [sort, setSort] = React.useState([{column: 0, ascending: true}, {column: 7, ascending: false}]); //# column does not count, 0 is the Status

    const [tableData, setTableData] = React.useState([]);
    const [data, setData] = React.useState(tableData);

    const [initialLoadDone, setInitialLoadDone] = React.useState(false);

    const [refreshing, setRefreshing] = React.useState(true);

    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        GetTableData();
    }, []);

    // function to handle exporting
  const exportDataToExcel = async () => {
    console.log('export');
    let wb = XLSX.utils.book_new();

    // Format datetime columns
    let formattedTableData = tableData.map(row => {
        if(row[7])
            row[7] = new Date(row[7]);
        return row;
    })
    let ws = XLSX.utils.aoa_to_sheet([tableHeader, ...formattedTableData], { cellDates: true });
    ws['!cols'] = sizes.map(size => { return {wpx: size}});
    
    XLSX.utils.book_append_sheet(wb,ws,"Users")
    const wbout = XLSX.write(wb, {type:'base64', bookType:"xlsx"});
    
    const filename = "Users_"+FormatDatetime(new Date()).replace(":", "-").replace(" ", "_")+".xlsx";
    if(Platform.OS === 'android' || Platform.OS === 'ios'){
        const dir = FileSystem.documentDirectory+'Documents/'+props.user.id;
        const fileUri = dir+'/'+filename;
        const dirInfo = await FileSystem.getInfoAsync(dir);
        if (!dirInfo.exists) {
            console.log("Documents directory doesn't exist, creating...");
            await FileSystem.makeDirectoryAsync(dir, { intermediates: true });
        }
        await FileSystem.writeAsStringAsync(fileUri, wbout, { encoding: FileSystem.EncodingType.Base64 });
    }
    else if(Platform.OS === 'web'){
        await db.saveDocument(props.user.id, filename, wbout); 
        var a = document.createElement("a");
        a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + wbout;
        a.download = filename;
        a.click();
    }
    const body = "Exporting completed successfully with filename: "+filename+".";
    modal.confirm.show("Success", body);
  }

    const GetTableData = () => {
        // setTableData([]);
        // setInitialLoadDone(false);
        axios.get('/api/getUsers.php')
        .then((res) => {
            let result = [];
            res.data.forEach(row => {
                result.push([row.status, row.firstName, row.lastName, row.email, row.phone, row.profession, row.userType, new Date(row.registeredAt), row.id]);
            })
            setTableData(result);
            setRefreshing(false);
            setTimeout(() => {
                //Just to make sure the table data has time to load
                setInitialLoadDone(true);
            },1000)
        })
    }
    React.useEffect(() => {
        const unsubscribe = props.navigation.addListener('focus', () => {
            // if(pageRef){
            //     pageRef.current.scrollTo({
            //         x: 0,
            //         y: 0,
            //         animated: false,
            //     });
            // }
            // if(tableRef){
            //     tableRef.current.scrollTo({
            //         x: 0,
            //         y: 0,
            //         animated: false,
            //     });
            // }
            GetTableData();
        });
        const getPageLength = async() => {
            setPageLength(await AsyncStorage.getItem('pageLength')??'10');
        }
        getPageLength();
        return unsubscribe;
      }, []);

    React.useEffect(() => {
        FilterData();
    }, [type, searchText, tableData, from, to]);

    React.useEffect(() => {
        SortData();
    }, [sort]);

    const FormatDatetime = (dt) => {
        if(!dt)
            return '';
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const padLeft = (num) => {
            if(num < 10)
                return '0'+num;
            return num;
        }
        const date = new Date(dt);
        return padLeft(date.getDate()) + '-' + months[date.getMonth()] + '-' + date.getFullYear() + ' ' + padLeft(date.getHours()) + ':' + padLeft(date.getMinutes());
    }

    const Searched = (text) => {
        setSearchText(text);
    }

    const changeType = (type) => {
        setType(type);
    }

    const ChangeSort = (sortColumn) => {
        if(sortColumn > 0){
            setSort(prev => (
                [{
                    column: sortColumn,
                    ascending: prev[0].column==sortColumn?(!prev[0].ascending):true
                }]
            ));
        }
    }

    const Approve = (id, firstName, lastName) => {
        axios.get('/api/user/approve.php', {
            params: {
                id: id
            }
        })
        .then((res) => {
            modal.confirm.show("Success", "User "+firstName+" "+lastName+" is now Approved.");
            setTimeout(() => {
                GetTableData();
            },10)
        })
    }
    const Reject = (id, firstName, lastName) => {
        setModalOpen(true);
        setModalCallback((reason) => (reason) => {
            axios.get('/api/user/reject.php', {
                params: {
                    id: id,
                    reason: reason
                }
            })
            .then((res) => {
                modal.confirm.show("Success", "User "+firstName+" "+lastName+" is now Rejected.");
                GetTableData();
            })
        })
    }
    const Cancel = (id, firstName, lastName) => {
        modal.confirm.show("Warning", "Are you sure you want to Delete "+firstName+" "+lastName+"?", () => {
            axios.get('/api/user/delete.php', {
                params: {
                    id: id
                }
            })
            .then((res) => {
                modal.confirm.show("Success", "User "+firstName+" "+lastName+" has been successfully deleted.");
                GetTableData();
            })
        });
    }
    
    const Header = (headerProps) => {
        let text = headerProps?.text?.toString()??'';
        return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <MyText style={[styles.headerText, css.bold, css.s]}>{text}</MyText>
            {
                sort[0].column+1 == headerProps.cellIndex &&
                <View style={{position: 'absolute', right: -10}}>
                    <MaterialIcons style={{top: 0}} name={"keyboard-arrow-up"} size={18} color={sort[0].ascending?'white':'#461b6f'} />
                    <MaterialIcons style={{marginTop: -10}} name={"keyboard-arrow-down"} size={18} color={!sort[0].ascending?'white':'#461b6f'} />
                </View>
            }
            {
                sort[0].column+1 != headerProps.cellIndex && headerProps.cellIndex != 0 && (
                <View style={{position: 'absolute', right: -10}}>
                    <MaterialIcons style={{top: 0}} name={"keyboard-arrow-up"} size={18} color="#d1d1d1" />
                    <MaterialIcons style={{marginTop: -10}} name={"keyboard-arrow-down"} size={18} color="#d1d1d1" />
                </View>
                )
            }
            </View>;
    }
    const Bullet = (bulletProps) => {
        const orange = '#efa270';
        const yellow = '#fed354';
        const blue = '#1aa0eb';
        const green = '#23a43f';
        const red = '#e32112';
        let color = '';
        let text = bulletProps?.text?.toString()??'';

        if(bulletProps.cellIndex == 9){
            return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <TouchableOpacity onPress={() => props.navigation.push('User Info', { id: bulletProps.id })}>
                    <MaterialIcons name="visibility" size={18} color="#461b6f" style={{marginRight: 10}} />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Cancel(bulletProps.id, bulletProps.firstName, bulletProps.lastName)}>
                    <Feather name="x" size={18} color="#b10002" style={{marginRight: 10}}/>
                </TouchableOpacity>
            </View>;
        }
        if(!text){
            return <></>;
        }
        if(bulletProps.cellIndex == 8){
            text = <><MyText style={{fontSize: 0.001, color: 'transparent'}}>{text}</MyText>{FormatDatetime(text)}</>;
        }
        if(bulletProps.cellIndex == 1){
            if(text.startsWith('Pending')){
                return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <TouchableOpacity onPress={() => Approve(bulletProps.id, bulletProps.firstName, bulletProps.lastName)}>
                            <MyText style={[styles.statusAction, {backgroundColor: green, height: 24, lineHeight: Platform.OS==='android'?20:26}]}>
                                ✓
                            </MyText>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => Reject(bulletProps.id, bulletProps.firstName, bulletProps.lastName)}>
                            <MyText style={[styles.statusAction, {backgroundColor: red, height: 24, lineHeight: Platform.OS==='android'?20:24}]}>
                                x
                            </MyText>
                        </TouchableOpacity>
                    </View>
            }
            if(text.startsWith('Approved')){
                color = green;
            }
            else if(text.startsWith('Rejected')){
                color = red;
            }
            return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{width: 10, height: 10, borderRadius: 5, backgroundColor: color, marginRight: 5, marginTop: Platform.OS==='ios'?-1:1.5, overflow: 'hidden'}}></View>
                    <MyText style={css.s}>{text}</MyText>
                </View>
        }
        let textAlign = 'left';
        if(bulletProps.cellIndex == 0)
            textAlign = 'right';
        return <MyText style={[css.s, {textAlign: textAlign}]}>{text}</MyText>;
    }

    const renderHeader = (cellIndex, data) => {
        const totalTableWidth = sizes.reduce((partialSum, a) => partialSum + a, 0);
        let cellWidth = sizes[cellIndex];
        if(totalTableWidth < width-230-40){ //table fits without scrolling
            cellWidth = sizes[cellIndex]*((width-230-40)/totalTableWidth);
        }
        return (
            <TouchableOpacity onPress={() => ChangeSort(cellIndex-1)}>
                <View key={cellIndex} style={[css.s, styles.cell, styles.header, {width: cellWidth}]}>
                    <Header cellIndex={cellIndex} text={data}/>
                </View>
            </TouchableOpacity>
        );
    }
    const render = (rowIndex, cellIndex, data, id, status, firstName, lastName) => {
        const totalTableWidth = sizes.reduce((partialSum, a) => partialSum + a, 0);
        let cellWidth = sizes[cellIndex];
        if(totalTableWidth < width-230-40){ //table fits without scrolling
            cellWidth = sizes[cellIndex]*((width-230-40)/totalTableWidth);
        }
        return <View key={cellIndex} style={[rowIndex%2?styles.even:styles.odd, css.s, styles.cell, {width: cellWidth}]}><Bullet rowIndex={rowIndex} cellIndex={cellIndex} id={id} text={data} status={status} firstName={firstName} lastName={lastName}/></View>;
    }

    const FilterData = () => {
        setPage('1');
        filteredData = [];
        tableData.forEach((row, rowIndex) => {
            let addedRow = false;
            let fromDate = new Date();
            let toDate = new Date();
            if(from){
                fromDate = new Date(from);
                fromDate.setHours(0);
                fromDate.setMinutes(0);
            }
            if(to){
                toDate = new Date(to);
                toDate.setHours(23);
                toDate.setMinutes(59);
            }
            if(from && !to){
                if(new Date(row[7]).getTime() < fromDate.getTime())
                    return;
            }
            else if(!from && to){
                if(new Date(row[7]).getTime() > toDate.getTime())
                    return;
            }
            else if(from && to){
                if(new Date(row[7]).getTime() < fromDate.getTime() || new Date(row[7]).getTime() > toDate.getTime())
                    return;
            }
            
            // Datetime objects to ISO String in order for sorting to work
            if(row[7].toISOString)
                row[7] = row[7].toISOString();

            row.forEach((cell, cellIndex) => {
                if(addedRow)
                    return;
                if(cell.toString().toLowerCase().includes(searchText.toLowerCase())){
                    let hasFilter = false;
                    if(type == 'all'){
                        hasFilter = true;
                    }
                    if(row[6].startsWith('Ship') && type == 'ship'){
                        hasFilter = true;
                    }
                    else if(row[6].startsWith('Agent') && type == 'agent'){
                        hasFilter = true;
                    }
                    else if(row[6].startsWith('Shipping') && type == 'shipping'){
                        hasFilter = true;
                    }
                    else if(row[6].startsWith('Port') && type == 'port'){
                        hasFilter = true;
                    }
                    else if(row[6].startsWith('Marine') && type == 'marine'){
                        hasFilter = true;
                    }
                    else if(row[6].startsWith('Shipyard') && type == 'shipyard'){
                        hasFilter = true;
                    }
                    else if(row[6].startsWith('Industrial') && type == 'industrial'){
                        hasFilter = true;
                    }
                    if(hasFilter){
                        filteredData.push(row);
                        addedRow = true;
                    }
                    return;
                }
            })
        })
        SortData();
    }

    const SortData = () => {
        const statusSorting = {
            'Pending': 0,
            'Approved': 1,
            'Rejected': 2,
        };
        filteredData.sort((a, b) => {
            for(let i = 0; i<sort.length; i++){
                let s = sort[i];
                if(s.column === 0){ //status sorting
                    if (statusSorting[a[s.column].toString()] < statusSorting[b[s.column].toString()]) return s.ascending ? -1 : 1;
                    if (statusSorting[a[s.column].toString()] > statusSorting[b[s.column].toString()]) return s.ascending ? 1 : -1;    
                }
                else{
                    if (a[s.column].toString() < b[s.column].toString()) return s.ascending ? -1 : 1;
                    if (a[s.column].toString() > b[s.column].toString()) return s.ascending ? 1 : -1;    
                }
            }
            return 0;
        });
        let numbered = [['#', ...tableHeader]];
        filteredData.forEach((row, idx) => {
            numbered.push([(idx+1).toString(), ...row]);//.unshift(idx.toString()));
        })
        setData(numbered);
    }

  return (
      <>
        <KeyboardAwareScrollView vertical ref={pageRef} refreshControl={Platform.OS==='web'?undefined:<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>} nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
            <View style={[css.background, {paddingRight: 0, paddingTop: 20}]}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <MyText style={[css.title, css.bold]}>
                        Manage Users
                    </MyText>
                </View>
                {/* <MyText style={css.subtitle}>
                    Date
                </MyText> */}
                <View style={{marginTop: 15}}>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{marginRight: 7.5, flexBasis: 127.5, flexGrow: 0, flexShrink: 0}}>
                            <MyDatePicker
                                onChange={setFrom}
                                value={from}
                                placeholder='From'
                                key={fromKey}
                            />
                        </View>
                        <View style={{flex: 1, flexBasis: 127.5, flexGrow: 0, flexShrink: 0}}>
                            <MyDatePicker
                                onChange={setTo}
                                value={to}
                                placeholder='To'
                                key={toKey}
                            />
                        </View>
                        <View style={[{justifyContent: 'center', alignItems: 'center', marginLeft: 5}]}>
                            <TouchableOpacity onPress={() => {setFrom(null); setTo(null); setFromKey(prev => prev+1); setToKey(prev => prev+1);}}>
                                <MyText style={{color: '#461b6f', textDecorationLine: 'underline'}}>
                                    Clear
                                </MyText>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
                <View style={{marginTop: 10}}>
                    <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        <View style={styles.filterButton}>
                            <TouchableOpacity onPress={() => changeType('all')}>
                                <View style={[css.smallButton, {backgroundColor: type=='all'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, {color: type=='all'?'white':'#461b6f'}]}>
                                        All Users
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.filterButton}>
                            <TouchableOpacity onPress={() => changeType('ship')}>
                                <View style={[css.smallButton, {backgroundColor: type=='ship'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, {color: type=='ship'?'white':'#461b6f'}]}>
                                        Ships
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.filterButton}>
                            <TouchableOpacity onPress={() => changeType('agent')}>
                                <View style={[css.smallButton, {backgroundColor: type=='agent'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, {color: type=='agent'?'white':'#461b6f'}]}>
                                        Agents
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.filterButton}>
                            <TouchableOpacity onPress={() => changeType('shipping')}>
                                <View style={[css.smallButton, {backgroundColor: type=='shipping'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, css.smallButtonText, {color: type=='shipping'?'white':'#461b6f'}]}>
                                        {'Shipping\nCompanies'}
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.filterButton}>
                            <TouchableOpacity onPress={() => changeType('port')}>
                                <View style={[css.smallButton, {backgroundColor: type=='port'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, css.smallButtonText, {color: type=='port'?'white':'#461b6f'}]}>
                                        Ports
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.filterButton}>
                            <TouchableOpacity onPress={() => changeType('marine')}>
                                <View style={[css.smallButton, {backgroundColor: type=='marine'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, css.smallButtonText, {color: type=='marine'?'white':'#461b6f'}]}>
                                        Marines
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.filterButton}>
                            <TouchableOpacity onPress={() => changeType('shipyard')}>
                                <View style={[css.smallButton, {backgroundColor: type=='shipyard'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, css.smallButtonText, {color: type=='shipyard'?'white':'#461b6f'}]}>
                                        Shipyards
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.filterButton}>
                            <TouchableOpacity onPress={() => changeType('industrial')}>
                                <View style={[css.smallButton, {backgroundColor: type=='industrial'?'#461b6f':'white'}]}>
                                    <MyText style={[css.bold, css.smallButtonText, {color: type=='industrial'?'white':'#461b6f'}]}>
                                        {'Industrial\nCompanies'}
                                    </MyText>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
                {
                    width<576?
                    <View style={{display: 'flex', flexDirection: 'row', marginRight: 20, marginTop: 0, marginBottom: 10, justifyContent: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <MyText style={{paddingRight: 10}}>
                                Show
                            </MyText>
                            <View style={{width: 50}}>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={['5','10','25','50']}
                                    onValueChange={(item, itemIndex) => {
                                            setPageLength(item.value);
                                            AsyncStorage.setItem('pageLength', item.value);
                                            const maxPage = Math.ceil(data.length/parseInt(item.value));
                                            if(page > maxPage){
                                                setPage(maxPage.toString());
                                            }
                                        }
                                    }
                                    value={pageLength}
                                    placeholder={'Show'}
                                    // fancyPlaceholder={true}
                                />
                            </View>
                            <MyText style={{paddingLeft: 10}}>
                                Users
                            </MyText>
                        </View>
                    </View>
                    :<></>
                }
                <View style={{display: 'flex', flexDirection: 'row', marginRight: 20, marginTop: 0, marginBottom: 10, justifyContent: 'flex-start'}}>
                    {/* <MyText style={[{lineHeight: 33, marginRight: 10}, css.bold]}>Search</MyText> */}
                    <MyTextInput icon='search' onChangeTextImmediate={Searched} onChangeText={() => false} value={searchText} placeholder='Search...' style={{flexGrow: 0, flexShrink: 0, flexBasis: 232, marginRight: 38}}/>
                    {
                        width>=576?
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <MyText style={{paddingRight: 10}}>
                                Show
                            </MyText>
                            <View style={{width: 50}}>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={['5','10','25','50']}
                                    onValueChange={(item, itemIndex) => {
                                            setPageLength(item.value);
                                            AsyncStorage.setItem('pageLength', item.value);
                                            const maxPage = Math.ceil(data.length/parseInt(item.value));
                                            if(page > maxPage){
                                                setPage(maxPage.toString());
                                            }
                                        }
                                    }
                                    value={pageLength}
                                    placeholder={'Show'}
                                    // fancyPlaceholder={true}
                                />
                            </View>
                            <MyText style={{paddingLeft: 10}}>
                                Requests
                            </MyText>
                        </View>
                        :<></>
                    }
                    <TouchableOpacity onPress={() => exportDataToExcel()} style={{marginLeft: 'auto'}}>
                        <View style={{flexDirection: 'column', alignItems: 'center'}}>
                            <Feather name="download" size={18} color="#461b6f" />
                            <MyText style={[css.xs, {color: '#461b6f'}]}>
                                Export
                            </MyText>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={{marginBottom: 50}}>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{flexShrink: 0, flexGrow: 0, borderTopLeftRadius: 8, borderBottomLeftRadius: 8, overflow: 'hidden'}}>
                            {
                                data.length>0?
                                <View key={0} style={{display: 'flex', flexDirection: 'row'}}>
                                    {
                                        data[0].map((cellData, cellIndex) => {
                                            if(cellIndex >= 2)
                                                return <></>;
                                            return renderHeader(cellIndex, cellData);
                                        })
                                    }
                                </View>
                                :null
                            }
                            {
                                data.slice(1).map((rowData, rowIndex) => {
                                    if(rowIndex < (parseInt(page)-1)*parseInt(pageLength) || rowIndex >= ((parseInt(page)-1)*parseInt(pageLength) + parseInt(pageLength)))
                                        return <></>
                                    return (
                                        <View key={rowIndex} style={{display: 'flex', flexDirection: 'row'}}>
                                            {
                                                rowData.map((cellData, cellIndex) => {
                                                    if(cellIndex >= 2)
                                                        return <></>;
                                                    return render(rowIndex, cellIndex, cellData, rowData[9], rowData[1], rowData[2], rowData[3]);
                                                })
                                            }
                                        </View>
                                    )
                                })
                            }
                        </View>
                        <ScrollView horizontal ref={tableRef}>
                            <View style={{marginRight: 20, borderTopRightRadius: 8, borderBottomRightRadius: 8, overflow: 'hidden'}}>
                                {
                                    data.length>0?
                                    <View key={0} style={{display: 'flex', flexDirection: 'row'}}>
                                        {
                                            data[0].map((cellData, cellIndex) => {
                                                if(cellIndex < 2)
                                                    return <></>;
                                                return renderHeader(cellIndex, cellData);
                                            })
                                        }
                                    </View>
                                    :null
                                }
                                {
                                    data.slice(1).map((rowData, rowIndex) => {
                                        if(rowIndex < (parseInt(page)-1)*parseInt(pageLength) || rowIndex >= ((parseInt(page)-1)*parseInt(pageLength) + parseInt(pageLength)))
                                            return <></>
                                        return (
                                            <View key={rowIndex} style={{display: 'flex', flexDirection: 'row'}}>
                                                {
                                                    rowData.map((cellData, cellIndex) => {
                                                        if(cellIndex < 2)
                                                            return <></>;
                                                        return render(rowIndex, cellIndex, cellData, rowData[9], rowData[1], rowData[2], rowData[3]);
                                                    })
                                                }
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </ScrollView>
                    </View>
                    <View style={[styles.even, css.s, styles.cell, {textAlign: 'center', display: data.length==1?'flex':'none'}]}>
                        <MyText style={{textAlign: 'center'}}>
                            {initialLoadDone?'No Users found':'Loading...'}
                        </MyText>
                    </View>
                    {
                        data?.length-1 > 0 ?
                        <View style={{display: 'flex', flexDirection: 'row', marginRight: 20, marginTop: 10, marginBottom: 10, justifyContent: 'space-between'}}>
                            <MyText>
                                Showing <MyText style={[css.bold, {color: '#461b6f'}]}>{(page-1)*pageLength+1}</MyText> to <MyText style={[css.bold, {color: '#461b6f'}]}>{Math.min((parseInt(page)-1)*parseInt(pageLength) + parseInt(pageLength), data.length-1)}</MyText> of <MyText style={[css.bold, {color: '#461b6f'}]}>{data?.length-1}</MyText> users
                            </MyText>
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <TouchableOpacity onPress={() => page-1>=1?setPage((parseInt(page)-1).toString()):false}>
                                    <AntDesign name="left" size={18} color="#461b6f" />
                                </TouchableOpacity>
                                <View style={[{width: 50, marginVertical: 0, marginHorizontal: 10}]}>
                                    <MyPicker
                                        style={css.formRowInput}
                                        items={[...Array(Math.ceil(data?.length/pageLength))].map((_, i) => (i+1).toString())}
                                        onValueChange={(item, itemIndex) => {
                                                setPage(item.value);
                                            }
                                        }
                                        value={page}
                                        placeholder={'Page'}
                                        fancyPlaceholder={true}
                                    />
                                </View>
                                <TouchableOpacity onPress={() => parseInt(page)+1<=Math.ceil(data?.length/parseInt(pageLength))?setPage((parseInt(page)+1).toString()):false}>
                                    <AntDesign name="right" size={18} color="#461b6f" />
                                </TouchableOpacity>
                            </View>
                        </View>
                        :null
                    }
                </View>
            </View>
        </KeyboardAwareScrollView>
        <MyReasonModal enabled={modalOpen} body='Please provide a reason for the User Rejection' close={() => setModalOpen(false)} callback={modalCallback}/>
    </>
        
  );
}

const styles = StyleSheet.create({
    filterButton: {
        marginRight: 10,
        marginBottom: 10,
        flexBasis: 125,
        flexGrow: 0,
        flexShrink: 0,
        // justifyContent: 'center',
        // alignItems: 'center'
    },
    fullFilterButton: {
        marginRight: 10,
        marginBottom: 10,
        flexBasis: 260,
        flexGrow: 0,
        flexShrink: 0
    },
    text: {
        padding: 10,
    },
    row: {
        flexDirection: 'row',
    },
    header: {
        backgroundColor: '#461b6f',
    },
    headerText: {
        color: 'white'
    },
    cell: {
        paddingVertical: 5,
        paddingHorizontal: 10,
        height: Platform.OS==='web'?55:45,
        borderWidth: 1,
        borderColor: 'white',
        display: 'flex',
        justifyContent: 'center'
    },
    even: {
        backgroundColor: '#ececec'
    },
    odd: {
        backgroundColor: '#F6F4F9'
    },
    statusAction: {
        width: 30,
        textAlign: 'center',
        paddingTop: 0,
        paddingBottom: 4,
        borderRadius: 5,
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
        overflow: 'hidden',
    }
});

export default ManageUsers
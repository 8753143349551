import React from 'react';
import { StyleSheet, Text, View, ScrollView, TextInput, TouchableWithoutFeedback, Platform } from 'react-native';
import MyText from './MyText';

import stylesheet from './Style';
import { FontAwesome } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';

import * as FileSystem from 'expo-file-system';
const { StorageAccessFramework } = FileSystem;
import FileViewer from "react-native-file-viewer";
import * as Sharing from 'expo-sharing';
import * as Haptics from 'expo-haptics';
import MyDocumentModal from './MyDocumentModal';

import * as db from './database';
import { useModal } from './hooks/modal';
import { RefreshControl } from 'react-native';

const Documents = (props) => {
    const css = stylesheet();
    const modal = useModal();
    
    const [documents, setDocuments] = React.useState([]);
    const [webDocuments, setWebDocuments] = React.useState([]);

    const [refreshing, setRefreshing] = React.useState(true);

    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        getFiles();
    }, []);

    const getFiles = async () => {
        try{
            if(Platform.OS != 'web'){
                let documents = await FileSystem.readDirectoryAsync(FileSystem.documentDirectory + 'Documents/'+props.user.id);
                let documentsMetadata = [];

                let documentsLoop = new Promise((resolve, reject) => {
                    if(documents.length === 0)
                        resolve();
                    documents.forEach(async(doc, idx) => {
                        documentsMetadata.push(await FileSystem.getInfoAsync(FileSystem.documentDirectory + 'Documents/'+props.user.id + '/' + doc));
                        if (idx === documents.length -1) resolve();
                    })
                });
                
                documentsLoop.then(() => {
                    documentsMetadata.sort((a,b) => b.modificationTime - a.modificationTime);
                    setDocuments(documentsMetadata);
                });
            }
            else{
                let documents = await db.getDocuments(props.user.id);
                documents.sort((a,b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());
                setWebDocuments(documents);
            }
        }
        finally {
            setRefreshing(false);
        }
    }
    React.useEffect(() => {
        getFiles().catch(console.error);
    },[]);

    const Clicked = (uri) => {
        FileViewer.open(uri);
    }

    const LongClicked = (uri, name, id) => {
        modal.document.show(uri, name, id, () => {
            getFiles();
        });
        // setUri(uri);
        // setName(name);
        // setId(id);
        // setModalOpen(true);
        Haptics.impactAsync();
    }

    const Document = ({document}) => {
        const name = document.uri.split('/').pop();
        let extension = name.split('.').pop();
        let iconName = 'file-o';
        switch(extension){
            case 'pdf':
                iconName = 'file-pdf-o';
                break;
            case 'xlsx':
                iconName = 'file-excel-o';
                break;
            case 'docx':
                iconName = 'file-word-o';
                break;
        }
        return (
            <TouchableOpacity onPress={() => Clicked(document.uri)} onLongPress={() => LongClicked(document.uri, name)} delayLongPress={300}>
                <View style={{alignItems: 'center', width: 80, flexDirection: 'column'}}>
                    <FontAwesome name={iconName} size={50} color='#461b6f' />
                    <MyText style={{marginTop: 10, flex: 1, textAlign: 'center'}}>
                        {name}
                    </MyText>
                </View>
            </TouchableOpacity>
        )
    }

    const WebDocument = ({document}) => {
        const name = document.name;
        let extension = name.split('.').pop();
        let iconName = 'file-o';
        switch(extension){
            case 'pdf':
                iconName = 'file-pdf-o';
                break;
            case 'xlsx':
                iconName = 'file-excel-o';
                break;
            case 'docx':
                iconName = 'file-word-o';
                break;
        }
        return (
            <TouchableOpacity onPress={() => LongClicked(document.file, name, document.id)}>
                <View style={{alignItems: 'center', width: 80, flexDirection: 'column'}}>
                    <FontAwesome name={iconName} size={50} color='#461b6f' />
                    <MyText style={{marginTop: 10, flex: 1, textAlign: 'center'}}>
                        {name}
                    </MyText>
                </View>
            </TouchableOpacity>
        )
    }

  return (
    <>
        <ScrollView overScrollMode='never' vertical refreshControl={Platform.OS==='web'?undefined:<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>} nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} removeClippedSubviews={true} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
            <View style={[css.background, {paddingHorizontal: 0, paddingTop: 20, marginBottom: 20}]}>
                <MyText style={[css.title, css.bold, {paddingLeft: 20}]}>
                    Documents
                </MyText>
                <View style={{paddingHorizontal: 20, marginTop: 30, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: 30}}>
                    {
                        documents.map((document, idx) => {
                            return <Document key={idx} document={document} />
                        })
                    }
                    {
                        webDocuments.map((document, idx) => {
                            return <WebDocument key={idx} document={document} />
                        })
                    }
                </View>
            </View>
        </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({

});

export default React.memo(Documents)
import React, { FunctionComponent } from 'react'
import { Animated, StyleSheet, Text, View, TextInput, Dimensions, Platform, useWindowDimensions } from 'react-native';
import MyText from './MyText';
import { Video } from 'expo-av';
//<Video source={require("./assets/intro.mp4")}

const Splash = (props) => {
    const {height, width} = useWindowDimensions();

    const [finished, setFinished] = React.useState(Platform.OS==='web');
    if(Platform.OS==='web'){
        props.finished();
    }
    const [done, setDone] = React.useState(false);

    const fadeAnim = React.useRef(new Animated.Value(Platform.OS==='web'?(-width-1):0)).current;

    const fadeIn = () => {
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true,
        }).start();
    };

    const fadeOut = () => {
        Animated.timing(fadeAnim, {
            toValue: -width-1,
            duration: 500,
            useNativeDriver: true,
        }).start();
    };

    React.useEffect(() => {
        if(props.done){
            setTimeout(() => {
                fadeOut();
                setTimeout(() => {
                    setDone(true);
                },600);
            },100);
        }
        else{
            if(Platform.OS !== 'web')
                fadeIn();
        }
    },[props.done]);

    return (
        <>
        {
            done?null:
            <Animated.View style={[styles.videoView, {transform: [{translateX: fadeAnim}]}]}>
                <Video 
                    source={Platform.OS==='web'?require("./assets/intro_web.mp4"):require("./assets/newIntro.mp4")}
                    rate={1.0}
                    resizeMode={'cover'}
                    shouldPlay={Platform.OS!=='web'}
                    isMuted={true}
                    onPlaybackStatusUpdate={(e) => {
                        if(e.positionMillis >= (Platform.OS==='web'?0:3500)){
                            if(!finished){
                                setFinished(true);
                                props.finished();
                            }
                        }
                    }}
                    // isLooping
                    videoStyle={Platform.OS==='web'?styles.videoStyle:null}
                    style={styles.videoView}
                />
            </Animated.View>
        }
        </>
    )
}
const styles = StyleSheet.create({
    videoView: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: '#64348E',
        // flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    videoStyle: {
        width: '100%',
        height: '100%',
        resizeMode: 'cover'
        // position: 'relative'
    },
})
export default Splash
import React from 'react'
import { StyleSheet, View, Linking, TouchableOpacity, Platform } from 'react-native';
import MyText from './MyText';
import stylesheet from './Style'
import { useModalStore } from './hooks/modal';

const MyModal = () => {
    const css = stylesheet();
    const modal = useModalStore();
    
    const parseBody = (text) => {
        let textArray = text.split(' ');
        let newTextArray = [];
        textArray.forEach((str, idx) => {
            if (/support@mdata.gr/.test(str)) {
                newTextArray.push(<MyText onPress={() => Linking.openURL('mailto:support@mdata.gr')} style={{color: 'blue', textDecorationLine: 'underline'}}>{str}</MyText>);
            }
            else if (/\$android/.test(str)) {
                newTextArray.push(<MyText onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=gr.mdata.footprint')} style={{color: 'blue', textDecorationLine: 'underline'}}>Android</MyText>);
            }
            else if (/\$ios/.test(str)) {
                newTextArray.push(<MyText onPress={() => Linking.openURL('https://apps.apple.com/us/app/application-footprint/id6446500693')} style={{color: 'blue', textDecorationLine: 'underline'}}>iOS</MyText>);
            }
            else{
                newTextArray.push(str);
            }
            if(idx != textArray.length-1)
                newTextArray.push(' ');
        });
        return newTextArray;
    };

    // let bodySupport = body.split('support@mdata.gr')[0];
    // bodySupport += body.split('support@mdata.gr').length > 1?
    //                 <MyText onPress={() => Linking.openURL('mailto:support@mdata.gr')} style={{color: 'blue', textDecorationLine: 'underline'}}>support@mdata.gr</MyText>
    //             :
    //             '';
    // bodySupport += body.split('support@mdata.gr')[1]??'';

    // let bodyAndroid = bodySupport.split('$android')[0];
    // bodyAndroid += bodySupport.split('$android').length > 1?
    //                 <MyText onPress={() => Linking.openURL('mailto:support@mdata.gr')} style={{color: 'blue', textDecorationLine: 'underline'}}>support@mdata.gr</MyText>
    //             :
    //             '';
    // bodyAndroid += bodySupport.split('$android')[1]??'';
    return (
        <>
            {modal.confirm.visible &&
                <View style={styles.bg}>
                    <View style={styles.modal}>
                        <View style={styles.header}>
                            <MyText style={[css.l, {color: '#461b6f'}, css.bold]}>
                                {modal.confirm.header}
                            </MyText>
                        </View>
                        <MyText style={modal.confirm.header.trim() == '' ? styles.onlyBody : styles.body}>
                            {parseBody(modal.confirm.body)}
                        </MyText>
                        <View style={styles.footer}>
                            { !modal.confirm.callback &&
                                <TouchableOpacity onPress={modal.confirm.hide} style={{alignItems: 'center', marginBottom: 0}}>
                                    <MyText style={[{paddingVertical: 10, paddingHorizontal: 30, backgroundColor: '#461b6f', color: 'white', borderRadius: 12, overflow: 'hidden'}, css.bold]}>
                                        OK
                                    </MyText>
                                </TouchableOpacity>
                            }
                            { modal.confirm.callback &&
                                <>
                                    <TouchableOpacity onPress={() => {modal.confirm.hide(); modal.confirm.callback();}} style={{alignItems: 'center', marginBottom: 0}}>
                                        <MyText style={[{paddingVertical: 10, paddingHorizontal: 30, backgroundColor: '#461b6f', color: 'white', borderRadius: 12, marginRight: 15, overflow: 'hidden'}, css.bold]}>
                                            Yes
                                        </MyText>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={modal.confirm.hide} style={{alignItems: 'center', marginBottom: 0}}>
                                        <MyText style={[{paddingVertical: 10, paddingHorizontal: 30, backgroundColor: '#461b6f', color: 'white', borderRadius: 12, overflow: 'hidden'}, css.bold]}>
                                            No
                                        </MyText>
                                    </TouchableOpacity>
                                </>
                            }
                        </View>
                    </View>
                </View>
            }
        </>
    );
}
const styles = StyleSheet.create({
    bg: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        paddingTop: Platform.OS==='web'?64:94,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: Platform.OS==='web'?'100dvh':'auto',
        position: Platform.OS==='web'?'fixed':'absolute'
    },
    modal: {
        backgroundColor: 'white',
        borderRadius: 8,
        overflow: 'hidden',
        width: 300,
        maxWidth: '95%',
    },
    header: {
        padding: 10,
        alignItems: 'center'
    },
    body: {
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
        padding: 15,
        paddingBottom: 5,
        alignItems: 'center',
        textAlign: 'center',
        lineHeight: 20
    },
    onlyBody: {
        paddingHorizontal: 15,
        paddingBottom: 5,
        alignItems: 'center',
        textAlign: 'center',
        lineHeight: 20
    },
    footer: {
        padding: 10,
        // flex: 1,
        // display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }
})

export default MyModal
import { DateTimePicker, DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput, TouchableOpacity } from 'react-native';
import { EvilIcons, Entypo } from '@expo/vector-icons'; 
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import stylesheet from './Style'
import MyTextarea from './MyTextarea';

const MyReasonModal = (props) => {
    const css = stylesheet();
    
    const [reason, setReason] = React.useState('');

    return (
        <>
            {props.enabled &&
                <View style={styles.bg}>
                    <View style={styles.modal}>
                        <View style={styles.header}>
                            <MyText style={[css.l, {color: '#461b6f'}, css.bold]}>
                                Reject
                            </MyText>
                        </View>
                        <MyText style={styles.body}>
                            {props.body}
                        </MyText>
                        <View style={{paddingHorizontal: 15, paddingVertical: 5}}>
                            <MyTextarea onChangeText={setReason}/>
                        </View>
                        <View style={styles.footer}>
                            { props.callback &&
                                <>
                                    <TouchableOpacity onPress={() => {props.close(); props.callback(reason);}} style={{alignItems: 'center', marginBottom: 0}}>
                                        <MyText style={[{paddingVertical: 10, paddingHorizontal: 30, backgroundColor: '#461b6f', color: 'white', borderRadius: 12, marginRight: 15, overflow: 'hidden'}, css.bold]}>
                                            Submit
                                        </MyText>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={props.close} style={{alignItems: 'center', marginBottom: 0}}>
                                        <MyText style={[{paddingVertical: 10, paddingHorizontal: 30, backgroundColor: '#461b6f', color: 'white', borderRadius: 12, overflow: 'hidden'}, css.bold]}>
                                            Cancel
                                        </MyText>
                                    </TouchableOpacity>
                                </>
                            }
                        </View>
                    </View>
                </View>
            }
        </>
    );
}
const styles = StyleSheet.create({
    bg: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modal: {
        backgroundColor: 'white',
        borderRadius: 8,
        width: 300,
        maxWidth: '95%',
        overflow: 'hidden'
    },
    header: {
        padding: 10,
        alignItems: 'center'
    },
    body: {
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
        borderBottomColor: '#dee2e6',
        padding: 15,
        paddingBottom: 5,
        alignItems: 'center',
        textAlign: 'center'
    },
    footer: {
        padding: 10,
        // flex: 1,
        // display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }
})
export default MyReasonModal
import React, { FunctionComponent } from 'react'
import {
  Text,
  StyleSheet
} from 'react-native'
import stylesheet from './Style'

const MyText = (props) => {
  const css = stylesheet();
  
  return (
    <Text {...props} onPress={props.onPress} style={[css.sm, {fontFamily: 'Comfortaa-Regular', color: '#474747'}, props.style]}>
      {props.children}
    </Text>
  )
}
const styles = StyleSheet.create({
})
export default MyText
import React from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    ScrollView,
    useWindowDimensions,
    Platform
  } from 'react-native'
import stylesheet from './Style'
import MyText from './MyText';
import MyDatePicker from './MyDatePicker';
import MyTextInput from './MyTextInput';
import { MaterialIcons, Feather } from '@expo/vector-icons'; 

import axios from 'axios';

import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from '@react-native-community/netinfo';
import { useModal } from './hooks/modal';
import { RefreshControl } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

let filteredData = [];

const tableHeader = ['Title', 'Message', 'Related\nRequest', 'Received at', 'Status', 'Actions'];

const MyNotifications = (props) => {
    const css = stylesheet();
    const modal = useModal();

    const {height, width} = useWindowDimensions();
    const sizes = width>=768?
    [55, 120, 230, 135, 145, 90, 85]:
    [45, 110, 220, 125, 135, 80, 75];

    const pageRef = React.useRef();
    const tableRef = React.useRef();

    const [from, setFrom] = React.useState(null);
    const [to, setTo] = React.useState(null);
    const [type, setType] = React.useState('all');
    const [searchText, setSearchText] = React.useState('');
    const [sort, setSort] = React.useState({column: 3, ascending: false}); //# column does not count, 0 is the ID

    const [tableData, setTableData] = React.useState([]);
    const [data, setData] = React.useState(tableData);

    const [initialLoadDone, setInitialLoadDone] = React.useState(false);

    const [refreshing, setRefreshing] = React.useState(true);

    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        GetTableData();
    }, []);

    const SetNotificationData = async(data) => {
        let readNotifications = JSON.parse(await AsyncStorage.getItem('read-notifications')??'{}');
        let deletedNotifications = JSON.parse(await AsyncStorage.getItem('deleted-notifications')??'{}');
        let result = [];
        data.forEach(row => {
            if(!deletedNotifications[row.id]){ //If it doesn't exist in the deleted notifications, show it
                let hasRead = false;
                if(readNotifications[row.id]){
                    hasRead = true;
                }
                readNotifications[row.id] = true; //The notification page was opened, so the notification is marked as read
                result.push([row.data.title, row.data.message, row.data.additionalData?.id??'', row.received_at, hasRead?'OPENED':'NEW', row.id]);
            }
        })
        setTableData(result);
        setRefreshing(false);
        setTimeout(() => {
            //Just to make sure the table data has time to load
            setInitialLoadDone(true);
        },1000)
        await AsyncStorage.setItem('read-notifications', JSON.stringify(readNotifications));
        await AsyncStorage.setItem('notifications', JSON.stringify(data));
        props.opened();
    }
    const GetTableData = async() => {
        NetInfo.fetch().then(async(state) => {
            if(!state.isConnected){
                try{
                    let notifications = JSON.parse(await AsyncStorage.getItem('notifications')??'[]');
                    SetNotificationData(notifications);
                }
                catch(e){}
                return;
            }
            axios.get('/api/getNotifications.php', {
                params: {
                    uuid: await AsyncStorage.getItem('uuid')
                }
            })
            .then(async (res) => {
                SetNotificationData(res.data);
            })
        });
    }
    React.useEffect(() => {
        const unsubscribe = props.navigation.addListener('focus', () => {
            // if(pageRef){
            //     pageRef.current.scrollTo({
            //         x: 0,
            //         y: 0,
            //         animated: false,
            //     });
            // }
            // if(tableRef){
            //     tableRef.current.scrollTo({
            //         x: 0,
            //         y: 0,
            //         animated: false,
            //     });
            // }
            GetTableData();
        });
        return unsubscribe;
      }, []);

    React.useEffect(() => {
        FilterData();
    }, [type, searchText, tableData, from, to]);

    React.useEffect(() => {
        SortData();
    }, [sort]);

    const FormatDatetime = (dt) => {
        if(!dt)
            return '';
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const padLeft = (num) => {
            if(num < 10)
                return '0'+num;
            return num;
        }
        const date = new Date(dt);
        return padLeft(date.getDate()) + '-' + months[date.getMonth()] + '-' + date.getFullYear() + ' ' + padLeft(date.getHours()) + ':' + padLeft(date.getMinutes());
    }

    const Searched = (text) => {
        setSearchText(text);
    }

    const changeType = (type) => {
        setType(type);
    }

    const ChangeSort = (sortColumn) => {
        if(sortColumn > 0){
            setSort(prev => (
                {
                    column: sortColumn,
                    ascending: prev.column==sortColumn?(!prev.ascending):true
                }
            ));
        }
    }

    const Delete = async(id) => {
        modal.confirm.show("Warning", "Are you sure you want to Delete this notification?", async() => {
            let deletedNotifications = JSON.parse(await AsyncStorage.getItem('deleted-notifications')??'{}');
            if(!deletedNotifications[id])
                deletedNotifications[id] = true;
            await AsyncStorage.setItem('deleted-notifications', JSON.stringify(deletedNotifications));
            GetTableData();
        });
    }
    
    const Bullet = (bulletProps) => {
        const orange = '#efa270';
        const yellow = '#fed354';
        const blue = '#1aa0eb';
        const green = '#23a43f';
        const red = '#e32112';
        let color = '';
        let text = bulletProps?.text?.toString()??'';

        if(bulletProps.cellIndex == 6 && bulletProps.rowIndex > 0){
            return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <TouchableOpacity onPress={() => props.navigation.push('Request Info', { id: bulletProps.relatedRequest })}>
                    <MaterialIcons name="visibility" size={18} color="#461b6f" style={{marginRight: 8}} />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Delete(bulletProps.id)}>
                    <Feather name="x" size={18} color="#b10002" style={{marginRight: 10}}/>
                </TouchableOpacity>
            </View>;
        }
        if(!text){
            return <></>;
        }
        if(text.startsWith('Submitted') || text.startsWith('Validated')){
            color = orange;
        }
        else if(text.startsWith('Route') || text.startsWith('Driver')){
            color = yellow;
        }
        else if(text.startsWith('Waste')){
            color = blue;
        }
        else if(text.startsWith('Certificate')){
            color = green;
        }
        else if(text.startsWith('Cancel')){
            color = red;
        }
        let textBefore = text.split('Click here')[0];
        let textAfter = text.split('Click here')[1];
        if(bulletProps.rowIndex > 0 && (bulletProps.cellIndex == 4) ){
            text = <><MyText style={{fontSize: 0.001, color: 'transparent'}}>{text}</MyText>{FormatDatetime(text)}</>;
        }
        if(textAfter){ //Means we were able to split
            text = <>{textBefore}Click <MyText style={[css.s, {textDecorationLine: 'underline', color: '#461b6f'}, css.bold]}>here</MyText>{textAfter}</>;
        }
        
        if(bulletProps.rowIndex == 0){
            return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <MyText style={[styles.headerText, css.bold, css.s]}>{text}</MyText>
                    {
                        sort.column+1 == bulletProps.cellIndex &&
                        <View style={{position: 'absolute', right: -10}}>
                            <MaterialIcons style={{top: 0}} name={"keyboard-arrow-up"} size={18} color={sort.ascending?'white':'#461b6f'} />
                            <MaterialIcons style={{marginTop: -10}} name={"keyboard-arrow-down"} size={18} color={!sort.ascending?'white':'#461b6f'} />
                        </View>
                    }
                    {
                        sort.column+1 != bulletProps.cellIndex && bulletProps.cellIndex != 0 && (
                        <View style={{position: 'absolute', right: -10}}>
                            <MaterialIcons style={{top: 0}} name={"keyboard-arrow-up"} size={18} color="#d1d1d1" />
                            <MaterialIcons style={{marginTop: -10}} name={"keyboard-arrow-down"} size={18} color="#d1d1d1" />
                        </View>
                        )
                    }
                    </View>;
        }
        if(bulletProps.rowIndex > 0 && (bulletProps.cellIndex == 1 || bulletProps.cellIndex == 2)){
            return <MyText onPress={() => props.navigation.push('Request Info', { id: bulletProps.relatedRequest })} style={[css.s, bulletProps.isNew?css.bold:css.normal]}>{text}</MyText>;
        }
        return <MyText style={[css.s, bulletProps.isNew?css.bold:css.normal]}>{text}</MyText>;
    }

    const render = (rowIndex, cellIndex, data, rowData) => {
        const totalTableWidth = sizes.reduce((partialSum, a) => partialSum + a, 0);
        let cellWidth = sizes[cellIndex];
        if(totalTableWidth < width-230-40){ //table fits without scrolling
            cellWidth = sizes[cellIndex]*((width-230-40)/totalTableWidth);
        }
        const id = rowData[6];
        if(rowIndex == 0)
            return (
                <TouchableOpacity onPress={() => ChangeSort(cellIndex-1)}>
                    <View key={cellIndex} style={[css.s, styles.cell, styles.header, {width: cellWidth}]}>
                        <Bullet rowIndex={rowIndex} cellIndex={cellIndex} text={data}/>
                    </View>
                </TouchableOpacity>
            );
        return <View key={cellIndex} style={[rowIndex%2?styles.even:styles.odd, css.s, styles.cell, {width: cellWidth}]}><Bullet rowIndex={rowIndex} cellIndex={cellIndex} id={id} text={data} isNew={rowData[5]==='NEW'} relatedRequest={rowData[3]}/></View>;
    }

    const FilterData = () => {
        filteredData = [];
        tableData.forEach((row, rowIndex) => {
            let addedRow = false;
            let fromDate = new Date();
            let toDate = new Date();
            if(from){
                fromDate = new Date(from);
                fromDate.setHours(0);
                fromDate.setMinutes(0);
            }
            if(to){
                toDate = new Date(to);
                toDate.setHours(23);
                toDate.setMinutes(59);
            }
            if(from && !to){
                if(new Date(row[3]).getTime() < fromDate.getTime())
                    return;
            }
            else if(!from && to){
                if(new Date(row[3]).getTime() > toDate.getTime())
                    return;
            }
            else if(from && to){
                if(new Date(row[3]).getTime() < fromDate.getTime() || new Date(row[3]).getTime() > toDate.getTime())
                    return;
            }
            row.forEach((cell, cellIndex) => {
                if(addedRow)
                    return;
                if(cell.toString().toLowerCase().includes(searchText.toLowerCase())){
                    let hasFilter = false;
                    if(type == 'all'){
                        hasFilter = true;
                    }
                    else if(type == 'unread'){
                        if(row[4] == 'NEW'){
                            hasFilter = true;
                        }
                    }
                    else if(type == 'opened'){
                        if(row[4] == 'OPENED'){
                            hasFilter = true;
                        }
                    }
                    if(hasFilter){
                        filteredData.push(row);
                        addedRow = true;
                    }
                    return;
                }
            })
        })
        SortData();
    }

    const SortData = () => {
        filteredData.sort((a, b) => {
            if (a[sort.column].toString() < b[sort.column].toString()) return sort.ascending ? -1 : 1;
            if (a[sort.column].toString() > b[sort.column].toString()) return sort.ascending ? 1 : -1;
            return 0;
        });
        let numbered = [['#', ...tableHeader]];
        filteredData.forEach((row, idx) => {
            numbered.push([(idx+1).toString(), ...row]);//.unshift(idx.toString()));
        })
        setData(numbered);
    }

  return (
    <KeyboardAwareScrollView vertical ref={pageRef} refreshControl={Platform.OS==='web'?undefined:<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>} nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
        <View style={[css.background, {paddingRight: 0, paddingTop: 20}]}>
            <MyText style={[css.title, css.bold]}>
                My Notifications
            </MyText>
            {/* <MyText style={css.subtitle}>
                Date
            </MyText> */}
            <View style={{marginTop: 15}}>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{marginRight: 7.5, flexBasis: 127.5, flexGrow: 0, flexShrink: 0}}>
                        <MyDatePicker
                            onChange={setFrom}
                            value={from}
                            placeholder='From'
                        />
                    </View>
                    <View style={{flex: 1, flexBasis: 127.5, flexGrow: 0, flexShrink: 0}}>
                        <MyDatePicker
                            onChange={setTo}
                            value={to}
                            placeholder='To'
                        />
                    </View>
                </View>
            </View>
            <View style={{marginTop: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <View style={styles.filterButtonBig}>
                    <TouchableOpacity onPress={() => changeType('all')}>
                        <View style={[css.smallButton, {backgroundColor: type=='all'?'#461b6f':'white'}]}>
                            <MyText style={[css.bold, css.smallButtonText, {color: type=='all'?'white':'#461b6f'}]}>
                                All Notifications
                            </MyText>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.filterButton}>
                    <TouchableOpacity onPress={() => changeType('unread')}>
                        <View style={[css.smallButton, {backgroundColor: type=='unread'?'#461b6f':'white'}]}>
                            <MyText style={[css.bold, css.smallButtonText, {color: type=='unread'?'white':'#461b6f'}]}>
                                Unread
                            </MyText>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.filterButton}>
                    <TouchableOpacity onPress={() => changeType('opened')}>
                        <View style={[css.smallButton, {backgroundColor: type=='opened'?'#461b6f':'white'}]}>
                            <MyText style={[css.bold, css.smallButtonText, {color: type=='opened'?'white':'#461b6f'}]}>
                                Opened
                            </MyText>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', marginRight: 10, marginTop: 0, marginBottom: 10}}>
                {/* <MyText style={[{lineHeight: 33, marginRight: 10}, css.bold]}>Search</MyText> */}
                <MyTextInput icon='search' onChangeTextImmediate={Searched} onChangeText={() => false} value={searchText} placeholder='Search...' style={{flexGrow: 0, flexShrink: 0, flexBasis: 232}}/>
            </View>
            <View style={{marginBottom: 50}}>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{flexShrink: 0, flexGrow: 0, borderTopLeftRadius: 8, borderBottomLeftRadius: 8, overflow: 'hidden'}}>
                        {
                            data.map((rowData, rowIndex) => {
                                return (
                                    <View key={rowIndex} style={{display: 'flex', flexDirection: 'row'}}>
                                        {
                                            rowData.map((cellData, cellIndex) => {
                                                if(cellIndex >= 2)
                                                    return <></>;
                                                return render(rowIndex, cellIndex, cellData, rowData);
                                            })
                                        }
                                    </View>
                                )
                            })
                        }
                    </View>
                    <ScrollView horizontal ref={tableRef}>
                        <View style={{marginRight: 20, borderTopRightRadius: 8, borderBottomRightRadius: 8, overflow: 'hidden'}}>
                            {
                                data.map((rowData, rowIndex) => {
                                    return (
                                        <View key={rowIndex} style={{display: 'flex', flexDirection: 'row'}}>
                                            {
                                                rowData.map((cellData, cellIndex) => {
                                                    if(cellIndex < 2)
                                                        return <></>;
                                                    return render(rowIndex, cellIndex, cellData, rowData);
                                                })
                                            }
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </ScrollView>
                </View>
                <View style={[styles.even, styles.cell, {textAlign: 'center', display: data.length==1?'flex':'none'}]}>
                    <MyText style={{textAlign: 'center'}}>
                        {initialLoadDone?'No notifications found':'Loading...'}
                    </MyText>
                </View>
            </View>
        </View>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
    filterButton: {
        marginRight: 10,
        marginBottom: 10,
        flexBasis: 125,
        flexGrow: 0,
        flexShrink: 0
    },
    filterButtonBig: {
        marginRight: 10,
        marginBottom: 10,
        flexBasis: 260,
        flexGrow: 0,
        flexShrink: 0
    },
    text: {
        padding: 10,
    },
    row: {
        flexDirection: 'row',
    },
    header: {
        backgroundColor: '#461b6f',
        height: Platform.OS==='web'?55:45
    },
    headerText: {
        color: 'white'
    },
    cell: {
        paddingVertical: 5,
        paddingHorizontal: 10,
        height: 80,
        borderWidth: 1,
        borderColor: 'white',
        display: 'flex',
        justifyContent: 'center'
    },
    even: {
        backgroundColor: '#ececec'
    },
    odd: {
        backgroundColor: '#F6F4F9'
    }
});

export default MyNotifications
import React from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    ScrollView,
    TouchableWithoutFeedback,
    ActivityIndicator,
    TouchableNativeFeedback,
    Image,
    Dimensions,
    Keyboard,
    Platform,
    TextInput
  } from 'react-native'
const height = Dimensions.get('window').height;
import stylesheet from './Style'
import PRF from './assets/icons/PRF.svg';
import IndustrialWaste from './assets/icons/IndustrialWaste.svg';
import MySVG from './assets/MySVG';
import MyText from './MyText';
import Login from './Login';
import { ImageBackground } from 'react-native';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import { useModal } from './hooks/modal';

export default function LandingPage(props) {
    const css = stylesheet();
    const modal = useModal();
    
    const [type, setType] = React.useState('PRF');
    const [isLogin, setIsLogin] = React.useState(false);
    const [isForgotPassword, setIsForgotPassword] = React.useState(false);
    const [keyboardStatus, setKeyboardStatus] = React.useState(false);

    const Management = () => (
        <View style={{padding: 20, flex: 1}}>
            <View style={{flex: 1, justifyContent: 'center'}}>
                <MyText style={[css.xxl, {color: '#461b6f'}, css.bold]}>{type=='PRF'?'PRF Management Platform':'Industrial Waste Management'}</MyText>
                <View style={{flexDirection: 'row', marginTop: 80}}>
                    <View style={{flex: 1, alignItems: 'center'}}>
                        <TouchableOpacity onPress={() => setIsLogin(true)} style={{width: 130}}>
                            <MyText style={[css.button, css.bold]}>
                                Login
                            </MyText>
                        </TouchableOpacity>
                        <View style={{flexGrow: 0, flexShrink: 0, marginTop: 10}}>
                            <MyText style={[css.bold, css.s, {color: '#461b6f'}]}>
                                Already Registered
                            </MyText>
                        </View>
                    </View>
                    <View style={{flex: 1, alignItems: 'center'}}>
                        <TouchableOpacity onPress={() => props.navigation.push('Register')} style={{width: 130}}>
                            <MyText style={[css.button, css.bold]}>
                                Register
                            </MyText>
                        </TouchableOpacity>
                        <View style={{flexGrow: 0, flexShrink: 0, marginTop: 10}}>
                            <MyText style={[css.bold, css.s, {color: '#461b6f'}]}>
                                Join Our Innovating Platform
                            </MyText>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )

    const Industrial = () => {
        modal.confirm.show('Warning', 'This functionality is not yet available.');
    }

    React.useEffect(() => {
        const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
          setKeyboardStatus(true);
        });
        const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
          setKeyboardStatus(false);
        });
    
        return () => {
          showSubscription.remove();
          hideSubscription.remove();
        };
      }, []);
      
    if(!props.autoLogin)
        return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <ActivityIndicator size="large" color="#461b6f" />
        </View>;
    return (
    <ScrollView vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
        {
            Platform.OS === 'web'?
            <>
                <ImageBackground source={type=='PRF'?require("./assets/icons/ship.png"):require("./assets/icons/industrialwaste.png")} resizeMode="cover" style={styles.webBackground}/>
                <View style={styles.webContainer}>
                    <View style={styles.webContainerView}>
                        <View style={styles.header}>
                            <TouchableOpacity onPress={() => setType('PRF')} style={[styles.tab, type=='PRF'?styles.tabSelected:null]}>
                                <MySVG source={PRF} style={styles.prf} resizeMode={'contain'} />
                                <MyText style={styles.label}>PRF</MyText>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => Industrial()/*setType('IndustrialWaste')*/} style={[styles.tab, type=='IndustrialWaste'?styles.tabSelected:null]}>
                                <MySVG source={IndustrialWaste} style={styles.industrialWaste} resizeMode={'contain'} />
                                <MyText style={styles.label}>Industrial Waste</MyText>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.content}>
                            {
                                props.action==='Reset'&&props.token?<ResetPassword navigation={props.navigation} token={props.token}/>:
                                (isLogin?
                                    isForgotPassword?
                                    <ForgotPassword navigation={props.navigation} Login={() => setIsForgotPassword(false)}/>
                                    :
                                    <Login navigation={props.navigation} setUser={props.setUser} isInfo={false} ForgotPassword={() => setIsForgotPassword(true)}/>
                                :
                                <Management/>)
                            }
                        </View>
                    </View>
                </View>
            </>
            :
            <>
                <View style={styles.header}>
                    <TouchableOpacity onPress={() => setType('PRF')} style={[styles.tab, type=='PRF'?styles.tabSelected:null]}>
                        <MySVG source={PRF} style={styles.prf} resizeMode={'contain'} />
                        <MyText style={styles.label}>PRF</MyText>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => Industrial()/*setType('IndustrialWaste')*/} style={[styles.tab, type=='IndustrialWaste'?styles.tabSelected:null]}>
                        <MySVG source={IndustrialWaste} style={styles.industrialWaste} resizeMode={'contain'} />
                        <MyText style={styles.label}>Industrial Waste</MyText>
                    </TouchableOpacity>
                </View>
                <View style={{flex: 1, flexDirection: 'column'}}>
                    <View style={{height: '44%', backgroundColor: 'white'}}>
                        {
                            props.action==='Reset'&&props.token?<ResetPassword navigation={props.navigation} token={props.token}/>:
                            (isLogin?
                                isForgotPassword?
                                <ForgotPassword navigation={props.navigation} Login={() => setIsForgotPassword(false)}/>
                                :
                                <Login navigation={props.navigation} setUser={props.setUser} isInfo={false} ForgotPassword={() => setIsForgotPassword(true)}/>
                            :
                            <Management/>)
                        }
                    </View>
                    <View style={{height: Platform.OS==='web'?'56%':((height / (100/56)) - (keyboardStatus?0:75)), alignItems: 'center', justifyContent: 'center', flex: 1}}>
                        <ImageBackground source={type=='PRF'?require("./assets/icons/ship.png"):require("./assets/icons/industrialwaste.png")} style={{width: '100%', height: '100%'}} resizeMode='cover'/>
                    </View>
                </View>
            </>
        }
    </ScrollView>
  );
}

const styles = StyleSheet.create({
    webBackground: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    webContainer: {
        flexDirection: 'row',
    },
    webContainerView: {
        width: 450,
        maxWidth: '90%',
        marginLeft: '5%',
        marginTop: 110,
        backgroundColor: '#f3f1f8e6'
    },
    header: {
        // flex: 1,
        flexDirection: 'row',
        backgroundColor: '#f3f1f8',
        height: 90,
        // justifyContent: 'center',
        // alignItems: 'center',
    },
    tab: {
        flexShrink: 0,
        flexGrow: 1,
        flexBasis: '50%',
        opacity: 0.4,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderColor: 'transparent',
    },
    tabSelected: {
        opacity: 1,
        borderColor: '#461b6f',
    },
    prf: {
        height: 25
    },
    industrialWaste: {
        height: 40,
    },
    label: {
        marginTop: 5,
        textAlign: 'center',
        // flexBasis: 30,
        // height: 30
    }
});

import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native';
import MyCheckbox from '../../MyCheckbox';
import MyText from '../../MyText';
import AnnexForm from '../AnnexForm';
import { AntDesign } from '@expo/vector-icons'; 
import stylesheet from '../../Style'
import { TouchableOpacity } from 'react-native-gesture-handler';

const AnnexVNonHazardous = (props) => {
    const css = stylesheet();
    
    const [collapsed, setCollapsed] = React.useState(true);

    const [plastic, setPlastic] = React.useState(props?.value?.plastic?.active??false);
    const [foodWaste, setFoodWaste] = React.useState(props?.value?.foodWaste?.active??false);
    const [domesticWaste, setDomesticWaste] = React.useState(props?.value?.domesticWaste?.active??false);
    const [cookingOil, setCookingOil] = React.useState(props?.value?.cookingOil?.active??false);
    const [incineratorAsh, setIncineratorAsh] = React.useState(props?.value?.incineratorAsh?.active??false);
    const [operationalWaste, setOperationalWaste] = React.useState(props?.value?.operationalWaste?.active??false);
    const [animalCarcasses, setAnimalCarcasses] = React.useState(props?.value?.animalCarcasses?.active??false);
    const [fishingGear, setFishingGear] = React.useState(props?.value?.fishingGear?.active??false);
    const [eWaste, setEWaste] = React.useState(props?.value?.eWaste?.active??false);
    const [cargo, setCargo] = React.useState(props?.value?.cargo?.active??false);
    const [other, setOther] = React.useState(props?.value?.other?.active??false);

    const [plasticData, setPlasticData] = React.useState(props?.value?.plastic?.data??{});
    const [foodWasteData, setFoodWasteData] = React.useState(props?.value?.foodWaste?.data??{});
    const [domesticWasteData, setDomesticWasteData] = React.useState(props?.value?.domesticWaste?.data??{});
    const [cookingOilData, setCookingOilData] = React.useState(props?.value?.cookingOil?.data??{});
    const [incineratorAshData, setIncineratorAshData] = React.useState(props?.value?.incineratorAsh?.data??{});
    const [operationalWasteData, setOperationalWasteData] = React.useState(props?.value?.operationalWaste?.data??{});
    const [animalCarcassesData, setAnimalCarcassesData] = React.useState(props?.value?.animalCarcasses?.data??{});
    const [fishingGearData, setFishingGearData] = React.useState(props?.value?.fishingGear?.data??{});
    const [eWasteData, setEWasteData] = React.useState(props?.value?.eWaste?.data??{});
    const [cargoData, setCargoData] = React.useState(props?.value?.cargo?.data??{});
    const [otherData, setOtherData] = React.useState(props?.value?.other?.data??{});

    const [hasChecked, setHasChecked] = React.useState(false);

    React.useEffect(() => {
        setCollapsed(!props.checked);
    }, [props.checked]);
    
    React.useEffect(() => {
        onChange();
    }, [plastic, foodWaste, domesticWaste, cookingOil, incineratorAsh, operationalWaste, animalCarcasses, fishingGear, eWaste, cargo, other,
        plasticData, foodWasteData, domesticWasteData, cookingOilData, incineratorAshData, operationalWasteData, animalCarcassesData, fishingGearData, eWasteData, cargoData, otherData]);

    const onChange = () => {
        props.onChange({
            plastic: {
                active: plastic,
                data: plasticData
            },
            foodWaste: {
                active: foodWaste,
                data: foodWasteData
            },
            domesticWaste: {
                active: domesticWaste,
                data: domesticWasteData
            },
            cookingOil: {
                active: cookingOil,
                data: cookingOilData
            },
            incineratorAsh: {
                active: incineratorAsh,
                data: incineratorAshData
            },
            operationalWaste: {
                active: operationalWaste,
                data: operationalWasteData
            },
            animalCarcasses: {
                active: animalCarcasses,
                data: animalCarcassesData
            },
            fishingGear: {
                active: fishingGear,
                data: fishingGearData
            },
            eWaste: {
                active: eWaste,
                data: eWasteData
            },
            cargo: {
                active: cargo,
                data: cargoData
            },
            other: {
                active: other,
                data: otherData
            },
        });
    }

    const Block = (check, setCheck, data, setData, label, ew) => {
        return (<>
            <View style={styles.row}>
                <MyCheckbox onClick={() => {setCheck(prev => !prev);}} checked={check} label={label} isInfo={props.isInfo}/>
            </View>
            {
                check && 
                <View style={[styles.row]}>
                    <AnnexForm ew={ew} wasteMetrics={props.wasteMetrics} onChange={setData} value={data} isInfo={props.isInfo}/>
                </View>
            }
        </>);
    }

    return (
        <View style={styles.annexContainer}>
            <TouchableOpacity activeOpacity={0.7} onPress={() => {setCollapsed(prev => !prev)}}>
                <MyText style={[css.m, styles.annexTitle, hasChecked?css.bold:css.normal]}>ANNEX V - Garbage</MyText>
                <View style={{position: 'absolute', right: 10, top: 14}}>
                    <AntDesign name="down" size={12} color="#461b6f" style={{transform: [{rotate: collapsed?'0deg':'180deg'}]}}/>
                </View>
            </TouchableOpacity>
            <View style={styles.formContainer}>
                <View style={{display: collapsed?'none':'flex'}}>
                    {Block(plastic, setPlastic, plasticData, setPlasticData, 'Plastic', '20 01 39')}
                    {Block(foodWaste, setFoodWaste, foodWasteData, setFoodWasteData, 'Food waste/International Catering Waste', '20 01 08')}
                    {Block(domesticWaste, setDomesticWaste, domesticWasteData, setDomesticWasteData, 'Domestic Waste', '20 03 01')}
                    {Block(cookingOil, setCookingOil, cookingOilData, setCookingOilData, 'Cooking Oil', '20 01 25')}
                    {Block(incineratorAsh, setIncineratorAsh, incineratorAshData, setIncineratorAshData, 'Incinerator Ash', '10 08 09')}
                    {Block(operationalWaste, setOperationalWaste, operationalWasteData, setOperationalWasteData, 'Operational Waste', '803806')}
                    {Block(animalCarcasses, setAnimalCarcasses, animalCarcassesData, setAnimalCarcassesData, 'Animal Carcasses', '803807')}
                    {Block(fishingGear, setFishingGear, fishingGearData, setFishingGearData, 'Fishing Gear', '803808')}
                    {Block(eWaste, setEWaste, eWasteData, setEWasteData, 'e-Waste', '803809')}
                    {Block(cargo, setCargo, cargoData, setCargoData, 'Cargo residues - non-HME', '803810')}
                    {Block(other, setOther, otherData, setOtherData, 'Other Substances', '803811')}
                </View>
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    annexContainer: {
        marginBottom: 0
    },
    annexTitle: {
        backgroundColor: '#e9e3f0',
        color: '#57307e',
        textAlign: 'center',
        width: '100%',
        padding: 10
    },
    formContainer: {
        padding: 20,
        paddingTop: 15,
        paddingVertical: 0,
        flex: 1
    },
    row: {
        marginBottom: 15
    }
})
export default AnnexVNonHazardous
import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput, TouchableOpacity, Platform } from 'react-native';
import Collapsible from 'react-native-collapsible';
import MyCheckbox from '../MyCheckbox';
import MyDatePicker from '../MyDatePicker';
import MyDateTimePicker from '../MyDatetimePicker';
import MyPicker from '../MyPicker';
import MyText from '../MyText';
import MyTextarea from '../MyTextarea';
import MyTextInput from '../MyTextInput';
import stylesheet from '../Style'

const RouteUpdateForm = (props) => {
    const css = stylesheet();
    
    const [companyRepresentative, setCompanyRepresentative] = React.useState(props.value?.companyRepresentative??'');
    const [collectionMean, setCollectionMean] = React.useState(props.value?.collectionMean??'');
    const [purpose, setPurpose] = React.useState(props.value?.purpose??'');
    const [vehicleId, setVehicleId] = React.useState(props.value?.vehicleId??'');
    const [comments, setComments] = React.useState(props.value?.comments??'');
    const [datetime, setDatetime] = React.useState(props.value?.datetime??new Date());
    const [active, setActive] = React.useState(props.value?.active??true);
    
    const toIsoString = (date) => {
        if(!date)
            return null;
        if(!date.getTimezoneOffset){
            date = new Date(date);
        }
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function(num) {
                return (num < 10 ? '0' : '') + num;
            };
      
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
      }

    React.useEffect(() => {
        props.onChange({
            companyRepresentative,
            datetime: toIsoString(datetime),
            collectionMean,
            vehicleId,
            purpose,
            comments,
            active
        });
    }, [companyRepresentative, datetime, collectionMean, vehicleId, purpose, comments, active]);

    const Delete = () => {
        setActive(false);
    }

    return (
        <>
        {active &&
            <View style={[css.formContainer, styles.container, {paddingTop: 24}]}>
                {
                    !props.isInfo?
                    <TouchableOpacity onPress={() => Delete()} style={[{padding: 5, position: 'absolute', right: 4, top: Platform.OS==='android'?-8:-4}]}>
                        <MyText style={[css.ml, {color: '#461b6f'}]}>x</MyText>
                    </TouchableOpacity>
                    :null
                }
                <View style={[{zIndex: 11}, css.formRow]}>
                    <MyText style={css.formRowLabel}>
                        Company Representative
                    </MyText>
                    <MyPicker
                        style={css.formRowInput}
                        items={props?.companyRepresentatives??[]}
                        value={props?.value?.companyRepresentative}
                        onValueChange={(item, itemIndex) =>
                            setCompanyRepresentative(item.value)
                        }
                        isInfo={props.isInfo}
                        hasError={!companyRepresentative && props.submit}
                        >
                    </MyPicker>
                </View>
                <View style={[{zIndex: 9}, css.formRow]}>
                    <MyText style={css.formRowLabel}>
                        Datetime
                    </MyText>
                    <View style={[{flex: 1}, css.doubleInput]}>
                        <MyDateTimePicker
                            onChange={setDatetime}
                            small={true}
                            value={props?.value?.datetime?new Date(props.value.datetime):null}
                            isInfo={props.isInfo}
                            hasError={!datetime && props.submit}
                        />
                    </View>
                </View>
                <View style={[{zIndex: 8}, css.formRow]}>
                    <MyText style={css.formRowLabel}>
                        Collection Mean
                    </MyText>
                    <MyPicker
                        style={css.formRowInput}
                        items={props?.collectionMeans??[]}
                        value={props?.value?.collectionMean}
                        onValueChange={(item, itemIndex) =>
                            setCollectionMean(item.value)
                        }
                        isInfo={props.isInfo}
                        hasError={!collectionMean && props.submit}
                        >
                    </MyPicker>
                </View>
                <View style={[{zIndex: 7}, css.formRow]}>
                    <MyText style={css.formRowLabel}>
                        ID
                    </MyText>
                    <MyTextInput
                        style={css.formRowInput}
                        onChangeText={(e) => setVehicleId(e)}
                        onChangeTextImmediate={(e) => setVehicleId(e)}
                        value={vehicleId}
                        isInfo={props.isInfo}
                        hasError={!vehicleId && props.submit}
                    />
                </View>
                <View style={[{zIndex: 6}, css.formRow]}>
                    <MyText style={css.formRowLabel}>
                        Purpose
                    </MyText>
                    <MyPicker
                        style={css.formRowInput}
                        items={props?.purposes??[]}
                        value={props?.value?.purpose}
                        onValueChange={(item, itemIndex) =>
                            setPurpose(item.value)
                        }
                        isInfo={props.isInfo}
                        hasError={!purpose && props.submit}
                        >
                    </MyPicker>
                </View>
                <View style={[{zIndex: 5}, css.formTextareaRow, {alignItems: 'flex-start'}]}>
                    <MyText style={css.formRowLabel}>
                        Comments
                    </MyText>
                    <MyTextarea
                        style={css.formRowInput}
                        onChangeText={setComments}
                        value={comments}
                        placeholder=''
                        isInfo={props.isInfo}
                    />
                </View>
            </View>
        }
        </>
    )
}
const styles = StyleSheet.create({
    container: {
        paddingVertical: 10,
        paddingHorizontal: 14,
        paddingBottom: 0,
        borderColor: '#532b75',
        borderWidth: 0.5,
        borderRadius: 20,
        marginBottom: 15,
        overflow: 'hidden',
    }
})
export default RouteUpdateForm
import React, { FunctionComponent } from 'react'
import {
  Text,
  StyleSheet,
  Image
} from 'react-native'
import { Platform } from 'react-native';

const MySVG = (props) => {
    if(Platform.OS === 'web'){
        return (
            <Image {...props} />
        )
    }
    else{
        return (
            <props.source {...props} />
        )
    }
}
const styles = StyleSheet.create({
})
export default MySVG
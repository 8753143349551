import React from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, TextInput, TouchableWithoutFeedback, TouchableOpacity, ActivityIndicator, Touchable, Platform, useWindowDimensions, Keyboard } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { ScrollView } from 'react-native-gesture-handler';
import axios from 'axios';
import stylesheet from '../Style'
import MyText from '../MyText';
import MyTextInput from '../MyTextInput';
import MyPicker from '../MyPicker';
import MyTextarea from '../MyTextarea';
import RequestedCollectionMeans from './RequestedCollectionMeans';
import MyDateTimePicker from '../MyDatetimePicker';
import AnnexINonHazardous from './Annex/AnnexINonHazardous';
import AnnexIINonHazardous from './Annex/AnnexIINonHazardous';
import AnnexIVNonHazardous from './Annex/AnnexIVNonHazardous';
import AnnexVNonHazardous from './Annex/AnnexVNonHazardous';
import AnnexVINonHazardous from './Annex/AnnexVINonHazardous';
import AnnexIHazardous from './Annex/AnnexIHazardous';
import AnnexIIHazardous from './Annex/AnnexIIHazardous';
import AnnexIVHazardous from './Annex/AnnexIVHazardous';
import AnnexVHazardous from './Annex/AnnexVHazardous';
import AnnexVIHazardous from './Annex/AnnexVIHazardous';
import { Entypo } from '@expo/vector-icons';
import Progress from '../Progress';

import RouteUpdate from './RouteUpdate';
import WasteCollection from './WasteCollection';
import MyCheckbox from '../MyCheckbox';
import MyReasonModal from '../MyReasonModal';

import NetInfo from '@react-native-community/netinfo';
import AsyncStorage from '@react-native-async-storage/async-storage';

import * as db from '../database';
import { useModal } from '../hooks/modal';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const NewRequest = (props) => {
    const modal = useModal();

    const defaultGrossTonnageType = 'Ton';
    const defaultGrtType = 'Ton';
    const defaultDwtType = 'Ton';

    const css = stylesheet();
    const {height, width} = useWindowDimensions();

    let annexError = '';

    const [gotData, setGotData] = React.useState(false);

    const [isInfo, setIsInfo] = React.useState(props.isInfo);

    const [tab, setTab] = React.useState('Overview');

    const [progressData, setProgressData] = React.useState([]);
    const [routeUpdateData, setRouteUpdateData] = React.useState([]);
    const [wasteCollectionData, setWasteCollectionData] = React.useState({});
    const [erpWasteCollectionData, setErpWasteCollectionData] = React.useState([]);

    const [dataCords0, setDataCords0] = React.useState(0);
    const [dataCords1, setDataCords1] = React.useState(0);
    const [dataCords2, setDataCords2] = React.useState(0);
    const [dataCords3, setDataCords3] = React.useState(0);
    const [countryFlagCords, setCountryFlagCords] = React.useState(0);

  const [comments, setComments] = React.useState('');
  const [agent, setAgent] = React.useState('');

  const [newShip, setNewShip] = React.useState(false);
  const [shipEditable, setShipEditable] = React.useState(true);

  const [requestedCollectionMeans, setRequestedCollectionMeans] = React.useState([{}]);

  const scrollRef = React.useRef();

  const [grossTonnageType, setGrossTonnageType] = React.useState(defaultGrossTonnageType);
  const [grtType, setGrtType] = React.useState(defaultGrtType);
  const [dwtType, setDwtType] = React.useState(defaultDwtType);

  const [annex1, setAnnex1] = React.useState({});
  const [annex2, setAnnex2] = React.useState({});
  const [annex4, setAnnex4] = React.useState({});
  const [annex5, setAnnex5] = React.useState({});
  const [annex6, setAnnex6] = React.useState({});

  const [annex1non, setAnnex1non] = React.useState({});
  const [annex2non, setAnnex2non] = React.useState({});
  const [annex4non, setAnnex4non] = React.useState({});
  const [annex5non, setAnnex5non] = React.useState({});
  const [annex6non, setAnnex6non] = React.useState({});

  const [annex1nonKey, setAnnex1nonKey] = React.useState(1000);
  const [annex2nonKey, setAnnex2nonKey] = React.useState(2000);
  const [annex4nonKey, setAnnex4nonKey] = React.useState(3000);
  const [annex5nonKey, setAnnex5nonKey] = React.useState(4000);
  const [annex6nonKey, setAnnex6nonKey] = React.useState(5000);

  const [annex1Key, setAnnex1Key] = React.useState(6000);
  const [annex2Key, setAnnex2Key] = React.useState(7000);
  const [annex4Key, setAnnex4Key] = React.useState(8000);
  const [annex5Key, setAnnex5Key] = React.useState(9000);
  const [annex6Key, setAnnex6Key] = React.useState(10000);

  const [requestedCollectionMeansKey, setRequestedCollectionMeansKey] = React.useState(11000);

  const [hasCheckedAnnex, setHasCheckedAnnex] = React.useState(true);

  const [status, setStatus] = React.useState('');
  const [requestedAt, setRequestedAt] = React.useState('');

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalCallback, setModalCallback] = React.useState(null);

    const [imo, setImo] = React.useState({
        value: '',
        hasError: false
    });
    const [shipName, setShipName] = React.useState({
        value: '',
        hasError: false
    });
    const [shipType, setShipType] = React.useState({
        value: '',
        hasError: false
    });
    const [countryFlag, setCountryFlag] = React.useState({
        value: '',
        hasError: false
    });
    const [grossTonnage, setGrossTonnage] = React.useState({
        value: '',
        hasError: false
    });
    const [grt, setGrt] = React.useState({
        value: '',
        hasError: false
    });
    const [dwt, setDwt] = React.useState({
        value: '',
        hasError: false
    });
    const [registryOffice, setRegistryOffice] = React.useState({
        value: '',
        hasError: false
    });
    const [portOfDelivery, setPortOfDelivery] = React.useState({
        value: '',
        hasError: false
    });
    const [location, setLocation] = React.useState({
        value: '',
        hasError: false
    });
    const [arrival, setArrival] = React.useState({
        value: '',
        hasError: false
    });
    const [departure, setDeparture] = React.useState({
        value: '',
        hasError: false
    });
    const [requestedDelivery, setRequestedDelivery] = React.useState({
        value: '',
        hasError: false
    });
    const [lastPort, setLastPort] = React.useState({
        value: '',
        hasError: false
    });
    const [nextPort, setNextPort] = React.useState({
        value: '',
        hasError: false
    });

    // Persons on board
    const [personsOnBoardTotal, setPersonsOnBoardTotal] = React.useState({
        value: '',
        hasError: false
    });
    const [personsOnBoardCrew, setPersonsOnBoardCrew] = React.useState({
        value: '',
        hasError: false
    });
    const [personsOnBoardPassengers, setPersonsOnBoardPassengers] = React.useState({
        value: '',
        hasError: false
    });

    //Contact person
    const [contactPersonFName, setContactPersonFName] = React.useState({
        value: '',
        hasError: false
    });
    const [contactPersonLName, setContactPersonLName] = React.useState({
        value: '',
        hasError: false
    });
    const [contactPersonProfession, setContactPersonProfession] = React.useState({
        value: '',
        hasError: false
    });
    const [contactPersonEmail, setContactPersonEmail] = React.useState({
        value: '',
        hasError: false
    });
    const [contactPersonPhone, setContactPersonPhone] = React.useState({
        value: '',
        hasError: false
    });
  
  const [countries, setCountries] = React.useState(null);
  const [collectionMeans, setCollectionMeans] = React.useState(null);
  const [companyRepresentatives, setCompanyRepresentatives] = React.useState([{}]);
  const [requestedPurposes, setRequestedPurposes] = React.useState(null);
  const [ships, setShips] = React.useState(null);
  const [shipTypes, setShipTypes] = React.useState(null);
  const [wasteMetrics, setWasteMetrics] = React.useState(null);
  const [portOfDeliveries, setPortOfDeliveries] = React.useState(null);
  const [locations, setLocations] = React.useState(null);
  const [agents, setAgents] = React.useState(null);
  const [agentPerPort, setAgentPerPort] = React.useState(null);

  React.useEffect(() => {
    if(countries && collectionMeans && companyRepresentatives && requestedPurposes && ships && shipTypes && wasteMetrics && portOfDeliveries && locations && agents && agentPerPort){
        const ready = async() => {
            if(props.id){
                SetRequest(await db.getRequest(props.id));
            }
            else{
                setGotData(true);
            }
        }
        ready().catch(console.error);
    }
  }, [countries, collectionMeans, companyRepresentatives, requestedPurposes, ships, shipTypes, wasteMetrics, portOfDeliveries, locations, agents, agentPerPort])
  
  const SetRequest = (data) => {
    SetFormData(data.data, data.status, data.requested_at);
    setRouteUpdateData(data.route_update);
    setWasteCollectionData(data.waste_collection);
    setErpWasteCollectionData(data.erp_waste_collection);
    let tempProgressData = [];
    let cancelled = false;
    const statuses = ['Submitted', 'Validated', 'Route Scheduled', 'Driver Arrived', 'Waste Collected', 'Waste Segregated', 'Completed', 'Certificate Issued'];
    data.request_progress.forEach(progress => {
        if(progress.status.toLocaleLowerCase() === 'cancelled' || progress.status.toLocaleLowerCase() === 'rejected'){
            cancelled = true;
        }
    });
    if(!cancelled){
        statuses.forEach(status => {
            let found = false;
            data.request_progress.forEach(progress => {
                if(!found && progress.status.toLowerCase() === status.toLocaleLowerCase()){
                    let tmpProgress = JSON.parse(JSON.stringify(progress));
                    if(progress.status.toLocaleLowerCase() === 'route scheduled' || progress.status.toLocaleLowerCase() === 'driver arrived'){
                        const drivers = data.route_update.map(route => {
                            return route.companyRepresentative
                        }).join(', ');
                        tmpProgress.drivers = drivers;
                        tmpProgress.scheduledFor = data.route_update[0].datetime;
                    }
                    else if(progress.status.toLocaleLowerCase() === 'waste collected'){
                        tmpProgress.waste_collection = data.waste_collection;
                    }
                    tempProgressData.push(tmpProgress);
                    found = true;
                }
            })
            if(!found){
                tempProgressData.push({status: status}); // Push empty so it's visible in the Progress tab
            }
        })
    }
    else{
        data.request_progress.forEach(progress => {
            let tmpProgress = JSON.parse(JSON.stringify(progress));
            if(progress.status.toLocaleLowerCase() === 'route scheduled'){
                const drivers = data.route_update.map(route => {
                    return route.companyRepresentative
                }).join(', ');
                tmpProgress.drivers = drivers;
            }
            tempProgressData.push(tmpProgress);
        })
    }
    setProgressData(tempProgressData);
  }

    React.useEffect(() => {
        const filters = async () => {
            console.log("Loading");
            if(!gotData){
                if(!!!props.id){ //If we are in New Request, load form immediatelly
                    setGotData(true);
                }
                try{
                    await db.syncFilters();
                    setCountries(await db.getAll('countries'));
                    setCollectionMeans(await db.getAll('collection_means'));
                    setCompanyRepresentatives(await db.getAll('company_representatives'));
                    setRequestedPurposes(await db.getAll('requested_purposes'));
                    setShips(await db.getAll('ships', 'shipName'));
                    setShipTypes(await db.getAll('ship_types'));
                    setWasteMetrics(await db.getAll('waste_metrics'));
                    setPortOfDeliveries(await db.getAll('ports'));
                    setLocations(await db.getAll('locations'));

                    const agents = await db.getAll('agents', 'value');
                    setAgents(agents);
                    
                    let tmpAgentPerPort = {};
                    const agent_ports = await db.getAll('agent_port');
                    agent_ports.forEach(agent_port => {
                        const agentName = agents.find(agent => agent.key == agent_port.agent);
                        if(!agentName)
                            return;
                        if(tmpAgentPerPort[agent_port.port]){
                            tmpAgentPerPort[agent_port.port].push(agentName);
                        }
                        else{
                            tmpAgentPerPort[agent_port.port] = [agentName];
                        }
                    })
                    for(const port in tmpAgentPerPort){
                        tmpAgentPerPort[port].sort((a,b) => (a.vallue > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
                    }
                    setAgentPerPort(tmpAgentPerPort);
                }
                catch(e){
                    console.log(e);
                }
            }
        }
        filters().catch(console.error);
    }, []);

    const SetFormData = (data, status, requestedAt) => {
        SetShip(data);
        if(data.registryOffice){
            setRegistryOffice({
                hasError: false,
                value: data.registryOffice
            })
        }

        if(data.contactPersonFName){
            setContactPersonFName({
                hasError: false,
                value: data.contactPersonFName
            })
        }
        if(data.contactPersonLName){
            setContactPersonLName({
                hasError: false,
                value: data.contactPersonLName
            })
        }
        if(data.contactPersonEmail){
            setContactPersonEmail({
                hasError: false,
                value: data.contactPersonEmail
            })
        }
        if(data.contactPersonPhone){
            setContactPersonPhone({
                hasError: false,
                value: data.contactPersonPhone
            })
        }
        if(data.contactPersonProfession){
            setContactPersonProfession({
                hasError: false,
                value: data.contactPersonProfession
            })
        }

        if(data.portOfDelivery){
            let tempKey = 0;
            portOfDeliveries.forEach(p => {
                if(p.value == data.portOfDelivery)
                    tempKey = p.key;
            })
            setPortOfDelivery({
                hasError: false,
                key: tempKey,
                value: data.portOfDelivery
            })
        }
        if(data.location){
            setLocation({
                hasError: false,
                value: data.location
            })
        }
        if(data.agent){
            setAgent(data.agent);
        }
        
        if(data.arrival){
            setArrival({
                hasError: false,
                value: data.arrival
            })
        }
        if(data.departure){
            setDeparture({
                hasError: false,
                value: data.departure
            })
        }
        if(data.requestedDelivery){
            setRequestedDelivery({
                hasError: false,
                value: data.requestedDelivery
            })
        }
        if(data.lastPort){
            setLastPort({
                hasError: false,
                value: data.lastPort
            })
        }
        if(data.nextPort){
            setNextPort({
                hasError: false,
                value: data.nextPort
            })
        }

        if(data.personsOnBoardCrew){
            setPersonsOnBoardCrew({
                hasError: false,
                value: data.personsOnBoardCrew
            })
        }
        if(data.personsOnBoardPassengers){
            setPersonsOnBoardPassengers({
                hasError: false,
                value: data.personsOnBoardPassengers
            })
        }
        if(data.personsOnBoardTotal){
            setPersonsOnBoardTotal({
                hasError: false,
                value: data.personsOnBoardTotal
            })
        }

        if(data.comments){
            setComments(data.comments);
        }
        
        if(data.requestedCollectionMeans.length > 0)
            setRequestedCollectionMeans(data.requestedCollectionMeans);
        
        setAnnex1non(data.annex1non);
        setAnnex2non(data.annex2non);
        setAnnex4non(data.annex4non);
        setAnnex5non(data.annex5non);
        setAnnex6non(data.annex6non);

        setAnnex1(data.annex1);
        setAnnex2(data.annex2);
        setAnnex4(data.annex4);
        setAnnex5(data.annex5);
        setAnnex6(data.annex6);

        setAnnex1nonKey(prev => prev+1);
        setAnnex2nonKey(prev => prev+1);
        setAnnex4nonKey(prev => prev+1);
        setAnnex5nonKey(prev => prev+1);
        setAnnex6nonKey(prev => prev+1);

        setAnnex1Key(prev => prev+1);
        setAnnex2Key(prev => prev+1);
        setAnnex4Key(prev => prev+1);
        setAnnex5Key(prev => prev+1);
        setAnnex6Key(prev => prev+1);

        setRequestedCollectionMeansKey(prev => prev+1);

        setStatus(status);
        setRequestedAt(FormatDatetime(requestedAt));

        setGotData(true);

        // annex1non,
        // annex2non,
        // annex4non,
        // annex5non,
        // annex6non,
        // annex1,
        // annex2,
        // annex4,
        // annex5,
        // annex6,
        // comments
    }

    const AnnexCheck = (toCheck) => {
        let result = false;
        toCheck.forEach(c => {
            if(c?.active && c?.data?.ew && c?.data?.wasteForDelivery != ''){
                result = true;
                if(parseFloat(c?.active && c?.data?.ew && c?.data?.wasteForDelivery) > 1000){
                    result = false;
                    annexError = 'Maximum allowed quantity is 1000.';
                }
                if(c?.data?.ew && c?.data?.maxStorageCapacity != ''){
                    if(parseFloat(c?.data?.ew && c?.data?.wasteForDelivery) + parseFloat(c?.data?.ew && c?.data?.wasteRetainedOnBoard||0) > parseInt(c?.data?.ew && c?.data?.maxStorageCapacity||0)){
                        result = false;
                        annexError = 'The sum of the quantities "Waste for Delivery" and "Waste retained on board" must be less or equal to "Max Storage Capacity"';
                    }
                }          
            }

            if(c?.active && c?.data?.ew && c?.data?.maxStorageCapacity != ''){
                if(parseFloat(c?.active && c?.data?.ew && c?.data?.maxStorageCapacity) > 1000){
                    result = false;
                    annexError = 'Maximum allowed quantity is 1000.';
                    return false;
                }
            }
            if(c?.active && c?.data?.ew && c?.data?.wasteRetainedOnBoard != ''){
                if(parseFloat(c?.active && c?.data?.ew && c?.data?.wasteRetainedOnBoard) > 1000){
                    result = false;
                    annexError = 'Maximum allowed quantity is 1000.';
                    return false;
                }
                if(c?.data?.ew && c?.data?.maxStorageCapacity != ''){
                    if(parseFloat(c?.data?.wasteRetainedOnBoard) + parseFloat(c?.data?.ew && c?.data?.wasteForDelivery||0) > parseFloat(c?.data?.ew && c?.data?.maxStorageCapacity||0)){
                        result = false;
                        annexError = 'The sum of the quantities "Waste for Delivery" and "Waste retained on board" must be less or equal to "Max Storage Capacity"';
                        return false;
                    }
                }
            }
            if(c?.active && c?.data?.ew && c?.data?.estWasteUntilNextPort){
                if(parseFloat(c?.active && c?.data?.ew && c?.data?.estWasteUntilNextPort) > 1000){
                    result = false;
                    annexError = 'Maximum allowed quantity is 1000.';
                    return false;
                }
                if(c?.data?.ew && c?.data?.maxStorageCapacity != ''){
                    if(parseFloat(c?.data?.estWasteUntilNextPort) + parseFloat(c?.data?.ew && c?.data?.wasteRetainedOnBoard||0) > parseFloat(c?.data?.ew && c?.data?.maxStorageCapacity||0)){
                        result = false;
                        annexError = 'The sum of the quantities "Est. Waste Until Next Port" and "Waste retained on board" must be less or equal to "Max Storage Capacity"';
                        return false;
                    }
                }
            }
        })
        return result;
    }

    const HasCheckedAnnex1Non = (obj) => {
        let toCheck = [obj.oilyBilgeWater, obj.oilyResidues, obj.oilyTank, obj.dirtyBallast, obj.scale, obj.other];
        return AnnexCheck(toCheck);
    }
    const HasCheckedAnnex2Non = (obj) => {
        let toCheck = [obj.categoryX, obj.categoryY, obj.categoryZ, obj.other];
        return AnnexCheck(toCheck);
    }
    const HasCheckedAnnex4Non = (obj) => {
        let toCheck = [obj.sewage];
        return AnnexCheck(toCheck);
    }
    const HasCheckedAnnex5Non = (obj) => {
        let toCheck = [obj.plastic, obj.foodWaste, obj.domesticWaste, obj.cookingOil, obj.incineratorAsh, obj.operationalWaste, obj.animalCarcasses, obj.fishingGear, obj.eWaste, obj.cargo, obj.other];
        return AnnexCheck(toCheck);
    }
    const HasCheckedAnnex6Non = (obj) => {
        let toCheck = [obj.ozone, obj.exhaust];
        return AnnexCheck(toCheck);
    }

    const HasCheckedAnnex1 = (obj) => {
        let toCheck = [obj.oily, obj.other];
        return AnnexCheck(toCheck);
    }
    const HasCheckedAnnex2 = (obj) => {
        let toCheck = [obj.categoryX, obj.categoryY, obj.categoryZ, obj.other];
        return AnnexCheck(toCheck);
    }
    const HasCheckedAnnex4 = (obj) => {
        let toCheck = [obj.sewage];
        return AnnexCheck(toCheck);
    }
    const HasCheckedAnnex5 = (obj) => {
        let toCheck = [obj.paint, obj.printing, obj.photo, obj.med, obj.leadBat, obj.otherBat, obj.dangerous, obj.oilyRags, obj.other];
        return AnnexCheck(toCheck);
    }
    const HasCheckedAnnex6 = (obj) => {
        let toCheck = [obj.ozone, obj.exhaust];
        return AnnexCheck(toCheck);
    }

  const CheckMandatory = () => {
    // const mandatoryFields = [imo, shipName, shipType, grossTonnage, grt, dwt, registryOffice,
    //                             personsOnBoard.crew,
    //                             contactPerson.fName, contactPerson.lName, contactPerson.email, contactPerson.phone];
    // const mandatoryFields = [imo, shipName, shipType, grossTonnage, grt, dwt, registryOffice];
    let check = true;
    
    setImo(prev => ({...prev, hasError: false}));
    setShipName(prev => ({...prev, hasError: false}));
    setShipType(prev => ({...prev, hasError: false}));
    setCountryFlag(prev => ({...prev, hasError: false}));
    setGrossTonnage(prev => ({...prev, hasError: false}));
    setGrt(prev => ({...prev, hasError: false}));
    setDwt(prev => ({...prev, hasError: false}));
    setRegistryOffice(prev => ({...prev, hasError: false}));

    setHasCheckedAnnex(true);

    setPersonsOnBoardCrew(prev => ({...prev, hasError: false}));

    setPortOfDelivery(prev => ({...prev, hasError: false}));
    setLocation(prev => ({...prev, hasError: false}));
    setArrival(prev => ({...prev, hasError: false}));
    setDeparture(prev => ({...prev, hasError: false}));
    setRequestedDelivery(prev => ({...prev, hasError: false}));

    setContactPersonFName(prev => ({...prev, hasError: false}));
    setContactPersonLName(prev => ({...prev, hasError: false}));
    setContactPersonEmail(prev => ({...prev, hasError: false}));
    setContactPersonPhone(prev => ({...prev, hasError: false}));

    let scrollTo = 0;

    if(!imo.value){
        check = false;
        setImo(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }
    if(!shipName.value){
        check = false;
        setShipName(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }
    if(!shipType.value){
        check = false;
        setShipType(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }
    if(!countryFlag.value){
        check = false;
        setCountryFlag(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }
    if(!grossTonnage.value){
        check = false;
        setGrossTonnage(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }
    if(!grt.value){
        check = false;
        setGrt(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }
    if(!dwt.value){
        check = false;
        setDwt(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }
    if(!registryOffice.value){
        check = false;
        setRegistryOffice(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }

    if(!contactPersonFName.value){
        check = false;
        setContactPersonFName(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }
    if(!contactPersonLName.value){
        check = false;
        setContactPersonLName(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }
    if(!contactPersonEmail.value){
        check = false;
        setContactPersonEmail(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }
    if(!contactPersonPhone.value){
        check = false;
        setContactPersonPhone(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords0;
    }

    if(!portOfDelivery.value){
        check = false;
        setPortOfDelivery(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords1;
    }
    if(!location.value){
        check = false;
        setLocation(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords1;
    }
    if(!arrival.value){
        check = false;
        setArrival(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords1;
    }
    if(!departure.value){
        check = false;
        setDeparture(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords1;
    }
    if(!requestedDelivery.value){
        check = false;
        setRequestedDelivery(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords1;
    }

    if(arrival.value && departure.value && requestedDelivery.value){
        const ar = new Date(arrival.value);
        const dep = new Date(departure.value);
        const rd = new Date(requestedDelivery.value);

        let ar_dep_rd = true;

        if(dep.getTime() < ar.getTime()){
            modal.confirm.show("Warning", "The Departure Datetime must be later than the Arrival Datetime.");
            ar_dep_rd = false;
        }
        else if(rd.getTime() > dep.getTime()){
            modal.confirm.show("Warning", "The Requested Delivery Datetime must be between the Arrival and Departure Datetime.");
            ar_dep_rd = false;
        }
        else if(rd.getTime() < ar.getTime()){
            modal.confirm.show("Warning", "The Requested Delivery Datetime must be between the Arrival and Departure Datetime.");
            ar_dep_rd = false;
        }

        if(!ar_dep_rd){
            scrollRef.current.scrollToPosition(0, dataCords1);
            return false;
        }

        let meansError = false;
        requestedCollectionMeans.forEach(mean => {
            if(mean.active && (mean.from || mean.to)){
                if(new Date(mean.to).getTime() < new Date(mean.from).getTime()){
                    modal.confirm.show("Warning", 'The Collection Mean "TO" Datetime must be later than the Collection Mean "FROM" Datetime.');
                    scrollRef.current.scrollToPosition(0, dataCords3);
                    meansError = true;
                    return;
                }
                if(new Date(mean.from).getTime() < ar.getTime() || new Date(mean.to).getTime() > dep.getTime()){
                    modal.confirm.show("Warning", 'The period of Collection Mean must be within the datetime of Arrival and Departure.');
                    scrollRef.current.scrollToPosition(0, dataCords3);
                    meansError = true;
                    return;
                }
            }
        })
        if(meansError){
            return false;
        }
    }

    if(!personsOnBoardCrew.value){
        check = false;
        setPersonsOnBoardCrew(prev => ({...prev, hasError: true}))
        if(scrollTo == 0) scrollTo = dataCords1;
    }

    annexError = '';

    // const hasAnnex1non = HasCheckedAnnex1Non(annex1non);
    // const hasAnnex2non = HasCheckedAnnex2Non(annex2non);
    // const hasAnnex4non = HasCheckedAnnex4Non(annex4non);
    // const hasAnnex5non = HasCheckedAnnex5Non(annex5non);
    // const hasAnnex6non = HasCheckedAnnex6Non(annex6non);

    // const hasAnnex1 = HasCheckedAnnex1(annex1);
    // const hasAnnex2 = HasCheckedAnnex2(annex2);
    // const hasAnnex4 = HasCheckedAnnex4(annex4);
    // const hasAnnex5 = HasCheckedAnnex5(annex5);
    // const hasAnnex6 = HasCheckedAnnex6(annex6);

    if(annexError){
        setHasCheckedAnnex(false);
        scrollRef.current.scrollToPosition(0, dataCords2);
        modal.confirm.show("Warning", annexError);
        return false;
    }
    /*if(!hasAnnex1non && !hasAnnex2non && !hasAnnex4non && !hasAnnex5non && !hasAnnex6non && !hasAnnex1 && !hasAnnex2 && !hasAnnex4 && !hasAnnex5 && !hasAnnex6){
        check = false;
        setHasCheckedAnnex(false);
        if(scrollTo == 0) scrollTo = dataCords2;
    }*/

    // alert(scrollTo);

    if(scrollTo != 0){
        scrollRef.current.scrollToPosition(0, scrollTo);
    }

    if(!check)
        modal.confirm.show("Warning", "Please fill in all mandatory fields in red.");
    return check;
  }

  const toIsoString = (date) => {
    if(!date)
        return null;
    if(!date.getTimezoneOffset){
        date = new Date(date);
    }
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            return (num < 10 ? '0' : '') + num;
        };
  
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
  }

  const [submit, setSubmit] = React.useState(false);
  React.useEffect(() => {
    if(submit){
        setSubmit(false);

        if(CheckMandatory()){
            const data = {
                imo: imo.value,
                shipName: shipName.value,
                shipType: shipType.value,
                grossTonnage: grossTonnage.value,
                grossTonnageType,
                grt: grt.value,
                grtType,
                dwt: dwt.value,
                dwtType,
                countryFlag: countryFlag.value,
                registryOffice: registryOffice.value,
                contactPersonFName: contactPersonFName.value,
                contactPersonLName: contactPersonLName.value,
                contactPersonProfession: contactPersonProfession.value,
                contactPersonEmail: contactPersonEmail.value,
                contactPersonPhone: contactPersonPhone.value,
                portOfDelivery: portOfDelivery.value,
                location: location.value,
                agent,
                arrival: toIsoString(arrival.value),
                departure: toIsoString(departure.value),
                requestedDelivery: toIsoString(requestedDelivery.value),
                lastPort: lastPort.value,
                nextPort: nextPort.value,
                personsOnBoardCrew: personsOnBoardCrew.value,
                personsOnBoardPassengers: personsOnBoardPassengers.value,
                personsOnBoardTotal: personsOnBoardTotal.value,
                requestedCollectionMeans,
                annex1non,
                annex2non,
                annex4non,
                annex5non,
                annex6non,
                annex1,
                annex2,
                annex4,
                annex5,
                annex6,
                comments,
                requestedAt: toIsoString(new Date())
            };
            NetInfo.fetch().then(async(state) => {
                if(!state.isConnected){
                    try{
                        if(props.id){
                            let pending = JSON.parse(await AsyncStorage.getItem('PendingEditRequests')??'[]');
                            let found = false;
                            pending.forEach(id => {
                                if(id == props.id){
                                    found = true;
                                }
                            })
                            if(!found){
                                pending.push(props.id);
                            }
                            await AsyncStorage.setItem('PendingEditRequests', JSON.stringify(pending));

                            // Update local database
                            const request = await db.get('requests', props.id);
                            request.data = data;
                            await db.save('requests', request);
   
                            if(!props.user.isVGroup){
                                modal.confirm.show("Success", "Your request has been updated successfully.\n\nYou are currently offline. We'll sync your request when you're back online.");
                                setTimeout(() => {
                                    props.navigation.push('My Requests');
                                },10)
                            }
                            else{
                                modal.confirm.show("Success", "The request has been updated successfully.\n\nYou are currently offline. We'll sync your request when you're back online.");
                                setTimeout(() => {
                                    props.navigation.push('Manage Requests');
                                },10)
                            }
                        }
                        else{                            
                            // Update local database
                            const request = {};
                            request.data = data;
                            request.status = 'Not Synced';
                            await db.save('pending_requests', request);

                            if(!props.user.isVGroup){
                                modal.confirm.show("Success", "Your request has been submitted successfully.\n\nYou are currently offline. We'll sync your request when you're back online.");
                                setTimeout(() => {
                                    props.navigation.push('My Requests');
                                },10)
                            }
                            else{
                                modal.confirm.show("Success", "The request has been submitted successfully.\n\nYou are currently offline. We'll sync your request when you're back online.");
                                setTimeout(() => {
                                    props.navigation.push('Manage Requests');
                                },10)
                            }
                        }
                    }
                    catch(e){console.error(e)}
                    return;
                }
                if(props.id){
                    data.id = props.id;
                    axios.post('/api/editRequest.php', data)
                    .then((res) => {
                        if(!props.user.isVGroup){
                            modal.confirm.show("Success", "Your request has been updated successfully.\nYou will be shortly notified about the Waste collection details.");
                            setTimeout(() => {
                                props.navigation.push('My Requests');
                            },10)
                        }
                        else{
                            modal.confirm.show("Success", "The request has been updated successfully.");
                            setTimeout(() => {
                                props.navigation.push('Manage Requests');
                            },10)
                        }
                    })
                }
                else{
                    axios.post('/api/newRequest.php', data)
                    .then((res) => {
                        if(!props.user.isVGroup){
                            modal.confirm.show("Success", "Your request has been submitted successfully.\nYou will be shortly notified about the Waste collection details.");
                            setTimeout(() => {
                                props.navigation.push('My Requests');
                            },10)
                        }
                        else{
                            modal.confirm.show("Success", "The request has been submitted successfully.");
                            setTimeout(() => {
                                props.navigation.push('Manage Requests');
                            },10)
                        }
                    })
                }
            })
        }
    }
  },[submit])

  const Approve = (id) => {
        const data = {
            id,
            status: 'Validated'
        }
        axios.post('/api/editRequestStatus.php', data)
        .then((res) => {
            modal.confirm.show("Success", "Request "+id+" is validated successfully.");
            setTimeout(() => {
                if(!props.user.isVGroup){
                    props.navigation.push('My Requests');
                }
                else{
                    props.navigation.push('Manage Requests');
                }
            },10)
        })
    }
    const Reject = (id) => {
        setModalOpen(true);
        setModalCallback((reason) => (reason) => {
            const data = {
                id,
                status: 'Rejected',
                reason: reason
            }
            axios.post('/api/editRequestStatus.php', data)
            .then((res) => {
                modal.confirm.show("Success", "Request "+id+" is now rejected.");
                setTimeout(() => {
                    if(!props.user.isVGroup){
                        props.navigation.push('My Requests');
                    }
                    else{
                        props.navigation.push('Manage Requests');
                    }
                },10)
            })
        })
    }

  const Submit = () => {
    Keyboard.dismiss();
    setTimeout(() => {
        setSubmit(true); //This makes sure I call submit after all other inputs have been saved (i.e. Annex takes longer)
    },100);
  }

  const DriverArrival = () => {
    modal.confirm.show("Warning", "Please confirm the arrival of the driver.", async() => {
        const data = {
            id: props.id,
            status: 'Driver Arrived'
        }
        axios.post('/api/editRequestStatus.php', data)
        .then((res) => {
            modal.confirm.show("Success", "The arrival of the driver has been confirmed.");
            setTimeout(() => {
                props.navigation.push('Request Info', { id: props.id });
            },100);
        })
    });
}

  const GetShipByIMO = async(parameter) => {
    setShipEditable(true);
    try{
        ships.forEach(ship => {
            if(ship.imo == (parameter?parameter:imo.value)){
                setNewShip(false);
                SetShip(ship);
            }
        })
    }catch(e){}
  }
  const GetShipByName = async(parameter) => {
    setShipEditable(true);
    try{
      ships.forEach(ship => {
          if(ship.shipName.toLowerCase() == (parameter?parameter.toLowerCase():shipName.value.toLowerCase())){
              setNewShip(false);
              SetShip(ship);
          }
      })
    }catch(e){}
  }
  const valueOrDefault = (val, def) => {
    if(!val){
        return def;
    }
    return val;
  }
  const SetShip = (data) => {    
    setShipEditable(data?false:true);
    setImo({
        hasError: false,
        value: valueOrDefault(data?.imo, "").toString()
    })
    setShipName({
        hasError: false,
        value: valueOrDefault(data?.shipName, "").toString()
    })
    setCountryFlag({
        hasError: false,
        value: valueOrDefault(data?.countryFlag, "").toString()
    })
    setGrossTonnage({
        hasError: false,
        value: valueOrDefault(data?.grossTonnage, "0").toString()
    })
    setGrossTonnageType(valueOrDefault(data?.grossTonnageType, defaultGrossTonnageType).toString())
    setDwt({
        hasError: false,
        value: valueOrDefault(data?.dwt, "0").toString()
    })
    setDwtType(valueOrDefault(data?.dwtType, defaultDwtType).toString())
    setGrt({
        hasError: false,
        value: valueOrDefault(data?.grt, "0").toString()
    })
    setGrtType(valueOrDefault(data?.grtType, defaultGrtType).toString())
    if(!(data?.grt)){
        setGrt({
            hasError: false,
            value: valueOrDefault(data?.grossTonnage, "0").toString()
        })
        setGrtType(valueOrDefault(data?.grossTonnageType, defaultGrossTonnageType).toString())
    }
    setShipType({
        hasError: false,
        value: valueOrDefault(data?.shipType, "").toString()
    })
    setRegistryOffice({
        hasError: false,
        value: valueOrDefault(data?.registryOffice, "-").toString()
    })
    setContactPersonFName({
        hasError: false,
        value: valueOrDefault(data?.contactPersonFName, "").toString()
    })
    setContactPersonLName({
        hasError: false,
        value: valueOrDefault(data?.contactPersonLName, "").toString()
    })
    setContactPersonProfession({
        hasError: false,
        value: valueOrDefault(data?.contactPersonProfession, "").toString()
    })
    setContactPersonEmail({
        hasError: false,
        value: valueOrDefault(data?.contactPersonEmail, "").toString()
    })
    setContactPersonPhone({
        hasError: false,
        value: valueOrDefault(data?.contactPersonPhone, "").toString()
    })
  }

  const NewShipCheckbox = () => {
    if(!newShip){
        setImo(prev => ({
            ...prev,
            value: ''
        }))
        setShipName(prev => ({
            ...prev,
            value: ''
        }))
        SetShip();
    }
    setNewShip(prev => !prev);
  }

  const FormatDatetime = (dt) => {
    if(!dt)
        return '';
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const padLeft = (num) => {
        if(num < 10)
            return '0'+num;
        return num;
    }
    const date = new Date(dt);
    return date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear() + ', ' + padLeft(date.getHours()) + ':' + padLeft(date.getMinutes());
  }
  const Bullet = (bulletProps) => {
    const orange = '#efa270';
    const yellow = '#fed354';
    const blue = '#1aa0eb';
    const green = '#23a43f';
    const red = '#e32112';
    let color = '';
    let text = bulletProps?.text?.toString()??'';

    if(!text){
        return <></>;
    }
    if(text.startsWith('Submitted') || text.startsWith('Validated')){
        color = orange;
    }
    else if(text.startsWith('Route') || text.startsWith('Driver')){
        color = yellow;
    }
    else if(text.startsWith('Waste')){
        color = blue;
    }
    else if(text.startsWith('Certificate')){
        color = green;
    }
    else if(text.startsWith('Cancel')){
        color = red;
    }
    return <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <View style={{width: 10, height: 10, borderRadius: 5, backgroundColor: color, marginRight: 5, marginTop: Platform.OS==='ios'?-1:1.5, overflow: 'hidden'}}></View>
            <MyText style={css.s}>{text}</MyText>
        </View>
  }

  let title = '';
  if(props.id && !isInfo){
    if(tab == 'Overview')
        title = 'Edit Request - ' + props.id;
    else
        title = 'Update Request - ' + props.id;
  }
  else if(props.id && isInfo){
      title = 'Request Info - ' + props.id;
  }
  else{
      title = 'New Request';
  }
  if(!gotData){
//   if(1==1){
      return <>
            <View style={[css.background, css.formBackground, {paddingTop: 20}]}>
                <View style={{width: '100%', flexDirection: 'row', alignItems: 'center'}}>
                    <MyText style={[css.title, css.bold]}>
                        {title}
                    </MyText>
                </View>
                {
                    !isInfo?
                    <MyText style={css.subtitle}>
                        Advance Notification Form
                    </MyText>
                    :null
                }
                {
                    isInfo?
                    <>
                        <View style={{marginTop: 10, flexDirection: 'row'}}>
                            <MyText style={{width: 120}}>Submitted On</MyText>
                            <MyText></MyText>
                        </View>
                        <View style={{marginTop: 0, flexDirection: 'row', marginBottom: 20}}>
                            <MyText style={{width: 120}}>Current Status</MyText>
                            <MyText></MyText>
                        </View>
                    </>:null
                }
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <ActivityIndicator size="large" color="#461b6f" />
                </View>
            </View>
            </>;
  }
  return (
    <>
        <KeyboardAwareScrollView ref={scrollRef} vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
            {/* <TouchableWithoutFeedback> */}
                <View style={[css.background, css.formBackground]}>
                    <View style={{width: '100%', flexDirection: 'row', alignItems: 'center'}}>
                        <MyText style={[css.title, css.bold]}>
                            {title}
                        </MyText>
                        {
                            (isInfo && ((status != 'Cancelled' && (status == 'Submitted' || status == 'Validated')) || props.user?.isVGroup))?
                            <TouchableOpacity onPress={() => props.navigation.push('Edit Request', {id: props.id})} style={{marginLeft: 'auto'}}>
                                <Entypo name="edit" size={18} color="#461b6f"/>
                            </TouchableOpacity>
                            :null
                        }
                    </View>
                    {
                        !isInfo?
                        <MyText style={css.subtitle}>
                            Advance Notification Form
                        </MyText>
                        :null
                    }
                    {
                        isInfo?
                        <>
                            <View style={{marginTop: 10, flexDirection: 'row', alignItems: 'center'}}>
                                <MyText style={{width: 120}}>Submitted On</MyText>
                                <MyText style={css.s}>{requestedAt}</MyText>
                            </View>
                            <View style={{marginTop: 0, flexDirection: 'row', alignItems: 'center', marginBottom: 20}}>
                                <MyText style={{width: 120}}>Current Status</MyText>
                                <Bullet text={status}/>
                            </View>
                            <View style={{
                                    borderColor: '#e0dde3',
                                    borderTopWidth: 1,
                                    borderBottomWidth: 1,
                                    // flex: 1,
                                    marginBottom: 20,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                }}>
                                <TouchableOpacity style={styles.tab} onPress={() => setTab('Overview')}>
                                    <View style={[{elevation: tab=='Overview'?10:0, backgroundColor: tab=='Overview'?'#eae2f1':null, paddingHorizontal: width>=768?14:7, paddingVertical: width>=768?20:10}, styles.updateRequestTab]}>
                                        <MyText style={[width>=768?css.m:css.sm, {color: tab=='Overview'?'#522c75':'#919293'}]}>Overview</MyText>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.tab} onPress={() => setTab('Progress')}>
                                    <View style={[{elevation: tab=='Progress'?10:0, backgroundColor: tab=='Progress'?'#eae2f1':null, paddingHorizontal: width>=768?14:7, paddingVertical: width>=768?20:10}, styles.updateRequestTab]}>
                                        <MyText style={[width>=768?css.m:css.sm, {color: tab=='Progress'?'#522c75':'#919293'}]}>Progress</MyText>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.tab} onPress={() => (status != 'Submitted' && status != 'Validated')?setTab('Route Update'):false}>
                                    <View style={[{elevation: tab=='Route Update'?10:0, backgroundColor: tab=='Route Update'?'#eae2f1':null, paddingHorizontal: width>=768?14:7, paddingVertical: width>=768?20:10}, styles.updateRequestTab]}>
                                        <MyText style={[width>=768?css.m:css.sm, {color: tab=='Route Update'?'#522c75':'#919293', textAlign: 'center'}]}>{'Route'}</MyText>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.tab} onPress={() => (status != 'Submitted' && status != 'Validated' && status != 'Route Scheduled' && status != 'Driver Arrived')?setTab('Waste Collection'):false}>
                                    <View style={[{elevation: tab=='Waste Collection'?10:0, backgroundColor: tab=='Waste Collection'?'#eae2f1':null, paddingHorizontal: width>=768?14:7, paddingVertical: width>=768?20:10}, styles.updateRequestTab]}>
                                        <MyText style={[width>=768?css.m:css.sm, {color: tab=='Waste Collection'?'#522c75':'#919293', textAlign: 'center'}]}>{width>=768?'Waste Collection':'Waste\nCollection'}</MyText>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </>:null
                    }
                    {
                        (!isInfo && props.id && props.user?.isVGroup)?
                        <>
                            <View style={{
                                    borderColor: '#e0dde3',
                                    borderTopWidth: 1,
                                    borderBottomWidth: 1,
                                    // flex: 1,
                                    marginBottom: 20,
                                    marginTop: 20,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                }}>
                                <TouchableOpacity style={styles.tab} onPress={() => setTab('Overview')}>
                                    <View style={[{elevation: tab=='Overview'?10:0, backgroundColor: tab=='Overview'?'#eae2f1':null, paddingHorizontal: width>=768?14:7, paddingVertical: width>=768?20:10}, styles.updateRequestTab]}>
                                        <MyText style={[width>=768?css.m:css.sm, {color: tab=='Overview'?'#522c75':'#919293'}]}>Edit</MyText>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.tab} onPress={() => (status == 'Validated' || status == 'Route Scheduled')?setTab('Route Update'):false}>
                                    <View style={[{elevation: tab=='Route Update'?10:0, backgroundColor: tab=='Route Update'?'#eae2f1':null, paddingHorizontal: width>=768?14:7, paddingVertical: width>=768?20:10}, styles.updateRequestTab]}>
                                        <MyText style={[width>=768?css.m:css.sm, {color: tab=='Route Update'?'#522c75':'#919293', textAlign: 'center'}]}>{width>=768?'Route Update':'Route\nUpdate'}</MyText>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.tab} onPress={() => status == 'Route Scheduled'?DriverArrival():false}>
                                    <View style={[{elevation: tab=='Arrival'?10:0, backgroundColor: tab=='Arrival'?'#eae2f1':null, paddingHorizontal: width>=768?14:7, paddingVertical: width>=768?20:10}, styles.updateRequestTab]}>
                                        <MyText style={[width>=768?css.m:css.sm, {color: tab=='Arrival'?'#522c75':'#919293', textAlign: 'center'}]}>{'Arrival'}</MyText>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.tab} onPress={() => (status == 'Driver Arrived' || status == 'Waste Collected')?setTab('Waste Collection'):false}>
                                    <View style={[{elevation: tab=='Waste Collection'?10:0, backgroundColor: tab=='Waste Collection'?'#eae2f1':null, paddingHorizontal: width>=768?14:7, paddingVertical: width>=768?20:10}, styles.updateRequestTab]}>
                                        <MyText style={[width>=768?css.m:css.sm, {color: tab=='Waste Collection'?'#522c75':'#919293', textAlign: 'center'}]}>{width>=768?'Waste Collection':'Waste\nCollection'}</MyText>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.tab} onPress={() => (status == 'Waste Collected' || status == 'Waste Segregated')?setTab('Waste Segregation'):false}>
                                    <View style={[{elevation: tab=='Waste Segregation'?10:0, backgroundColor: tab=='Waste Segregation'?'#eae2f1':null, paddingHorizontal: width>=768?14:7, paddingVertical: width>=768?20:10}, styles.updateRequestTab]}>
                                        <MyText style={[width>=768?css.m:css.sm, {color: tab=='Waste Segregation'?'#522c75':'#919293', textAlign: 'center'}]}>{width>=768?'Waste Segregation':'Waste\nSegregation'}</MyText>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </>:null
                    }
                    { tab=='Overview'? 
                    <>
                    {/* Ship Particulars */}
                    <View style={[{zIndex: 10}, css.form]} onLayout={(event) => {setDataCords0(event.nativeEvent.layout.y)}}>
                        <MyText style={[css.formTitle, css.bold]}>
                            Ship Particulars
                        </MyText>
                        <View
                            style={{
                                borderBottomColor: '#f3f1f8',
                                borderBottomWidth: 2,
                            }}
                        />
                        <View style={css.formContainer}>
                            {
                                (isInfo)?
                                <></>
                                :
                                <>
                                    {
                                        newShip?
                                        <>
                                            <View style={[css.formRow, {flexBasis: '100%', marginTop: 20, marginBottom: 15, justifyContent: 'space-between'}]}>
                                                <MyText style={[css.m, styles.contactPersonTitle]}>
                                                    New Ship
                                                </MyText>
                                                <View>
                                                    <MyCheckbox onClick={() => NewShipCheckbox()} checked={newShip} label='New?'/>
                                                </View>
                                            </View>
                                        </>
                                        :
                                        <>
                                            <View style={[css.formRow, {flexBasis: '100%', marginTop: 20, marginBottom: 15, justifyContent: 'space-between'}]}>
                                                <MyText style={[css.m, styles.contactPersonTitle]}>
                                                    Select existing Ship
                                                </MyText>
                                                <View>
                                                    <MyCheckbox onClick={() => NewShipCheckbox()} checked={newShip} label='New?'/>
                                                </View>
                                            </View>
                                            <View style={css.formRow}>
                                                <MyText style={css.formRowLabel}>
                                                IMO
                                                </MyText>
                                                <MyPicker
                                                    style={css.formRowInput}
                                                    items={ships?.map(x => parseInt(x.imo)).sort((a, b) => (a - b)).map(x => x.toString())}
                                                    onValueChange={(item, itemIndex) => {
                                                            ships.forEach(x => {
                                                                if(x.imo == item.value){
                                                                    setShipName(prev => ({
                                                                        ...prev,
                                                                        value: x.shipName.toString()
                                                                    }))
                                                                }
                                                            })
                                                            setImo(prev => ({
                                                                ...prev,
                                                                value: item.value
                                                            }))
                                                            GetShipByIMO(item.value);
                                                        }
                                                    }
                                                    value={imo.value}
                                                    showSearch={true}
                                                    placeholder={'IMO'}
                                                />
                                            </View>
                                            <View style={css.formRow}>
                                                <MyText style={css.formRowLabel}>
                                                    Ship Name
                                                </MyText>
                                                <MyPicker
                                                    style={css.formRowInput}
                                                    items={ships?.map(x => x.shipName.toString())}
                                                    onValueChange={(item, itemIndex) => {
                                                            ships.forEach(x => {
                                                                if(x.shipName == item.value){
                                                                    setImo(prev => ({
                                                                        ...prev,
                                                                        value: x.imo.toString()
                                                                    }))
                                                                }
                                                            })
                                                            setShipName(prev => ({
                                                                ...prev,
                                                                value: item.value
                                                            }))
                                                            GetShipByName(item.value);
                                                        }
                                                    }
                                                    value={shipName.value}
                                                    showSearch={true}
                                                    placeholder={'Ship Name'}
                                                />
                                            </View>
                                        </>
                                    }
                                </>   
                            }
                            {
                                (newShip || isInfo)?
                                <>
                                    <View style={css.formRow}>
                                        <MyText style={css.formRowLabel}>
                                        IMO
                                        </MyText>
                                        <MyTextInput
                                            inputMode='decimal'
                                            keyboardType='phone-pad'
                                            style={css.formRowInput}
                                            onChangeText={(e) => {
                                                    setImo(prev => ({
                                                        ...prev,
                                                        value: e
                                                    }))
                                                    GetShipByIMO(e);
                                                }
                                            }
                                            value={imo.value}
                                            hasError={imo.hasError}
                                            validation='imo'
                                            editable={props.id==undefined}
                                            isInfo={isInfo}
                                        />
                                    </View>
                                    <View style={css.formRow}>
                                        <MyText style={css.formRowLabel}>
                                        Ship Name
                                        </MyText>
                                        <MyTextInput
                                            style={css.formRowInput}
                                            onChangeText={(e) => {
                                                    setShipName(prev => ({
                                                        ...prev,
                                                        value: e
                                                    }))
                                                    GetShipByName(e);
                                                }
                                            }
                                            value={shipName.value}
                                            hasError={shipName.hasError}
                                            editable={props.id==undefined}
                                            isInfo={isInfo}
                                        />
                                    </View>
                                </>
                                :
                                <></>
                            }
                            <View style={[{zIndex: 10}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                Ship Type
                                </MyText>
                                <MyPicker
                                style={css.formRowInput}
                                //   items={['Container', 'Bulk', 'Tanker', 'Passenger', 'Naval', 'Offshore', 'Special Purpose']}
                                items={shipTypes}
                                onValueChange={(item, itemIndex) => setShipType(prev => ({
                                        ...prev,
                                        value: item.value
                                    }))}
                                    value={shipType.value}
                                    hasError={shipType.hasError}
                                    editable={shipEditable}
                                    isInfo={isInfo}
                                >
                                </MyPicker>
                            </View>
                            <View style={[{zIndex: 9}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                Gross Tonnage
                                </MyText>
                                <View style={[{flex: 1}, css.doubleInput]}>
                                <MyTextInput
                                    inputMode='decimal'
                                    keyboardType='phone-pad'
                                    style={[{flexGrow: 1, flexShrink: 1, marginRight: 5}, css.formRowInput]}
                                    onChangeText={(e) => setGrossTonnage(prev => ({
                                        ...prev,
                                        value: e
                                    }))}
                                    value={grossTonnage.value}
                                    type={grossTonnageType}
                                    hasError={grossTonnage.hasError}
                                    editable={shipEditable}
                                    isInfo={isInfo}
                                />
                                <View style={css.secondInput}>
                                    <MyPicker
                                        style={css.formRowInput}
                                        width={65}
                                        items={wasteMetrics}
                                        value={isInfo?'':grossTonnageType}
                                        onValueChange={(item, itemIndex) =>
                                            setGrossTonnageType(item.value)
                                        }
                                        editable={shipEditable}
                                        isInfo={isInfo}
                                    >
                                    </MyPicker>
                                </View>
                                </View>
                            </View>
                            <View style={[{zIndex: 8}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                GRT
                                </MyText>
                                <View style={[{flex: 1}, css.doubleInput]}>
                                <MyTextInput
                                    inputMode='decimal'
                                    keyboardType='phone-pad'
                                    style={[{flexGrow: 1, flexShrink: 1, marginRight: 5}, css.formRowInput]}
                                    onChangeText={(e) => setGrt(prev => ({
                                        ...prev,
                                        value: e
                                    }))}
                                    value={grt.value}
                                    type={grtType}
                                    hasError={grt.hasError}
                                    editable={shipEditable}
                                    isInfo={isInfo}
                                />
                                <View style={css.secondInput}>
                                    <MyPicker
                                        style={css.formRowInput}
                                        width={65}
                                        items={wasteMetrics}
                                        value={isInfo?'':grtType}
                                        onValueChange={(item, itemIndex) =>
                                            setGrtType(item.value)
                                        }
                                        editable={shipEditable}
                                        isInfo={isInfo}
                                    >
                                    </MyPicker>
                                </View>
                                </View>
                            </View>
                            <View style={[{zIndex: 7}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                DWT
                                </MyText>
                                <View style={[{flex: 1}, css.doubleInput]}>
                                <MyTextInput
                                    inputMode='decimal'
                                    keyboardType='phone-pad'
                                    style={[{flexGrow: 1, flexShrink: 1, marginRight: 5}, css.formRowInput]}
                                    onChangeText={(e) => setDwt(prev => ({
                                        ...prev,
                                        value: e
                                    }))}
                                    value={dwt.value}
                                    type={dwtType}
                                    hasError={dwt.hasError}
                                    editable={shipEditable}
                                    isInfo={isInfo}
                                />
                                <View style={css.secondInput}>
                                    <MyPicker
                                        style={css.formRowInput}
                                        width={65}
                                        items={wasteMetrics}
                                        value={isInfo?'':dwtType}
                                        onValueChange={(item, itemIndex) =>
                                            setDwtType(item.value)
                                        }
                                        editable={shipEditable}
                                        isInfo={isInfo}
                                    >
                                    </MyPicker>
                                </View>
                                </View>
                            </View>
                            <View style={[{zIndex: 6}, css.formRow]} onLayout={(event) => {setCountryFlagCords(event.nativeEvent.layout.y)}}>
                                <MyText style={css.formRowLabel}>
                                Country Flag
                                </MyText>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={countries}
                                    onValueChange={(item, itemIndex) => setCountryFlag(prev => ({
                                        ...prev,
                                        key: item.key,
                                        value: item.value
                                    }))}
                                    showSearch={true}
                                    value={countryFlag.value}
                                    hasError={countryFlag.hasError}
                                    editable={shipEditable}
                                    willShow={() => {
                                            scrollRef.current.scrollToPosition(0, countryFlagCords);
                                        }
                                    }
                                    isInfo={isInfo}
                                >
                                </MyPicker>
                            </View>
                            <View style={css.formRow}>
                                <MyText style={css.formRowLabel}>
                                Registry Office
                                </MyText>
                                <MyTextInput
                                style={css.formRowInput}
                                onChangeText={(e) => setRegistryOffice(prev => ({
                                    ...prev,
                                    value: e
                                }))}
                                value={registryOffice.value}
                                hasError={registryOffice.hasError}
                                isInfo={isInfo}
                                editable={shipEditable}
                                />
                            </View>
                            <View style={[css.formRow, {flexBasis: '100%', marginTop: 20, marginBottom: 15}]}>
                                <MyText style={[css.m, styles.contactPersonTitle]}>
                                    Contact Person
                                </MyText>
                            </View>
                            <View style={[{zIndex: 10}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                    First Name
                                </MyText>
                                <MyTextInput
                                    style={css.formRowInput}
                                    onChangeText={(e) => setContactPersonFName(prev => ({
                                        ...prev,
                                        value: e
                                    }))}
                                    value={contactPersonFName.value}
                                    hasError={contactPersonFName.hasError}
                                    isInfo={isInfo}
                                />
                            </View>
                            <View style={[{zIndex: 9}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                    Last Name
                                </MyText>
                                <MyTextInput
                                    style={css.formRowInput}
                                    onChangeText={(e) => setContactPersonLName(prev => ({
                                        ...prev,
                                        value: e
                                    }))}
                                    value={contactPersonLName.value}
                                    hasError={contactPersonLName.hasError}
                                    isInfo={isInfo}
                                />
                            </View>
                            <View style={[{zIndex: 9}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                    Profession
                                </MyText>
                                <MyTextInput
                                    style={css.formRowInput}
                                    onChangeText={(e) => setContactPersonProfession(prev => ({
                                        ...prev,
                                        value: e
                                    }))}
                                    value={contactPersonProfession.value}
                                    hasError={contactPersonProfession.hasError}
                                    isInfo={isInfo}
                                />
                            </View>
                            <View style={[{zIndex: 9}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                    Email
                                </MyText>
                                <MyTextInput
                                    style={css.formRowInput}
                                    onChangeText={(e) => setContactPersonEmail(prev => ({
                                        ...prev,
                                        value: e
                                    }))}
                                    value={contactPersonEmail.value}
                                    hasError={contactPersonEmail.hasError}
                                    inputMode='email'
                                    keyboardType='email-address'
                                    validation='email'
                                    isInfo={isInfo}
                                    autoCapitalize='none'
                                    autoCorrect={false}
                                />
                            </View>
                            <View style={[{zIndex: 9}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                    Phone
                                </MyText>
                                <MyTextInput
                                    inputMode='decimal'
                                    keyboardType='phone-pad'
                                    style={css.formRowInput}
                                    onChangeText={(e) => setContactPersonPhone(prev => ({
                                        ...prev,
                                        value: e
                                    }))}
                                    value={contactPersonPhone.value}
                                    hasError={contactPersonPhone.hasError}
                                    validation='phone'
                                    isInfo={isInfo}
                                />
                            </View>
                        </View>
                    </View>
                    {/* Port & Voyage Particulars */}
                    <View style={[{zIndex: 10}, css.form]} onLayout={(event) => {setDataCords1(event.nativeEvent.layout.y)}}>
                            <MyText style={[css.formTitle, css.bold]}>
                            Port &amp; Voyage Particulars
                            </MyText>
                            <View
                            style={{
                                borderBottomColor: '#f3f1f8',
                                borderBottomWidth: 2,
                            }}
                            />
                            <View style={css.formContainer}>
                            <View style={css.formRow}>
                                <MyText style={css.formRowLabel}>
                                Port of Delivery
                                </MyText>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={portOfDeliveries}
                                    onValueChange={(item, itemIndex) => {
                                        setPortOfDelivery(prev => ({
                                                ...prev,
                                                key: item.key,
                                                value: item.value
                                        }))
                                        setLocation(prev => ({
                                            ...prev,
                                            value: null
                                        }))
                                        }
                                    }
                                    value={portOfDelivery.value}
                                    hasError={portOfDelivery.hasError}
                                    isInfo={isInfo}
                                >
                                </MyPicker>
                            </View>
                            <View style={css.formRow}>
                                <MyText style={css.formRowLabel}>
                                Location
                                </MyText>
                                <MyPicker
                                    style={css.formRowInput}
                                    items={locations?.filter(loc => loc.port_id == portOfDelivery.key)}
                                    onValueChange={(item, itemIndex) => setLocation(prev => ({
                                        ...prev,
                                        value: item.value
                                    }))}
                                    value={location.value}
                                    hasError={location.hasError}
                                    isInfo={isInfo}
                                >
                                </MyPicker>
                            </View>
                            <View style={[{zIndex: 10}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                Agent
                                </MyText>
                                <MyPicker
                                    style={css.formRowInput}
                                    value={agent}
                                    items={portOfDelivery.key?((agentPerPort[portOfDelivery.key]&&agentPerPort[portOfDelivery.key].length>0)?agentPerPort[portOfDelivery.key]:agents):agents}
                                    onValueChange={(item, itemIndex) =>
                                        setAgent(item.value)
                                    }
                                    isInfo={isInfo}
                                >
                                </MyPicker>
                            </View>
                            <View style={[{zIndex: 9}, css.formRow]}>
                                <MyText style={css.formRowLabel} >
                                    Arrival
                                </MyText>
                                <View style={[{flex: 1}, css.doubleInput]}>
                                    <MyDateTimePicker 
                                        onChange={(e) => setArrival(prev => ({
                                            ...prev,
                                            value: e
                                        }))}
                                        value={arrival.value?new Date(arrival.value):null}
                                        hasError={arrival.hasError}
                                        isInfo={isInfo}
                                    />
                                </View>
                            </View>
                            <View style={[{zIndex: 8}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                Departure
                                </MyText>
                                <View style={[{flex: 1}, css.doubleInput]}>
                                    <MyDateTimePicker
                                        onChange={(e) => setDeparture(prev => ({
                                            ...prev,
                                            value: e
                                        }))}
                                        value={departure.value?new Date(departure.value):null}
                                        hasError={departure.hasError}
                                        isInfo={isInfo}
                                        arrival={arrival.value}
                                        validation='departure'
                                    />
                                </View>
                            </View>
                            <View style={[{zIndex: 7}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                Requested Delivery
                                </MyText>
                                <View style={[{flex: 1}, css.doubleInput]}>
                                    <MyDateTimePicker
                                        onChange={(e) => setRequestedDelivery(prev => ({
                                            ...prev,
                                            value: e
                                        }))}
                                        value={requestedDelivery.value?new Date(requestedDelivery.value):null}
                                        hasError={requestedDelivery.hasError}
                                        isInfo={isInfo}
                                        arrival={arrival.value}
                                        departure={departure.value}
                                        validation='requestedDelivery'
                                    />
                                </View>
                            </View>
                            <View style={[{zIndex: 6}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                Last Port
                                </MyText>
                                <MyTextInput
                                    style={css.formRowInput}
                                    onChangeText={(e) => setLastPort(prev => ({
                                        ...prev,
                                        value: e
                                    }))}
                                    value={lastPort.value}
                                    hasError={lastPort.hasError}
                                    isInfo={isInfo}
                                />
                            </View>
                            <View style={css.formRow}>
                                <MyText style={css.formRowLabel}>
                                Next Port
                                </MyText>
                                <MyTextInput
                                    style={css.formRowInput}
                                    onChangeText={(e) => setNextPort(prev => ({
                                        ...prev,
                                        value: e
                                    }))}
                                    value={nextPort.value}
                                    hasError={nextPort.hasError}
                                    isInfo={isInfo}
                                />
                            </View>
                            <View style={[css.formRow, {flexBasis: '100%', marginTop: 20, marginBottom: 15}]}>
                                <MyText style={[css.m, styles.personsOnBoardTitle]}>
                                    Number of Persons onboard
                                </MyText>
                            </View>
                            <View style={[{zIndex: 10}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                    Crew Members
                                </MyText>
                                <MyTextInput
                                    inputMode='decimal'
                                    keyboardType='phone-pad'
                                    style={css.formRowInput}
                                    onChangeText={(e) => {
                                            setPersonsOnBoardCrew(prev => ({
                                                ...prev,
                                                value: e
                                            }));
                                            setPersonsOnBoardTotal(prev => ({
                                                ...prev,
                                                value: ((parseInt(e)||0) + (parseInt(personsOnBoardPassengers.value)||0)).toString()
                                            }));
                                        }
                                    }
                                    value={personsOnBoardCrew.value}
                                    hasError={personsOnBoardCrew.hasError}
                                    isInfo={isInfo}
                                />
                            </View>
                            <View style={[{zIndex: 9}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                    Passengers
                                </MyText>
                                <MyTextInput
                                    inputMode='decimal'
                                    keyboardType='phone-pad'
                                    style={css.formRowInput}
                                    onChangeText={(e) => {
                                            setPersonsOnBoardPassengers(prev => ({
                                                ...prev,
                                                value: e
                                            }));
                                            setPersonsOnBoardTotal(prev => ({
                                                ...prev,
                                                value: ((parseInt(e)||0) + (parseInt(personsOnBoardCrew.value)||0)).toString()
                                            }));
                                        }
                                    }
                                    value={personsOnBoardPassengers.value}
                                    hasError={personsOnBoardPassengers.hasError}
                                    isInfo={isInfo}
                                />
                            </View>
                            <View style={[{zIndex: 10}, css.formRow]}>
                                <MyText style={css.formRowLabel}>
                                    Total
                                </MyText>
                                <MyTextInput
                                    inputMode='decimal'
                                    keyboardType='phone-pad'
                                    style={css.formRowInput}
                                    // onChangeText={(e) => setPersonsOnBoardTotal(prev => ({
                                    //     ...prev,
                                    //     value: e
                                    // }))}
                                    value={personsOnBoardTotal.value}
                                    hasError={personsOnBoardTotal.hasError}
                                    editable={false}
                                    isInfo={isInfo}
                                />
                            </View>
                        </View>
                    </View>
                    {/* Requested Collection Means*/}
                    <View style={[{zIndex: 9}, css.form]} onLayout={(event) => {setDataCords3(event.nativeEvent.layout.y)}}>
                        <RequestedCollectionMeans 
                            value={requestedCollectionMeans}
                            onChange={setRequestedCollectionMeans}
                            collectionMeans={collectionMeans}
                            purposes={requestedPurposes}
                            key={requestedCollectionMeansKey}
                            isInfo={isInfo}
                            arrival={arrival.value}
                            departure={departure.value}
                        />
                    </View>
                    {/* Type & Amount of Waste*/}
                    <View style={[{zIndex: 9}, css.form]} onLayout={(event) => {setDataCords2(event.nativeEvent.layout.y)}}>
                        <MyText style={[css.formTitle, css.bold]}>
                        Type &amp; Amount of Waste
                        </MyText>
                        <MyText style={[css.xxs, {display: hasCheckedAnnex?'none':'flex', color: 'red', textAlign: 'center', position: 'relative', top: -4}]}>
                            Please select at least one Waste Type and fill in all mandatory fields in red
                        </MyText>
                        <View
                        style={{
                            borderBottomColor: '#f3f1f8',
                            borderBottomWidth: 2,
                        }}
                        />
                        <View style={css.formContainer}>
                        <MyText style={[css.bold, css.formSubtitle]}>
                            Non-Hazardous
                        </MyText>
                        </View>
                        <AnnexINonHazardous wasteMetrics={wasteMetrics} hasOne={HasCheckedAnnex1Non(annex1non)} checked={props.id?HasCheckedAnnex1Non(annex1non):false} onChange={setAnnex1non} value={annex1non} key={annex1nonKey} isInfo={isInfo}/>
                        <AnnexIINonHazardous wasteMetrics={wasteMetrics} hasOne={HasCheckedAnnex2Non(annex2non)} checked={props.id?HasCheckedAnnex2Non(annex2non):false} onChange={setAnnex2non} value={annex2non} key={annex2nonKey} isInfo={isInfo}/>
                        <AnnexIVNonHazardous wasteMetrics={wasteMetrics} hasOne={HasCheckedAnnex4Non(annex4non)} checked={props.id?HasCheckedAnnex4Non(annex4non):false} onChange={setAnnex4non} value={annex4non} key={annex4nonKey} isInfo={isInfo}/>
                        <AnnexVNonHazardous wasteMetrics={wasteMetrics} hasOne={HasCheckedAnnex5Non(annex5non)} checked={props.id?HasCheckedAnnex5Non(annex5non):false} onChange={setAnnex5non} value={annex5non} key={annex5nonKey} isInfo={isInfo}/>
                        <AnnexVINonHazardous wasteMetrics={wasteMetrics} hasOne={HasCheckedAnnex6Non(annex6non)} checked={props.id?HasCheckedAnnex6Non(annex6non):false} onChange={setAnnex6non} value={annex6non} key={annex6nonKey} isInfo={isInfo}/>
                        <View
                        style={{
                            borderBottomColor: '#f3f1f8',
                            borderBottomWidth: 2,
                        }}
                        />
                        <View style={css.formContainer}>
                        <MyText style={[css.bold, css.formSubtitle]}>
                            Hazardous
                        </MyText>
                        </View>
                        <AnnexIHazardous wasteMetrics={wasteMetrics} hasOne={HasCheckedAnnex1(annex1)} checked={props.id?HasCheckedAnnex1(annex1):false} onChange={setAnnex1} value={annex1} key={annex1Key} isInfo={isInfo}/>
                        <AnnexIIHazardous wasteMetrics={wasteMetrics} hasOne={HasCheckedAnnex2(annex2)} checked={props.id?HasCheckedAnnex2(annex2):false} onChange={setAnnex2} value={annex2} key={annex2Key} isInfo={isInfo}/>
                        <AnnexIVHazardous wasteMetrics={wasteMetrics} hasOne={HasCheckedAnnex4(annex4)} checked={props.id?HasCheckedAnnex4(annex4):false} onChange={setAnnex4} value={annex4} key={annex4Key} isInfo={isInfo}/>
                        <AnnexVHazardous wasteMetrics={wasteMetrics} hasOne={HasCheckedAnnex5(annex5)} checked={props.id?HasCheckedAnnex5(annex5):false} onChange={setAnnex5} value={annex5} key={annex5Key} isInfo={isInfo}/>
                        <AnnexVIHazardous wasteMetrics={wasteMetrics} hasOne={HasCheckedAnnex6(annex6)} checked={props.id?HasCheckedAnnex6(annex6):false} onChange={setAnnex6} value={annex6} key={annex6Key} isInfo={isInfo}/>
                    </View>
                    {/* Comments */}
                    <View style={[{zIndex: 9}, css.form]}>
                        <MyText style={[css.formTitle, css.bold]}>
                        Comments
                        </MyText>
                        <View
                        style={{
                            borderBottomColor: '#f3f1f8',
                            borderBottomWidth: 2,
                        }}
                        />
                        <View style={css.commentsContainer}>
                            <MyTextarea
                                style={[{flexGrow: 1, flexShrink: 0, marginRight: 5}, css.formRowInput]}
                                onChangeTextImmediate={(e) => Platform.OS!='web'?setComments(e):false}
                                onChangeText={setComments}
                                value={comments}
                                placeholder="Please provide any additional information regarding your request"
                                isInfo={isInfo}
                            />
                        </View>
                    </View>
                    {
                        !isInfo?
                        <TouchableOpacity onPress={() => Submit()} style={{alignItems: 'center', marginBottom: 20}}>
                            <MyText style={[css.button, css.bold]}>
                                {props.id?'Update':'Submit'}
                            </MyText>
                        </TouchableOpacity>
                        :null
                    }
                    {
                        (status === 'Submitted' && props.user.isVGroup)?
                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 30}}>
                            <TouchableOpacity onPress={() => Approve(props.id)}>
                                <MyText style={[styles.statusAction, {backgroundColor: '#23a43f', height: 24, lineHeight: Platform.OS==='android'?20:26}]}>
                                    Validate
                                </MyText>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => Reject(props.id)}>
                                <MyText style={[styles.statusAction, {backgroundColor: '#e32112', height: 24, lineHeight: Platform.OS==='android'?20:24}]}>
                                    Reject
                                </MyText>
                            </TouchableOpacity>
                        </View>
                        :null
                    }
                </>:null
                }
                {
                    tab=='Progress'?
                    <Progress data={progressData} location={location} portOfDelivery={portOfDelivery}/>
                    :null
                }
                {
                    tab=='Route Update'?
                    <RouteUpdate id={props.id} navigation={props.navigation} requestedDelivery={requestedDelivery.value} value={(routeUpdateData&&routeUpdateData.length>0)?routeUpdateData:requestedCollectionMeans} onChange={setRouteUpdateData} collectionMeans={collectionMeans} companyRepresentatives={companyRepresentatives} purposes={requestedPurposes} key={requestedCollectionMeansKey} isInfo={isInfo}/>
                    :null
                }
                {
                    tab=='Waste Collection'?
                    <WasteCollection 
                        id={props.id}
                        navigation={props.navigation}
                        comments={comments}
                        data={wasteCollectionData}
                        erp_data={erpWasteCollectionData}
                        annex1={annex1}
                        annex2={annex2}
                        annex4={annex4}
                        annex5={annex5}
                        annex6={annex6}
                        annex1non={annex1non}
                        annex2non={annex2non}
                        annex4non={annex4non}
                        annex5non={annex5non}
                        annex6non={annex6non}
                        isInfo={isInfo}
                    />
                    :null
                }
            </View>
            {/* </TouchableWithoutFeedback> */}
        </KeyboardAwareScrollView>
        <MyReasonModal enabled={modalOpen} body='Please provide a reason for the Request Rejection' close={() => setModalOpen(false)} callback={modalCallback}/>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  personsOnBoardTitle: {
    color: '#461b6f',
    // paddingVertical: 15,
  },
  contactPersonTitle: {
    color: '#461b6f',
    // paddingHorizontal: 15,
  },
  tab: {
    height: '100%'
  },
  updateRequestTab: {
    flex: 1,
    justifyContent: 'center',
  },
  statusAction: {
    width: 75,
    textAlign: 'center',
    paddingTop: 0,
    paddingBottom: 4,
    borderRadius: 5,
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    overflow: 'hidden',
  }
});

export default NewRequest
import React from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    Platform
  } from 'react-native'
import stylesheet from './Style'
import MyText from './MyText';
import MyTextInput from './MyTextInput';
import axios from 'axios';

import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from "@react-native-community/netinfo";
import { useModal } from './hooks/modal';

const Login = (props) => {
    const css = stylesheet();
    const modal = useModal();

    const [type, setType] = React.useState('PRF');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const LinkTo = (to) => {
        if(Platform.OS==='web'){
            document.documentElement.scrollTop = 0;
        }
        props.navigation.push(to);
    }

    const Login = async() => {
        NetInfo.fetch().then(async(state) => {
            if(!state.isConnected){
                modal.confirm.show('Warning', 'You need to be online to login. Please check your internet connection.');
                return;
            }
            const data = {
                email,
                password,
                uuid: await AsyncStorage.getItem('uuid')
            };
            console.log(data);
            axios.post('/api/user/login.php', data)
            .then(async(res) => {
                props.setUser(res.data);
                await AsyncStorage.setItem('token', res.data.token);
                await AsyncStorage.setItem('user', JSON.stringify(res.data));
                if(Platform.OS==='web'){
                    document.documentElement.scrollTop = 0;
                }
                if(!res.data.isVGroup){
                    setTimeout(() => {
                        props.navigation.reset({
                            index: 1,
                            routes: [{ name: 'My Requests' }],
                        });  
                    },100)
                }
                else{
                    console.log('vgroup');
                    setTimeout(() => {
                        props.navigation.reset({
                            index: 1,
                            routes: [{ name: 'Manage Requests' }],
                        });  
                    },100)
                }
            })
            .catch((err) => {
                console.log(err);
                if(err.response?.status == 401){
                    modal.confirm.show('Warning', err.response.data);
                }
                else{
                    modal.confirm.show('Warning', 'An internal error occured. Please try again later or contact us.');
                }
            })    
        })
    }

  return (
    <View style={{alignItems: 'center', justifyContent: 'center', marginTop: Platform.OS==='web'?18:15, marginBottom: Platform.OS==='web'?13:0}}>
        <View style={{width: 232}}>
            <MyText style={[css.title, css.bold]}>
                Login
            </MyText>
            <View style={{marginTop: 18, flexDirection: 'row'}}>
                <MyTextInput
                    style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
                    onChangeTextImmediate={setEmail}
                    value={email}
                    inputMode='email'
                    keyboardType='email-address'
                    autoCapitalize='none'
                    autoCorrect={false}
                    placeholder='Email'
                    fancyPlaceholder={true}
                    validation='email'
                    autoComplete='off'
                />
            </View>
            <View style={{marginTop: 18, flexDirection: 'row'}}>
                <MyTextInput
                    style={[{flexGrow: 0, flexShrink: 0, flexBasis: 232}, css.formRowInput]}
                    onChangeTextImmediate={setPassword}
                    value={password}
                    secureTextEntry={true}
                    placeholder='Password'
                    fancyPlaceholder={true}
                    autoCapitalize='none'
                    autoCorrect={false}
                    autoComplete='off'
                />
            </View>
            <View style={{marginTop: 18, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <TouchableOpacity onPress={() => Login()} style={{flexGrow: 0, flexShrink: 0, flexBasis: 110, marginRight: 10}}>
                    <MyText style={[css.button, css.bold]}>
                        Login
                    </MyText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => props.ForgotPassword()} style={{flexGrow: 0, flexShrink: 0}}>
                    <MyText style={[css.bold, css.xs, {color: '#461b6f'}]}>
                        Forgot Password?
                    </MyText>
                </TouchableOpacity>
            </View>
            <View style={{marginTop: 15, alignItems: 'center'}}>
                <TouchableOpacity onPress={() => LinkTo('Register')} style={{flexGrow: 0, flexShrink: 0}}>
                    {/* <View style={{borderStyle: Platform.OS==='ios'?'solid':'dotted', borderColor: '#461b6f', borderBottomWidth: 1}}> */}
                        {/* <MyText style={[css.bold, {fontSize: 10, color: '#461b6f', paddingBottom: 3}]}>
                            Register and Join Us!
                        </MyText> */}
                    {/* </View> */}
                    <View style={{ overflow: 'hidden' }}>
                        <View
                        style={{
                            borderStyle: 'dashed',
                            borderWidth: 1,
                            borderColor: '#461b6f',
                            margin: -2,
                            marginBottom: 10,
                            paddingHorizontal: 1
                        }}>
                        {/* https://stackoverflow.com/questions/73506925/when-trying-to-add-a-dashed-border-style-getting-unsupported-dashed-dotted-b */}
                        <MyText style={[css.bold, css.s, {color: '#461b6f', paddingBottom: 3, textAlign: 'center'}]}>
                            Register and Join Us!
                        </MyText>
                        {/* <View style={{ height: 1, width: 200 }} /> */}
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    </View>
  );
}

const styles = StyleSheet.create({

});

export default Login
import React from 'react';
import {
    Linking,
    Platform,
    View
} from 'react-native'
import { ScrollView } from 'react-native-gesture-handler';
import stylesheet from './Style'
import MyText from './MyText';

const Terms = (props) => {
    const css = stylesheet();

    const LinkTo = (to) => {
        if(Platform.OS==='web'){
            document.documentElement.scrollTop = 0;
        }
        props.navigation.push(to);
    }
    
  return (
    <ScrollView vertical nestedScrollEnabled={true} keyboardShouldPersistTaps={'handled'} contentContainerStyle={{flexShrink: 0, flexGrow: 1}} scrollEnabled={true}>
    {/* <TouchableWithoutFeedback> */}
        <View style={[css.background, css.formBackground]}>
            <View style={[{zIndex: 10}, css.form]}>
                <MyText style={[css.formTitle, css.bold]}>
                    Terms of Use
                </MyText>
                <View
                style={{
                    borderBottomColor: '#f3f1f8',
                    borderBottomWidth: 2,
                }}
                />
                <View style={{paddingVertical: 20}}>
                    <MyText style={css.p}>
                        Effective Date: 28 Sep 2023
                    </MyText>
                    <MyText style={css.p}>
                        Welcome to FOOTPRINT! These Terms of Use (&quot;Terms&quot;) govern your access to and use of the
                        FOOTPRINT web platform and mobile application (collectively referred to as the &quot;Service&quot;).
                        Please read these Terms carefully before using the Service. By accessing or using the Service,
                        you agree to comply with and be bound by these Terms. If you do not agree to these Terms,
                        please do not use the Service.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        1. Acceptance of Terms
                    </MyText>
                    <MyText style={css.p}>
                        By accessing or using the Service, you represent and warrant that you are at least 18 years of
                        age and have the legal capacity to enter into these Terms. If you are using the Service on
                        behalf of an organization, you represent and warrant that you have the authority to bind
                        that organization to these Terms.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        2. Privacy Policy
                    </MyText>
                    <MyText style={css.p}>
                        Your use of the Service is also governed by our Privacy Policy, which can be found at <MyText onPress={() => LinkTo('Privacy')} style={{color: 'blue', textDecorationLine: 'underline'}}>Privacy Policy</MyText>. Please review the Privacy Policy to understand our data collection and usage
                        practices.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        3. User Accounts
                    </MyText>
                    <MyText style={css.p}>
                        3.1. Registration: In order to access certain features of the Service, you may be required to
                        create a user account. You agree to provide accurate, current, and complete information
                        during the registration process and to update such information to keep it accurate, current,
                        and complete.
                    </MyText>
                    <MyText style={css.p}>
                        3.2. Security: You are responsible for maintaining the confidentiality of your account login
                        information and for all activities that occur under your account. You agree to immediately
                        notify us of any unauthorized use of your account or any other breach of security.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        4. Use of the Service
                    </MyText>
                    <MyText style={css.p}>
                        4.1. License: Subject to these Terms, we grant you a limited, non-exclusive, non-transferable,
                        and revocable license to use the Service for your personal, non-commercial use.
                        4.2. Restrictions: You agree not to:
                    </MyText>
                    <MyText style={css.p}>
                        Use the Service for any unlawful purpose or in violation of any applicable laws or
                        regulations.
                    </MyText>
                    <MyText style={css.p}>
                        Modify, adapt, translate, reverse engineer, decompile, or disassemble any part of the
                        Service.
                    </MyText>
                    <MyText style={css.p}>
                        Attempt to gain unauthorized access to any portion of the Service.
                    </MyText>
                    <MyText style={css.p}>
                        Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation
                        with a person or entity.
                    </MyText>
                    <MyText style={css.p}>
                        Interfere with or disrupt the Service or servers or networks connected to the Service.
                        Use the Service in a manner that could harm, disable, overburden, or impair our servers or
                        networks.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        5. Content
                    </MyText>
                    <MyText style={css.p}>
                        5.1. User-Generated Content: You may submit content to the Service, including but not
                        limited to text, photos, and other materials (&quot;User Content&quot;). You retain ownership of your
                        User Content, but you grant us a non-exclusive, worldwide, royalty-free license to use,
                        reproduce, modify, adapt, publish, distribute, and display your User Content for the purpose
                        of providing the Service.
                    </MyText>
                    <MyText style={css.p}>
                        5.2. Prohibited Content: You agree not to submit or post any User Content that is illegal,
                        infringing, or violates the rights of any third party, including intellectual property rights,
                        privacy rights, or rights of publicity.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        6. Termination
                    </MyText>
                    <MyText style={css.p}>
                        We reserve the right to suspend or terminate your access to the Service at our sole
                        discretion, without notice, for any reason, including if you violate these Terms or for any
                        other reason we deem appropriate.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        7. Changes to Terms
                    </MyText>
                    <MyText style={css.p}>
                        We may modify these Terms at any time by posting the revised Terms on the Service. Your
                        continued use of the Service after such modifications will constitute your acceptance of the
                        modified Terms.
                    </MyText>
                    <MyText style={[css.p, css.bold]}>
                        8. Contact Us
                    </MyText>
                    <MyText style={css.p}>
                        If you have any questions or concerns about these Terms, please contact us at <MyText onPress={() => Linking.openURL('mailto:support@mdata.gr')} style={{color: 'blue', textDecorationLine: 'underline'}}>support@mdata.gr</MyText>.
                    </MyText>
                    <MyText style={css.p}>
                        Thank you for using FOOTPRINT. Enjoy your experience!
                    </MyText>
                </View>
            </View>
        </View>
     </ScrollView>
  );
}

export default Terms

import React from 'react';
import { StyleSheet, Text, View, ScrollView, TextInput, TouchableWithoutFeedback, Dimensions, ActivityIndicator, useWindowDimensions } from 'react-native';
import MyText from './MyText';
import stylesheet from './Style';

export default function Progress(props) {
    const css = stylesheet();
    const {height, width} = useWindowDimensions();
    
    const FormatDatetime = (dt) => {
        if(!dt)
            return '';
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const padLeft = (num) => {
            if(num < 10)
                return '0'+num;
            return num;
        }
        const date = new Date(dt);
        return padLeft(date.getDate()) + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ' - ' + padLeft(date.getHours()) + ':' + padLeft(date.getMinutes());
    }

    const IsLast = (data, idx) => {
        if(!data[idx].updated_at){ //We are already past the last
            return -1;
        }
        if(!data[idx+1] || !data[idx+1].updated_at){ //This is the last
            return 1;
        }
        return 0;
    }

    const render = (row) => {
        if(row.status == 'Route Scheduled'){
            return <>
                <MyText style={[styles.infoText, css.bold, css.m]}>{row.status}</MyText>
                {
                    row.scheduledFor ?
                    <MyText style={styles.infoText}>{'For: ' + FormatDatetime(row.scheduledFor)}</MyText>
                    : null
                }
                {
                    row.drivers ?
                    <MyText style={styles.infoText}>{'Driver: '+row.drivers}</MyText>
                    : null
                }
                {
                    row.updated_by ?
                    <MyText style={styles.infoText}>{'By: '+row.updated_by}</MyText>
                    : null
                }
                {
                    row.updated_at ?
                    <MyText style={styles.infoText}>{'On: ' + FormatDatetime(row.updated_at)}</MyText>
                    : null
                }
            </>
        }
        else if(row.status == 'Driver Arrived'){
            return <>
                <MyText style={[styles.infoText, css.bold, css.m]}>{row.status}</MyText>
                {
                    props.location ?
                    <MyText style={styles.infoText}>{'At: ' + props.location.value + ' of ' + props.portOfDelivery.value}</MyText>
                    : null
                }
                {
                    row.drivers ?
                    <MyText style={styles.infoText}>{row.drivers}</MyText>
                    : null
                }
                <MyText style={styles.infoText}>{'Tel.: 1234567890'}</MyText>
                {
                    row.updated_by ?
                    <MyText style={styles.infoText}>{'By: '+row.updated_by}</MyText>
                    : null
                }
                {
                    row.updated_at ?
                    <MyText style={styles.infoText}>{'On: ' + FormatDatetime(row.updated_at)}</MyText>
                    : null
                }
            </>
        }
        else if(row.status == 'Waste Collected'){
            return <>
                <MyText style={[styles.infoText, css.bold, css.m]}>{row.status}</MyText>
                {
                    row.waste_collection ?
                    <MyText style={styles.infoText}>{'On: ' + FormatDatetime(row.waste_collection.collectedOn)}</MyText>
                    : null
                }
                {
                    row.drivers ?
                    <MyText style={styles.infoText}>{'By: '+row.drivers}</MyText>
                    : null
                }
            </>
        }
        else{
            return <>
                <MyText style={[styles.infoText, css.bold, css.m]}>{row.status}</MyText>
                {
                    row.updated_at ?
                    <MyText style={styles.infoText}>{FormatDatetime(row.updated_at)}</MyText>
                    : null
                }
                {
                    row.updated_by ?
                    <MyText style={styles.infoText}>{(row.drivers?('Driver: '+row.drivers):('By: '+row.updated_by))}</MyText>
                    : null
                }
            </>
        }
    }

  return (
    <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <View style={{marginTop: 10, width: '100%', maxWidth: 600}}>
            {
                props.data.map((row, idx) => (
                    <View style={styles.container}>
                        <View>
                            <View style={[styles.numberContainer, {backgroundColor: IsLast(props.data, idx)===-1?'#cfbfde':'#522c75'}]}>
                                <MyText style={[css.ml, styles.numberText, css.bold]}>{idx+1}</MyText>
                            </View>
                            {   idx != props.data.length-1 &&
                                <View style={[styles.line, {height: '100%', backgroundColor: IsLast(props.data, idx)===-1?'#cfbfde':'#522c75'}]}></View>
                            }
                        </View>
                        <View style={[IsLast(props.data, idx)===1?styles.infoContainerLast:styles.infoContainer, {opacity: IsLast(props.data, idx)===-1?0.3:1, paddingVertical: width>=768?14:7}]}>
                            {
                                render(row)
                            }
                        </View>
                    </View>
                ))
            }
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginBottom: 30
    },
    numberContainer: {
        borderRadius: 35,
        overflow: 'hidden',
        width: 40,
        height: 40,
        backgroundColor: '#522c75',
        justifyContent: 'center',
        alignItems: 'center'
    },
    numberText: {
        color: 'white',
        marginTop: -3
    },
    line: {
        width: 1,
        backgroundColor: '#522c75',
        position: 'absolute',
        left: 20,
        top: 40
    },
    infoContainer: {
        borderColor: '#ece5f3',
        borderWidth: 1,
        paddingHorizontal: 14,
        marginLeft: 10,
        flex: 1,
        backgroundColor: 'white'
    },
    infoContainerLast: {
        borderColor: '#ece5f3',
        borderWidth: 1,
        paddingHorizontal: 14,
        marginLeft: 10,
        flex: 1,
        backgroundColor: '#e3d8ec'
    },
    infoText: {
        color: '#522c75',
        marginBottom: 5
    }
});

import { StyleSheet, useWindowDimensions } from 'react-native';

const stylesheet = () => {
  const {height, width} = useWindowDimensions();

  return StyleSheet.create({
    regular: {
        fontFamily: 'Comfortaa-Regular',
    },
    bold: {
        fontFamily: 'Comfortaa-Bold',
    },
    form: {
        backgroundColor: 'white',
        marginBottom: 25
      },
    formContainer: {
        paddingVertical: 20,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingHorizontal: width>=576?30:15,
      },
      columnFormContainer: {
        paddingHorizontal: 15,
        paddingVertical: 20,
        flex: 1
      },
      commentsContainer: {
        paddingVertical: 20,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingHorizontal: (width>=576?30:15) + (width>=576?30:0),
        // marginHorizontal: width>=1400?160:(width>=1200?130:110)
      },
      formTitle: {
        color: '#461b6f',
        textAlign: 'center',
        padding: 12,
        fontSize: width>=768?18:16
      },
      formSubtitle: {
        color: '#461b6f',
        textDecorationLine: 'underline'
      },
      formRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 18,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: width>=768?'50%':'100%',
        paddingHorizontal: width>=1200?30:(width>=576?10:0),
      },
      formRowNarrow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 15,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: width>=768?'50%':'100%',
        paddingHorizontal: width>=1200?30:(width>=576?10:0),
      },
      formTextareaRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '100%',
        paddingHorizontal: width>=1200?30:(width>=576?10:0),
      },
      doubleInput: {
        display: 'flex',
        flexDirection: 'row',
      },
      secondInput: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 65
      },
      formRowLabel: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: width>=1400?160:(width>=1200?130:110),
        paddingRight: 10
      },
      formRowInput: {
        flex: 1,
        width: '100%',
      },
      title: {
        color: '#461b6f',
        marginBottom: 3,
        fontSize: width>=768?24:22,
      },
      subtitle: {
        marginTop: 5,
        marginBottom: 15,
      },
      background: {
        flex: 1,
        backgroundColor: '#F6F4F9',
        paddingTop: 15,
        paddingHorizontal: 20,
        paddingBottom: 70,
        overflow: 'visible'
      },
      formBackground: {
        paddingHorizontal: (width>=950&&width<1006)?90:(width>=1300?90:20),
      },
      button: {
        paddingVertical: 10,
        paddingHorizontal: 30,
        backgroundColor: '#461b6f',
        color: 'white',
        borderRadius: 12,
        overflow: 'hidden',
        textAlign: 'center'
      },
      smallButton: {
        // flex: 1,
        height: 35,
        // lineHeight: 33,
        justifyContent: 'center',
        alignItems: 'center',
        // paddingHorizontal: 20,
        // paddingVertical: 10,
        borderColor: '#461b6f',
        color: '#461b6f',
        borderWidth: 0.5,
        backgroundColor: 'white',
        borderRadius: 8,
        // textAlign: 'center',
        overflow: 'hidden',
        cursor: 'pointer'
      },
      smallButtonText: {
        textAlign: 'center',
        // paddingBottom: 5
      },
      filterButton: {
          marginRight: 10,
          marginBottom: 10,
          flexBasis: 125,
          flexGrow: 0,
          flexShrink: 0,
          // height: 33,
      },
      fullFilterButton: {
          marginRight: 10,
          marginBottom: 10,
          flexBasis: 260,
          flexGrow: 0,
          flexShrink: 0
      },
      p: {
        flex: 1,
        marginBottom: 18,
        paddingHorizontal: width>=1200?30:(width>=576?10:0),
        lineHeight: 22
      },

      xxs: {
        fontSize: width>=768?9:7
      },
      xs: {
        fontSize: width>=768?10:8
      },
      s: {
        fontSize: width>=768?12:10
      },
      sm: {
        fontSize: width>=768?14:12
      },
      m: {
        fontSize: width>=768?16:14
      },
      ml: {
        fontSize: width>=768?18:16
      },
      l: {
        fontSize: width>=768?20:18
      },
      xl: {
        fontSize: width>=768?24:22
      },
      xxl: {
        fontSize: width>=768?34:34
      },
  });
}
export default stylesheet;
import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, View, TextInput, TouchableOpacity } from 'react-native';
import Collapsible from 'react-native-collapsible';
import MyCheckbox from '../MyCheckbox';
import MyPicker from '../MyPicker';
import MyText from '../MyText';
import MyTextarea from '../MyTextarea';
import MyTextInput from '../MyTextInput';
import stylesheet from '../Style'
import RequestedCollectionMeansForm from './RequestedCollectionMeansForm';
import { AntDesign } from '@expo/vector-icons';

const RequestedCollectionMeans = (props) => {
    const css = stylesheet();
    
    const HasDefaultValue = () => {
        if(props.value.length == 0){
            return false;
        }
        let found = false;
        props.value.forEach(row => {
            if(row.active){
                if(row.collectionMean || row.purpose || row.from || row.to){
                    found = true;
                }
            }
        });
        return found;
    }
    const [collapsed, setCollapsed] = React.useState(!HasDefaultValue());
    const [requestedCollectionMeans, setRequestedCollectionMeans] = React.useState(HasDefaultValue()?props.value:(props.isInfo?[]:[{}]));//props?.value??[{}]);

    React.useEffect(() => {
        onChange();
    }, [requestedCollectionMeans]);

    const onChange = () => {
        props.onChange(requestedCollectionMeans);
    }

    return (
        <View style={styles.reqContainer}>
            <TouchableOpacity activeOpacity={0.7} onPress={() => {setCollapsed(prev => !prev)}}>
                <MyText style={[styles.reqTitle, css.m]}>Requested Collection Means</MyText>
                <View style={{position: 'absolute', right: 10, top: 14}}>
                    <AntDesign name="down" size={12} color="#461b6f" style={{transform: [{rotate: collapsed?'0deg':'180deg'}]}}/>
                </View>
            </TouchableOpacity>
            { 
                !collapsed &&
                <View style={{display: collapsed?'none':'flex'}}>
                    <View style={[{zIndex: 9}]}>
                        <View
                        style={{
                            borderBottomColor: '#f3f1f8',
                            borderBottomWidth: 2,
                        }}
                        />
                        <View style={css.columnFormContainer}>
                            {
                                requestedCollectionMeans.map((mean, i) => (
                                    <RequestedCollectionMeansForm onChange={(e) => {
                                        const newAr = requestedCollectionMeans.slice(); //copy array
                                        newAr[i] = e;
                                        let hasActive = false;
                                        newAr.forEach(re => {
                                            if(re.active){
                                                hasActive = true;
                                            }
                                        })
                                        if(!hasActive){ //If we deleted everything, add a new one
                                            newAr.push({});
                                        }
                                        setRequestedCollectionMeans(newAr);
                                    }} value={mean} arrival={props.arrival} departure={props.departure} collectionMeans={props.collectionMeans} purposes={props.purposes} key={i} id={i} isInfo={props.isInfo}/>
                                ))
                            }
                            {
                                !props.isInfo &&
                                <TouchableOpacity onPress={() => setRequestedCollectionMeans(current => [...current, {}])} style={{alignItems: 'flex-start', marginBottom: 0}}>
                                    <MyText style={[{textDecorationLine: 'underline', color: '#461b6f'}, css.bold]}>
                                        + Add more
                                    </MyText>
                                </TouchableOpacity>
                            }
                        </View>
                    </View>
                </View>
            }
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        paddingVertical: 10,
        paddingHorizontal: 14,
        paddingBottom: 0,
        borderColor: '#532b75',
        borderWidth: 0.5,
        borderRadius: 20,
        marginBottom: 15,
        overflow: 'hidden',
    },
    reqContainer: {
        marginBottom: 0,
        backgroundColor: 'white'
    },
    reqTitle: {
        backgroundColor: '#e9e3f0',
        color: '#57307e',
        textAlign: 'center',
        width: '100%',
        padding: 10
    },
})
export default RequestedCollectionMeans
import React from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import MyText from '../MyText';
import MyTextarea from '../MyTextarea';
import MyTextInput from '../MyTextInput';
import stylesheet from '../Style'

import axios from 'axios';
import MyDatetimePicker from '../MyDatetimePicker';
import { useModal } from '../hooks/modal';
import { Keyboard } from 'react-native';

const WasteCollection = (props) => {
    const css = stylesheet();
    const modal = useModal();
    
    const annexMap = {
        'annex1': 'ANNEX I - Oily Waste',
        'annex2': 'ANNEX II - NLS in bulk',
        'annex4': 'ANNEX IV - Sewage',
        'annex5': 'ANNEX V - Operational Hazardous',
        'annex6': 'ANNEX VI - Related',
        'annex1non': 'ANNEX I - Oily Waste',
        'annex2non': 'ANNEX II - NLS in bulk',
        'annex4non': 'ANNEX IV - Sewage',
        'annex5non': 'ANNEX V - Garbage',
        'annex6non': 'ANNEX VI - Related',
    };
    const nameMap = {
        'annex1': {
            'oily': 'High Viscosity Oily Sediments',
            'other': 'Other Substances'
        },
        'annex2': {
            'categoryX': 'Category X Substance',
            'categoryY': 'Category Y Substance',
            'categoryZ': 'Category Z Substance',
            'other': 'Other',
        },
        'annex4': {
            'sewage': 'Sewage',
        },
        'annex5': {
            'paint': 'Paint & varnish residues',
            'printing': 'Waste printing toner',
            'photo': 'Photo waste',
            'med': 'Expired Medicine',
            'leadBat': 'Lead batteries',
            'otherBat': 'Other batteries & Accumulators',
            'dangerous': 'Packaging contaminated by dangerous substances',
            'oilyRags': 'Oily Rags',
            'other': 'Other substances',
        },
        'annex6': {
            'ozone': 'Ozone depleting substances and equipment containing such substances',
            'exhaust': 'Exhaust Gas - Cleaning residues'
        },
        'annex1non': {
            'oilyBilgeWater': 'Oily Bilge Water',
            'oilyResidues': 'Oily Residues - Sludge',
            'oilyTank': 'Oily tank water - Slops',
            'dirtyBallast': 'Dirty ballast water',
            'scale': 'Scale and sludge from tank cleaning',
            'other': 'Other',
        },
        'annex2non': {
            'categoryX': 'Category X Substance',
            'categoryY': 'Category Y Substance',
            'categoryZ': 'Category Z Substance',
            'other': 'Other',
        },
        'annex4non': {
            'sewage': 'Sewage',
        },
        'annex5non': {
            'plastic': 'Plastic',
            'foodWaste': 'Food waste/International Catering Waste',
            'domesticWaste': 'Domestic Waste',
            'cookingOil': 'Cooking Oil',
            'incineratorAsh': 'Incinerator Ash',
            'operationalWaste': 'Operational Waste',
            'animalCarcasses': 'Animal Carcasses',
            'fishingGear': 'Fishing Gear',
            'eWaste': 'e-Waste',
            'cargo': 'Cargo residues - non-HME',
            'other': 'Other Substances',
        },
        'annex6non': {
            'ozone': 'Ozone depleting substances and equipment containing such substances',
            'exhaust': 'Exhaust Gas - Cleaning residues'
        },
    };
    const [submit, setSubmit] = React.useState(false);

    const [data, setData] = React.useState({});
    const [comments, setComments] = React.useState('');
    const [collectedOn, setCollectedOn] = React.useState(new Date());

    React.useEffect(() => {
        if(props.data){
            if(props.data){
                setData(props.data);
                if(props.data?.comments)
                    setComments(props.data.comments);
                if(props.data?.collectedOn)
                    setCollectedOn(props.data.collectedOn);
            }
            return;
        }
        let tempData = {};
        Object.keys(props.annex1).forEach((key, index) => {
            const annex = 'annex1';
            if(props[annex][key].active){
                if(!tempData[annex]){
                    tempData[annex] = {};
                }
                tempData[annex][key] = '';
            }
        })
        Object.keys(props.annex2).forEach((key, index) => {
            const annex = 'annex2';
            if(props[annex][key].active){
                if(!tempData[annex]){
                    tempData[annex] = {};
                }
                tempData[annex][key] = '';
            }
        })
        Object.keys(props.annex4).forEach((key, index) => {
            const annex = 'annex4';
            if(props[annex][key].active){
                if(!tempData[annex]){
                    tempData[annex] = {};
                }
                tempData[annex][key] = '';
            }
        })
        Object.keys(props.annex5).forEach((key, index) => {
            const annex = 'annex5';
            if(props[annex][key].active){
                if(!tempData[annex]){
                    tempData[annex] = {};
                }
                tempData[annex][key] = '';
            }
        })
        Object.keys(props.annex6).forEach((key, index) => {
            const annex = 'annex6';
            if(props[annex][key].active){
                if(!tempData[annex]){
                    tempData[annex] = {};
                }
                tempData[annex][key] = '';
            }
        })
        Object.keys(props.annex1non).forEach((key, index) => {
            const annex = 'annex1non';
            if(props[annex][key].active){
                if(!tempData[annex]){
                    tempData[annex] = {};
                }
                tempData[annex][key] = '';
            }
        })
        Object.keys(props.annex2non).forEach((key, index) => {
            const annex = 'annex2non';
            if(props[annex][key].active){
                if(!tempData[annex]){
                    tempData[annex] = {};
                }
                tempData[annex][key] = '';
            }
        })
        Object.keys(props.annex4non).forEach((key, index) => {
            const annex = 'annex4non';
            if(props[annex][key].active){
                if(!tempData[annex]){
                    tempData[annex] = {};
                }
                tempData[annex][key] = '';
            }
        })
        Object.keys(props.annex5non).forEach((key, index) => {
            const annex = 'annex5non';
            if(props[annex][key].active){
                if(!tempData[annex]){
                    tempData[annex] = {};
                }
                tempData[annex][key] = '';
            }
        })
        Object.keys(props.annex6non).forEach((key, index) => {
            const annex = 'annex6non';
            if(props[annex][key].active){
                if(!tempData[annex]){
                    tempData[annex] = {};
                }
                tempData[annex][key] = '';
            }
        })
        setData(tempData);
    },[])

    React.useEffect(() => {
        onChange();
    }, [data]);

    const onChange = () => {
        // props.onChange(data);
    }

    const toIsoString = (date) => {
        if(!date)
            return null;
        if(!date.getTimezoneOffset){
            date = new Date(date);
        }
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function(num) {
                return (num < 10 ? '0' : '') + num;
            };
      
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
    }
    
    const Submit = () => {
        Keyboard.dismiss();
        setSubmit(true);

        let hasError = false;
        for(const annex in data){
            if(data[annex]){ // On update we also have 'comments' and 'collectedOn'. We have to ignore these here
                if(Object.keys(data[annex]).length == 0){ //If we have no keys it means no data was saved, but data is required
                    hasError = true;
                }
                else{
                    for(const key in data[annex]){
                        if(!data[annex][key]){ //Also have to check if it's empty
                            hasError = true;
                        }
                    }
                }
            }
        }
        
        if(!hasError){
            if(props.id){
                data.comments = comments;
                data.collectedOn = toIsoString(collectedOn);
                let formData = {
                    id: props.id,
                    data: data
                };
              axios.post('/api/editRequestWasteCollection.php', formData)
              .then((res) => {
                modal.confirm.show("Success", "Waste Collected");
                    setTimeout(() => {
                        props.navigation.push('Manage Requests');
                    },10)
              })
            }
            else{
                modal.confirm.show("Warning", "Something went wrong.");
            }
        }
        else{
            modal.confirm.show("Warning", "Please fill in all mandatory fields in red.");
        }
    }
  
    const Changed = (annex, key, value) => {
        let tempData = JSON.parse(JSON.stringify(data));
        if(!tempData[annex]){
            tempData[annex] = {};
        }
        tempData[annex][key] = value;
        setData(tempData);
    }

    const HasActive = (annex) => {
        let result = false;
        Object.keys(props[annex]).forEach(key => {
            if(props[annex][key].active)
                result = true;
        })
        if(data[annex]){ // For data that was collected but was not in the original request
            result = true;
        }
        return result;
    }
    const Block = (annex, key, index) => {
        let quantity = props[annex][key]?.data?.wasteForDelivery ?? "0";
        const quantityType = props[annex][key]?.data?.wasteForDeliveryType ?? "";
        let extraCollected = false;

        if(data[annex] && data[annex][key]){ // For data that was collected but was not in the original request
            extraCollected = true;
            if(!props[annex][key].active) // If it was not in the original, set it to 0
                quantity = "0"; // This is required in case the request was updated and one annex was removed
                // The previous value is held but we don't want the previous value (only the active is set to false)
        }
        if(!props[annex][key].active && !extraCollected){
            return null;
        }
        
        return <>
                <MyText style={{marginBottom: 10}}>
                    {nameMap[annex][key]}
                </MyText>
                <View style={[css.formContainer, styles.container]}>
                    <View style={[css.formRowNarrow, {marginBottom: 0}]}>
                        <MyText style={css.formRowLabel}>
                            Requested Quantity
                        </MyText>
                        <MyTextInput
                            style={css.formRowInput}
                            value={quantity+' '+quantityType}
                            isInfo={true}
                        />
                    </View>
                    {/* <View style={[{zIndex: 11}, css.formRowNarrow]}></View> */}
                    <View style={[css.formRowNarrow, {marginBottom: 0}]}>
                        <MyText style={[css.formRowLabel, {flexBasis: 200}]}>
                            Actual Collected Quantity
                        </MyText>
                        <MyTextInput
                            style={styles.input}
                            inputMode='decimal'
                            keyboardType='phone-pad'
                            onChangeText={(e) => Changed(annex, key, e)}
                            onChangeTextImmediate={(e) => Changed(annex, key, e)}
                            value={data[annex]?data[annex][key]:''}
                            type={quantityType}
                            isInfo={props.isInfo}
                            hasError={(data[annex]?!data[annex][key]:true) && submit}
                        />
                        {/* <MyText style={styles.type}>
                            {quantityType}
                        </MyText> */}
                    </View>
                    {/* <View style={[{zIndex: 11}, css.formRowNarrow]}></View> */}
                </View>
            </>
    }

    let hasHazardous = false;
    let hasNonHazardous = false;

    const loop = [props.annex1, props.annex2, props.annex4, props.annex5, props.annex6];
    for(let i = 0; i<loop.length; i++){
        for(const annex in loop[i]){
            if(loop[i][annex].active){
                hasHazardous = true;
                break;
            }
        }
    }
    const loopNon = [props.annex1non, props.annex2non, props.annex4non, props.annex5non, props.annex6non];
    for(let i = 0; i<loopNon.length; i++){
        for(const annex in loopNon[i]){
            if(loopNon[i][annex].active){
                hasNonHazardous = true;
                break;
            }
        }
    }

    console.log(props);
    
    return (
        <>
        <View style={[{zIndex: 10}, css.form]}>
            <View style={styles.reqContainer}>
                <View>
                    <MyText style={[css.formTitle, css.bold]}>
                        Waste Collection
                    </MyText>
                    <View
                        style={{
                            borderBottomColor: '#f3f1f8',
                            borderBottomWidth: 2,
                        }}
                    />
                    {/* <View style={css.columnFormContainer}>
                        <View style={[css.formContainer, styles.container]}>
                            <View style={[{zIndex: 9}, css.formRowNarrow]}> */}
                                <View style={css.columnFormContainer}>
                                    {
                                        hasNonHazardous?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            Non-Hazardous
                                        </MyText>
                                        :null
                                    }
                                    {
                                        HasActive('annex1non')?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            {annexMap['annex1non']}
                                        </MyText>
                                        :null
                                    }
                                    {
                                        Object.keys(props.annex1non).map((key, index) => {
                                            return Block('annex1non', key, index)
                                        })
                                    }
                                    {
                                        HasActive('annex2non')?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            {annexMap['annex2non']}
                                        </MyText>
                                        :null
                                    }
                                    {
                                        Object.keys(props.annex2non).map((key, index) => {
                                            return Block('annex2non', key, index)
                                        })
                                    }
                                    {
                                        HasActive('annex4non')?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            {annexMap['annex4non']}
                                        </MyText>
                                        :null
                                    }
                                    {
                                        Object.keys(props.annex4non).map((key, index) => {
                                            return Block('annex4non', key, index)
                                        })
                                    }
                                    {
                                        HasActive('annex5non')?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            {annexMap['annex5non']}
                                        </MyText>
                                        :null
                                    }
                                    {
                                        Object.keys(props.annex5non).map((key, index) => {
                                            return Block('annex5non', key, index)
                                        })
                                    }
                                    {
                                        HasActive('annex6non')?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            {annexMap['annex6non']}
                                        </MyText>
                                        :null
                                    }
                                    {
                                        Object.keys(props.annex6non).map((key, index) => {
                                            return Block('annex6non', key, index)
                                        })
                                    }
                                    {
                                        hasHazardous?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            Hazardous
                                        </MyText>
                                        :null
                                    }
                                    {
                                        HasActive('annex1')?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            {annexMap['annex1']}
                                        </MyText>
                                        :null
                                    }
                                    {
                                        Object.keys(props.annex1).map((key, index) => {
                                            return Block('annex1', key, index)
                                        })
                                    }
                                    {
                                        HasActive('annex2')?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            {annexMap['annex2']}
                                        </MyText>
                                        :null
                                    }
                                    {
                                        Object.keys(props.annex2).map((key, index) => {
                                            return Block('annex2', key, index)
                                        })
                                    }
                                    {
                                        HasActive('annex4')?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            {annexMap['annex4']}
                                        </MyText>
                                        :null
                                    }
                                    {
                                        Object.keys(props.annex4).map((key, index) => {
                                            return Block('annex4', key, index)
                                        })
                                    }
                                    {
                                        HasActive('annex5')?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            {annexMap['annex5']}
                                        </MyText>
                                        :null
                                    }
                                    {
                                        Object.keys(props.annex5).map((key, index) => {
                                            return Block('annex5', key, index)
                                        })
                                    }
                                    {
                                        HasActive('annex6')?
                                        <MyText style={[{color: '#461b6f', marginBottom: 10}, css.bold]}>
                                            {annexMap['annex6']}
                                        </MyText>
                                        :null
                                    }
                                    {
                                        Object.keys(props.annex6).map((key, index) => {
                                            return Block('annex6', key, index)
                                        })
                                    }
                                    <MyText style={[{color: '#461b6f', marginBottom: 10, marginTop: 20}, css.bold]}>
                                        Collection Datetime
                                    </MyText>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <View style={[css.formRowNarrow, {flex: 1, marginBottom: 0, padding: 0}, css.doubleInput]}>
                                            <MyDatetimePicker
                                                onChange={setCollectedOn}
                                                // small={true}
                                                value={collectedOn?new Date(collectedOn):new Date()}
                                                isInfo={props.isInfo}
                                            />
                                        </View>
                                        <View style={[{zIndex: 11}, css.formRowNarrow]}></View>
                                    </View>
                                </View>
                            </View>
                            <View style={[{zIndex: 8}, css.form]}>
                                <MyText style={[css.formTitle, css.bold]}>
                                    Request Comments
                                </MyText>
                                <View
                                style={{
                                    borderBottomColor: '#f3f1f8',
                                    borderBottomWidth: 2,
                                }}
                                />
                                <View style={css.commentsContainer}>
                                    <MyTextarea
                                        style={[{flexGrow: 1, flexShrink: 0, marginRight: 5}, css.formRowInput]}
                                        value={props.comments??''}
                                        isInfo={true}
                                        placeholder=""
                                    />
                                </View>
                            </View>
                            <View style={[{zIndex: 7}, css.form]}>
                                <MyText style={[css.formTitle, css.bold]}>
                                    Collection Comments
                                </MyText>
                                <View
                                style={{
                                    borderBottomColor: '#f3f1f8',
                                    borderBottomWidth: 2,
                                }}
                                />
                                <View style={css.commentsContainer}>
                                    <MyTextarea
                                        style={[{flexGrow: 1, flexShrink: 0, marginRight: 5}, css.formRowInput]}
                                        onChangeText={setComments}
                                        onChangeTextImmediate={setComments}
                                        value={comments}
                                        isInfo={props.isInfo}
                                        placeholder="Please write any additional information regarding the Waste Collection"
                                    />
                                </View>
                            </View>
                        </View>
                    {/* </View>
                </View>
            </View> */}
        </View>
        {
            !props.isInfo?
            <TouchableOpacity onPress={() => Submit()} style={{alignItems: 'center', marginBottom: 20}}>
                <MyText style={[css.button, css.bold]}>
                    Submit
                </MyText>
            </TouchableOpacity>
            :null
        }
    </>
    )
}
const styles = StyleSheet.create({
    container: {
        paddingVertical: 10,
        paddingHorizontal: 14,
        // paddingBottom: 0,
        borderColor: '#532b75',
        borderWidth: 0.5,
        borderRadius: 20,
        marginBottom: 15,
        minHeight: 'auto'
        // overflow: 'hidden',
    },
    label: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 180,
        paddingRight: 10
    },
    input: {
        flex: 1,
        width: '100%',
    },
    type: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 30,
        paddingLeft: 5
    },
    reqContainer: {
        marginBottom: 0,
        backgroundColor: 'white'
    },
})

export default WasteCollection

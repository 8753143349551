import Constants from "expo-constants";
import { Platform } from "react-native";

let OneSignal;

if(!Constants.appOwnership && Platform.OS != 'web'){
    OneSignal = require('react-native-onesignal').default;
    OneSignal.setAppId(Constants.expoConfig.extra.oneSignalAppId);
}

export default OneSignal;